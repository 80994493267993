import { PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { useAppRoot } from '../app/RootContext'
import { uploadPatchRecording } from '../filePickers/BaseRecordingFilePicker'
import DropTarget from '../utils/DropTarget'
import { DropTargetViewLarge } from '../utils/DropTargetView'

interface SegmentDropTargetProps {
    passage: Passage
    enabled: boolean
}

export const SegmentDropTarget = ({ passage, enabled, children }: PropsWithChildren<SegmentDropTargetProps>) => {
    const { t } = useTranslation()
    const { rt } = useAppRoot()

    return (
        <DropTarget
            upload={async (files) => {
                if (!rt || !enabled) {
                    return
                }
                await uploadPatchRecording({ files, rt, passage, t })
            }}
            dropTargetView={enabled ? <DropTargetViewLarge message={<div>{t('segmentDropFile')}</div>} /> : <div />}
        >
            {children}
        </DropTarget>
    )
}
