import { FC } from 'react'

import { getAllNoteMarkers, getNoteColor } from './NoteMarkers'
import { PassageNote } from '../../models3/PassageNote'
import { ResolvedNoteMarker } from '../utils/Buttons'

interface Props {
    className: string
    enabled: boolean
    note: PassageNote
    noteColors: string[]
    noteLabels: string[]
}

const NoteMarker: FC<Props> = ({ className, enabled, note, noteColors, noteLabels }) => {
    const { resolved, type } = note
    const typeIndex = parseInt(type)
    const color = getNoteColor(note, noteColors, enabled)
    const allNoteMarkers = getAllNoteMarkers({ className })
    const { component, shape } = allNoteMarkers[typeIndex]
    const title = typeIndex >= 0 && typeIndex < noteLabels.length - 1 ? noteLabels[typeIndex] : ''

    return (
        <span className="note-marker" style={{ color }} title={title}>
            {resolved ? <ResolvedNoteMarker className={className} noteMarkerShape={shape} /> : component}
        </span>
    )
}

export default NoteMarker
