/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { OralTranscriber } from './OralTranscriber'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { useAppRoot } from '../../app/RootContext'
import { AudioRecorder, AVTTRecordingState } from '../../video/VideoRecorder'
import { LiveWaveformVisualizerWrapper } from '../../video/WaveformVisualizer'
import { CancelButton, StopButton } from '../Buttons'

type OralTranscriberRecorderProps = {
    closeRecorder: () => void
    setTranscription: (value: string) => void
    onDoneRecording: (blob: Blob) => void
}

export const OralTranscriberRecorder = ({
    closeRecorder,
    setTranscription,
    onDoneRecording
}: OralTranscriberRecorderProps) => {
    const { isOnline } = useOnlineStatus()
    const { t } = useTranslation()
    const { rt } = useAppRoot()
    const { autoGainControl, recordingCountdown } = rt?.project ?? {}

    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const [transcriptionStarted, setTranscriptionStarted] = useState(false)
    const [audioRecorder] = useState(
        new AudioRecorder({
            recordingCountdown,
            onRecordingStateChange: setRecordingState,
            autoGainControl
        })
    )

    const initializeTranscriber = () => {
        if (!isOnline) {
            return undefined
        }

        const languageCode = rt?.getDefault('oralBackTranslationLanguage') ?? ''

        if (languageCode && OralTranscriber.supportedLanguages.some(({ value }) => value === languageCode)) {
            return new OralTranscriber(setTranscription, languageCode, t)
        }

        return undefined
    }

    const [transcriber] = useState(initializeTranscriber)

    useEffect(() => {
        function _onDoneRecording(blob: Blob) {
            onDoneRecording(blob)
        }

        function _onError() {
            closeRecorder()
        }

        audioRecorder.addListener('onRecordingDone', _onDoneRecording)
        audioRecorder.addListener('onError', _onError)

        return () => {
            audioRecorder.removeListener('onRecordingDone', _onDoneRecording)
            audioRecorder.removeListener('onError', _onError)
        }
    })

    useEffect(() => {
        if (recordingState === 'RECORDING' && !transcriptionStarted) {
            transcriber?.start()
            setTranscriptionStarted(true)
        }
    }, [recordingState, transcriptionStarted])

    useEffect(() => {
        setTimeout(audioRecorder._record.bind(audioRecorder), 1000)

        return () => {
            if (audioRecorder.mediaRecorder) {
                const { state } = audioRecorder.mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    audioRecorder.cancel()
                }
            }
        }
    }, [])

    return (
        <div>
            <div className="audio-button-row">
                <StopButton
                    enabled={recordingState !== 'NOT_INITIALIZED' && recordingState !== 'INITIALIZED'}
                    onClick={() => {
                        audioRecorder.stopRecording() // will trigger onRecordingDone listener
                        transcriber?.stop()
                    }}
                    className="right-pane-button segment-document-stop-button"
                    tooltip={t('Stop recording and preview it')}
                />
                <CancelButton
                    enabled
                    onClick={() => {
                        audioRecorder.cancel()
                        transcriber?.stop()
                        closeRecorder()
                    }}
                    className="right-pane-button segment-document-cancel-button"
                    tooltip={t('Cancel recording')}
                />
            </div>
            <div className="audio-recorder-visualization-wrapper">
                <LiveWaveformVisualizerWrapper
                    mediaStream={audioRecorder.mediaStream}
                    recordingState={recordingState}
                    isAudioOnly
                    className="audio-recorder-visualization"
                />
            </div>
        </div>
    )
}
