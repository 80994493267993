/* eslint-disable react/no-array-index-key */

import { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { ExegeticalGlossaryDropdown } from './ExegeticalGlossaryDropdown'
import { ExegeticalResourceSelector } from './ExegeticalResourceSelector'
import { StepJsonRenderer } from './StepJsonRenderer'
import { StepMediaRenderer } from './StepMediaRenderer'
import { useGlossary } from '../../hooks/useGlossary'
import { fetchPericopes, Pericope, Step } from '../../resources/ExegeticalResources'
import { RefRange } from '../../resources/RefRange'
import { useAppRoot } from '../app/RootContext'
import { AudioFetchPlayer } from '../audio/AudioFetchPlayer'
import { ReferenceSearchInput } from '../referenceInput/ReferenceSearchInput'
import { DEFAULT_LANGUAGE_CODE } from '../utils/Languages'

import './ExegeticalResources.css'

const BODY_CLASS = 'resource-body'
const COPYRIGHT = '© SRV Partners. All rights reserved.'

interface ExegeticalResourceViewerProps {
    persistenceTag: string
    idSuffix: string
    passageReferences: RefRange[]
}

export const ExegeticalResourceViewer = ({
    persistenceTag,
    idSuffix,
    passageReferences
}: ExegeticalResourceViewerProps) => {
    const { t, i18n } = useTranslation()
    const appRoot = useAppRoot()
    const { rt } = appRoot
    const uiLanguage = i18n.language ?? DEFAULT_LANGUAGE_CODE
    const EXEGETICAL_RESOURCE_GLOSSARY = 'glossary'

    const [language, setLanguage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const [refs, setRefs] = useState<RefRange[]>([])
    const [pericopesMap, setPericopesMap] = useState<Map<string, Pericope[]> | null>(null)
    const [stepsMap, setStepsMap] = useState<Map<string, Step> | null>(null)

    const [selectedStepId, setSelectedStepId] = useState(rt?.getDefault(persistenceTag) || '')
    const [errored, setErrored] = useState(false)

    const pericopeId = selectedStepId.split('-').slice(0, 2).join('-')
    const glossaryTerms = useGlossary(pericopeId)
    const [glossaryStepId, setGlossaryStepId] = useState<string>(
        rt?.getDefault(`${persistenceTag}.${EXEGETICAL_RESOURCE_GLOSSARY}`) || ''
    )

    const setGlossaryStep = useCallback(
        (glossaryTermId: string) => {
            rt?.setDefault(`${persistenceTag}.${EXEGETICAL_RESOURCE_GLOSSARY}`, glossaryTermId)
            setGlossaryStepId(glossaryTermId)
        },
        [persistenceTag, rt]
    )

    const setStepId = useCallback(
        (stepId: string) => {
            rt?.setDefault(persistenceTag, stepId)
            setGlossaryStep('')
            setSelectedStepId(stepId)
        },
        [persistenceTag, setGlossaryStep, rt]
    )

    useEffect(() => {
        const fetchResources = async () => {
            const handleNoGuides = () => {
                setPericopesMap(null)
                setStepsMap(null)
                setLanguage(null)
            }

            // Helper functions for the actual fetching
            const handleFetch = async (lang: string) => {
                if (!refs.length) {
                    handleNoGuides()
                    setLoading(false)
                    return
                }

                const refPericopesMap = await fetchPericopes(lang, refs)
                setPericopesMap(refPericopesMap)

                const refStepsMap: Map<string, Step> = new Map()
                refPericopesMap?.forEach((pericopes) => {
                    pericopes.forEach(({ steps }) => {
                        steps.forEach((step) => refStepsMap.set(step.id, step))
                    })
                })
                setStepsMap(refStepsMap)

                setLanguage(lang)
                setLoading(false)
            }

            const handleFetchError = (error: unknown, lang: string) => {
                console.error(`Error while fetching Exegetical Resources for language ${lang}`, error)
                handleNoGuides()
                setLoading(false)
            }

            // Attempt to fetch first in the selected locale
            try {
                setLoading(true)
                await handleFetch(uiLanguage)
            } catch (error) {
                setStepId('')
                // Attempt to fetch in the fallback locale
                if (uiLanguage !== DEFAULT_LANGUAGE_CODE) {
                    try {
                        await handleFetch(DEFAULT_LANGUAGE_CODE)
                    } catch (errorFallbackLng) {
                        setStepId('')
                        handleFetchError(errorFallbackLng, DEFAULT_LANGUAGE_CODE)
                    }
                } else {
                    handleFetchError(error, uiLanguage)
                }
            }
        }

        fetchResources()
    }, [uiLanguage, refs, setStepId])

    const getRenderedGlossaryStep = () => {
        if (glossaryStepId) {
            return glossaryTerms.find((glossaryTerm) => glossaryTerm.id === glossaryStepId)
        }

        if (glossaryTerms.length > 0) {
            return glossaryTerms[0]
        }
    }

    const getCurrentGlossaryTerm = () => {
        if (!selectedStepId.includes('glossary-terms')) {
            return undefined
        }

        return getRenderedGlossaryStep()
    }

    const dir = language === DEFAULT_LANGUAGE_CODE ? 'ltr' : i18n.dir()
    const step = stepsMap?.get(selectedStepId)
    const glossaryTermStep = getCurrentGlossaryTerm()

    const stepToRender = glossaryTermStep || step

    if (!rt) return null

    const isGlossaryStep = selectedStepId.includes('glossary')

    return (
        <div className="resources-viewer">
            <div className="resources-viewer-toolbar">
                <ReferenceSearchInput
                    {...{
                        refInput: rt,
                        setRefs,
                        stickyReferenceId: `${persistenceTag}Reference`,
                        errored,
                        setErrored,
                        passageReferences
                    }}
                />
                <div className="exegetical-selectors-container">
                    {!loading && stepsMap && (
                        <ExegeticalResourceSelector
                            stepId={selectedStepId}
                            setStepId={setStepId}
                            idSuffix={idSuffix}
                            stepsMap={stepsMap}
                        />
                    )}
                    {isGlossaryStep && (
                        <ExegeticalGlossaryDropdown
                            glossaryStep={getRenderedGlossaryStep()}
                            glossaryTerms={glossaryTerms}
                            setStepId={setGlossaryStep}
                        />
                    )}
                </div>
            </div>
            {loading && t('loading')}
            {!loading && !pericopesMap && refs.length > 0 && t('exegeticalResourcesNone')}
            {!loading && !pericopesMap && refs.length === 0 && t('enterBibleReference')}
            {!loading && stepsMap && selectedStepId && language && (
                <>
                    {stepToRender?.audio && (
                        <>
                            <div className="resource-audio-player">
                                <AudioFetchPlayer fetchBlob={stepToRender.audio} />
                            </div>
                            <div className="resource-audio-separator" />
                        </>
                    )}
                    {stepToRender?.json ? (
                        <StepJsonRenderer className={BODY_CLASS} dir={dir} jsonString={stepToRender.json} />
                    ) : (
                        <div
                            className={BODY_CLASS}
                            dir={dir}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: stepToRender?.html ?? '' }}
                        />
                    )}
                    {stepToRender?.media && stepToRender?.media.length > 0 && (
                        <div className={BODY_CLASS} dir={dir}>
                            <StepMediaRenderer language={language} media={stepToRender.media} />
                        </div>
                    )}
                    <div className="translation-right-pane-footer">{COPYRIGHT}</div>
                </>
            )}
        </div>
    )
}
