import { t } from 'i18next'

import { MIN_SEGMENT_LENGTH } from '../../models3/PassageSegment'
import { VideoCache } from '../../models3/VideoCache'
import { getVideoDuration } from '../../models3/VideoDuration'
import { BlobVerifyResult, RecordingDoneParams } from '../../types'
import { LocalStorageKeys } from '../app/slttAvtt'

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

export const verifyBlobs = (blobs: Blob[]): BlobVerifyResult => {
    let mimeType = ''
    let blobSize = 0
    for (const blob of blobs) {
        mimeType = blob.type
        blobSize += blob.size
    }

    if (!mimeType || !blobSize) {
        return { error: t('videoUploaderInvalidBlobError'), type: 'error' }
    }

    return { blob: new Blob(blobs, { type: mimeType }), type: 'success' }
}

export const saveRecording = async (blob: Blob, baseUrl: string): Promise<RecordingDoneParams> => {
    const result = verifyBlobs([blob])
    if (result.type === 'error') {
        return result
    }

    const { blob: verifiedBlob } = result
    const url = await VideoCache.copyFileToVideoCache(verifiedBlob, baseUrl)
    const duration = intest ? 1.5 : await getVideoDuration(verifiedBlob)
    if (duration < MIN_SEGMENT_LENGTH) {
        return { error: t('videoUploaderInvalidBlobError'), type: 'error' }
    }

    return { mimeType: verifiedBlob.type, url, duration, type: 'success' }
}
