import { TFunction } from 'i18next'

export const i18nVocabularies = (t: TFunction) => ({
    // Sign in form
    'Forgot your password?': t('signInForgotYourPassword?'),
    Password: t('signInPassword'),
    'Sign In': t('signIn'),
    Email: t('signInUsername'),
    // Send code form
    'Reset your password': t('signInResetYourPassword'),
    'Enter your email': t('signInEnterYourEmail'),
    'Send code': t('signInSendCode'),
    'Back to Sign In': t('signInBack'),
    Sending: t('signInSending'),
    // Reset password form
    Code: t('signInCode'),
    'New Password': t('signInNewPassword'),
    'Confirm Password': t('signInConfirmPassword'),
    Submit: t('signInSubmit'),
    'Resend Code': t('signInResendCode'),
    'Invalid verification code provided, please try again.': t('signInInvalidCode'),
    'Password does not conform to policy: Password not long enough': t('signInPasswordNotLongEnough'),
    'Your passwords must match': t('signInPasswordsMustMatch'),
    // Sign up form
    'Create Account': t('signUp'),
    'An account with the given email already exists.': t('signUpErrorAccountExists')
})
