import { useTranslation } from 'react-i18next'

import { StudyTabKey } from './StudyTabsOrder'
import { SlttHelp } from '../utils/Buttons'
import {
    ExegeticalResourcesIcon,
    ImageReferencesIcon,
    PassageNotesIcon,
    PassageResourcesIcon,
    PublishedBiblesIcon,
    StarIcon
} from '../utils/Icons'

export const StudyGuideTabIcon = ({ tabName }: { tabName: StudyTabKey }) => {
    const { t } = useTranslation()

    switch (tabName) {
        case 'passageResources':
            return <PassageResourcesIcon className="right-pane-tab-icon" tooltip={t('passageResources')} />
        case 'publishedBibles':
            return <PublishedBiblesIcon className="right-pane-tab-icon" tooltip={t('publishedBibles')} />
        case 'studyGuides':
            return <ExegeticalResourcesIcon className="right-pane-tab-icon" tooltip={t('fiaStudyGuide')} />
        case 'bibleImages':
            return <ImageReferencesIcon className="right-pane-tab-icon" tooltip={t('marbleImages')} />
        case 'passageBiblicalTerms':
            return (
                <SlttHelp id="biblical-terms-passage" tooltip={t('passageBiblicalTerms')} place="bottom">
                    <StarIcon className="right-pane-tab-icon passage-key-terms-icon" />
                </SlttHelp>
            )
        case 'passageNotes':
            return <PassageNotesIcon className="right-pane-tab-icon passage-notes-icon" tooltip={t('passageNotes')} />
        default:
            return null
    }
}
