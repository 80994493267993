import { useState } from 'react'

import { BlobWriter, TextReader, ZipWriter } from '@zip.js/zip.js'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { AudioEncodingSwitch } from './AudioEncodingSwitch'
import { CombineToggle } from './CombineToggle'
import { ExportItem, generateZip, getExportItem } from './ExportItem'
import { ExportTextFormatChooser } from './ExportTextFormatChooser'
import { ResultsPage } from './ResultsPage'
import { SecondsOfSilence } from './SecondsOfSilence'
import { Portion } from '../../../models3/Portion'
import { Root } from '../../../models3/Root'
import { refToPtxBookId } from '../../../resources/RefRange'
import { ExportSourceType, ExportTextFormat, ExportType, FileExtension, MimeType } from '../../../types'
import { bookTextsToString, BookText, getFileExtension, getExportTextType } from '../../passages/utils'
import { HelpLinkWithMessage } from '../../utils/Buttons'
import { DEFAULT_AUDIO_ENCODE_TYPE } from '../../utils/DownloadPassage'
import { exportToFile, safeFileName } from '../../utils/Helpers'
import { GenericIcon, HeadsetIcon } from '../../utils/Icons'
import { ScrollIcon } from '../../utils/SVGRepoIcons'
import { ModalFooter } from '../Modals'

type ExportBookTextsProps = {
    rt: Root
    portion?: Portion
    exportSourceType: ExportSourceType
    exportType: ExportType
    exportTextFormat: ExportTextFormat
}

export const bookTextsToZip = async ({
    bookTexts,
    exportType,
    exportTextFormat
}: {
    bookTexts: BookText[]
    exportType: ExportType
    exportTextFormat: ExportTextFormat
}) => {
    const zipWriter = new ZipWriter(new BlobWriter(MimeType.ZIP))
    const fileExtension = getFileExtension(exportTextFormat)
    const folderName = getExportTextType(exportType)

    await Promise.all(
        bookTexts.map(({ bookId, text }) => {
            const ptxBookId = refToPtxBookId(bookId)
            return zipWriter.add(`${folderName}/${ptxBookId}${fileExtension}`, new TextReader(text))
        })
    )

    const zipFile = await zipWriter.close()
    return new Blob([zipFile])
}

const getExportBookTexts = ({
    rt: { passage, passageVideo, project },
    portion,
    exportSourceType,
    exportType,
    exportTextFormat
}: ExportBookTextsProps): BookText[] | undefined => {
    if (exportSourceType === ExportSourceType.PASSAGE_VIDEO) {
        if (!passage || !passageVideo) {
            return undefined
        }

        // For exporting in USFM format, we need all passage references and be of the same book
        // TODO: Warn if references are not all of the same book. Also, warn during reference reation.
        const { text } =
            exportType === ExportType.BACK_TRANSLATION
                ? passageVideo.getBackTranslation({ passage, project, exportTextFormat, includeHeader: true })
                : passageVideo.getTranscription({ passage, project, exportTextFormat, includeHeader: true })
        return [{ bookId: '', text }]
    }

    if (exportSourceType === ExportSourceType.PORTION) {
        if (!portion) {
            return undefined
        }

        const bookTexts =
            exportType === ExportType.BACK_TRANSLATION
                ? portion.getBackTranslation(project, exportTextFormat)
                : portion.getTranscription(project, exportTextFormat)

        return bookTexts
    }

    const bookTexts =
        exportType === ExportType.BACK_TRANSLATION
            ? project.getBackTranslation(exportTextFormat)
            : project.getTranscription(exportTextFormat)

    return bookTexts
}

const TextViewer = ({ text }: { text: string }) => {
    return <textarea value={text} className="modal-text-editor" readOnly dir="auto" />
}

type ExportTextTabContentProps = ExportBookTextsProps & {
    panelTitle: string
    setExportTextFormat: (format: ExportTextFormat) => void
}

const ExportTextTabContent = ({
    panelTitle,
    rt,
    portion,
    exportSourceType,
    exportType,
    exportTextFormat,
    setExportTextFormat
}: ExportTextTabContentProps) => (
    <>
        <h5 className="modal-subheading">{panelTitle}</h5>
        <>
            <ExportTextFormatChooser selected={exportTextFormat} setSelected={setExportTextFormat} />
            <TextViewer
                text={bookTextsToString(
                    getExportBookTexts({
                        rt,
                        portion,
                        exportSourceType,
                        exportType,
                        exportTextFormat
                    })
                )}
            />
        </>
    </>
)

interface ExportModalProps {
    rt: Root
    exportSourceType: ExportSourceType
    portion?: Portion
    setOpen: (value: boolean) => void
}

export const ExportModal = ({ rt, exportSourceType, portion, setOpen }: ExportModalProps) => {
    const { t } = useTranslation()
    const [secondsOfSilence, setSecondsOfSilence] = useState(1.0)
    const [audioEncodeType, setAudioEncodeType] = useState(DEFAULT_AUDIO_ENCODE_TYPE)
    const [combinePassages, setCombinePassages] = useState(false)
    const [exportTextFormat, setExportTextFormat] = useState(ExportTextFormat.USFM)
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [exportType, setExportType] = useState(0)
    const [error, setError] = useState('')
    const [exporting, setExporting] = useState(true)
    const [hasFileToExport, setHasFileToExport] = useState(true)
    const [isMissingRecordings, setIsMissingRecordings] = useState(false)

    const { project, passage, passageVideo } = rt

    if (exportSourceType === ExportSourceType.PORTION && !portion) {
        return null
    }

    let headerLabel
    if (exportSourceType === ExportSourceType.PASSAGE_VIDEO) {
        headerLabel = `${t('Export Passage')}: ${passage?.name ?? ''}`
    } else if (exportSourceType === ExportSourceType.PORTION) {
        headerLabel = `${t('Export Portion')}: ${portion?.name}`
    } else {
        headerLabel = `${t('Export Project')}: ${project.displayName}`
    }

    const passages = exportSourceType === ExportSourceType.PROJECT ? project.passages : portion?.passages ?? []
    const passagesToExport = passages.filter((exportPassage) => exportPassage.videosNotDeleted.length > 0)
    const hasPassagesToExport = passagesToExport.length > 0

    const createRecordingBlob = async () => {
        let blob: Blob | undefined
        const fileExtension = `.${audioEncodeType}`

        if (exportSourceType === ExportSourceType.PASSAGE_VIDEO) {
            if (!passage || !passageVideo) {
                return { blob, fileExtension }
            }

            const passageAudio = await getExportItem({
                passage,
                passageVideo,
                inputParams: { recording: { path: '', audioEncodeType } }
            })
            if (passageAudio.recording) {
                blob = passageAudio.recording.data
                setIsMissingRecordings(passageAudio.recording.isMissingParts)
            }
            return { blob, fileExtension }
        }

        const dataPromises: Promise<ExportItem>[] = []
        if (combinePassages) {
            const portionsToExport =
                exportSourceType === ExportSourceType.PORTION
                    ? portion
                        ? [portion]
                        : []
                    : project.portions.filter((exportPortion) =>
                          exportPortion.passages.filter((exportPassage) => exportPassage.videosNotDeleted.length > 0)
                      )

            if (!portionsToExport.length) {
                return { blob, fileExtension }
            }

            portionsToExport.forEach((exportPortion) => {
                const portionName = safeFileName(exportPortion.name)
                dataPromises.push(
                    getExportItem({
                        portion: exportPortion,
                        inputParams: {
                            recording: { path: `${portionName}-audio.${audioEncodeType}`, audioEncodeType }
                        }
                    })
                )
            })
        } else {
            passagesToExport.forEach((exportPassage) => {
                const latestAudio = exportPassage.latestVideo
                if (!latestAudio) {
                    return { blob, fileExtension }
                }

                const portionName = project.findPortion(exportPassage._id)?.name ?? project.displayName
                const passageName = `${safeFileName(portionName)}/${safeFileName(exportPassage.name)}`
                dataPromises.push(
                    getExportItem({
                        passage: exportPassage,
                        passageVideo: latestAudio,
                        inputParams: {
                            recording: {
                                path: `${passageName}-audio.${audioEncodeType}`,
                                audioEncodeType
                            }
                        }
                    })
                )
            })
        }

        const data = await Promise.all(dataPromises)
        setIsMissingRecordings(data.some((item) => item.recording?.isMissingParts))
        const zipFile = await generateZip(data)
        if (zipFile) {
            blob = zipFile
        }

        return { blob, fileExtension: FileExtension.ZIP }
    }

    const exportTheData = async () => {
        const fileNameParts: (string | undefined)[] = [`AVTT-${project.displayName}`]

        if (exportSourceType === ExportSourceType.PASSAGE_VIDEO) {
            fileNameParts.push(rt.portion?.name)
            fileNameParts.push(passage?.name)
            fileNameParts.push(getExportTextType(exportType))
        } else if (exportSourceType === ExportSourceType.PORTION) {
            fileNameParts.push(portion?.name)
        }
        const fileName = safeFileName(fileNameParts.filter((name) => name).join('-'))

        try {
            if (exportType === ExportType.RECORDING) {
                const { blob, fileExtension } = await createRecordingBlob()
                if (!blob) {
                    setHasFileToExport(false)
                    setExporting(false)
                    return
                }

                exportToFile(blob, fileName, fileExtension)
            } else {
                setIsMissingRecordings(false)

                const bookTexts = getExportBookTexts({ rt, portion, exportSourceType, exportType, exportTextFormat })
                if (!bookTexts) {
                    setHasFileToExport(false)
                    setExporting(false)
                    return
                }

                if (exportSourceType === ExportSourceType.PASSAGE_VIDEO) {
                    exportToFile(bookTexts[0].text, fileName, getFileExtension(exportTextFormat))
                } else {
                    const zipFile = await bookTextsToZip({ bookTexts, exportType, exportTextFormat })
                    exportToFile(zipFile, fileName, FileExtension.ZIP)
                }
            }
            setExporting(false)
        } catch (err) {
            setError(String(err))
        }
    }

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {headerLabel}{' '}
                    <HelpLinkWithMessage
                        id={exportSourceType === ExportSourceType.PASSAGE_VIDEO ? 'passages-export' : 'portions-export'}
                    />
                </Modal.Title>
            </Modal.Header>
            {showResultsPage && (
                <ResultsPage
                    closeModal={() => setOpen(false)}
                    error={error}
                    loading={exporting}
                    hasFileToExport={hasFileToExport}
                    isMissingRecordings={isMissingRecordings}
                />
            )}
            {!showResultsPage && (
                <>
                    <Modal.Body>
                        <Tabs selectedIndex={exportType} onSelect={(index: number) => setExportType(index)}>
                            <TabList>
                                <Tab className="modal-tab__tab">
                                    <HeadsetIcon className="passage-transcription-icon" tooltip={t('recordingPanel')} />
                                </Tab>
                                <Tab>
                                    <GenericIcon
                                        iconName="fa-rotate-left"
                                        className="modal-back-translation-icon"
                                        tooltip={t('backTranslationPanel')}
                                    />
                                </Tab>
                                <Tab className="modal-tab__tab">
                                    <ScrollIcon
                                        className="passage-transcription-icon"
                                        tooltip={t('transcriptionPanel')}
                                    />
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <h5 className="modal-subheading">{t('exportRecording')}</h5>
                                {exportSourceType === ExportSourceType.PASSAGE_VIDEO && (
                                    <AudioEncodingSwitch setAudioEncodeType={setAudioEncodeType} />
                                )}
                                {exportSourceType !== ExportSourceType.PASSAGE_VIDEO && (
                                    <>
                                        {!hasPassagesToExport && <p>{t('No passages exist with a recording.')}</p>}
                                        {hasPassagesToExport && (
                                            <>
                                                <CombineToggle
                                                    toggle={combinePassages}
                                                    setToggle={setCombinePassages}
                                                />
                                                {combinePassages && (
                                                    <SecondsOfSilence
                                                        secondsOfSilence={secondsOfSilence}
                                                        setSecondsOfSilence={setSecondsOfSilence}
                                                    />
                                                )}
                                                <AudioEncodingSwitch setAudioEncodeType={setAudioEncodeType} />
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <ExportTextTabContent
                                    panelTitle={t('exportBackTranslation')}
                                    rt={rt}
                                    portion={portion}
                                    exportSourceType={exportSourceType}
                                    exportType={exportType}
                                    exportTextFormat={exportTextFormat}
                                    setExportTextFormat={setExportTextFormat}
                                />
                            </TabPanel>
                            <TabPanel>
                                <ExportTextTabContent
                                    panelTitle={t('exportTranscription')}
                                    rt={rt}
                                    portion={portion}
                                    exportSourceType={exportSourceType}
                                    exportType={exportType}
                                    exportTextFormat={exportTextFormat}
                                    setExportTextFormat={setExportTextFormat}
                                />
                            </TabPanel>
                        </Tabs>
                    </Modal.Body>
                    <ModalFooter
                        onOK={() => {
                            if (hasPassagesToExport) {
                                setShowResultsPage(true)
                                exportTheData()
                            } else {
                                setOpen(false)
                            }
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </>
            )}
        </Modal>
    )
}
