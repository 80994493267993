import { useCallback } from 'react'

import { AudioFetchPlayer } from './AudioFetchPlayer'
import API from '../../models3/API'

interface ReviewRecordingAudioPlayerProps {
    url: string
    recordedBlob?: Blob
    avttProjectName: string
}

export const ReviewRecordingAudioPlayer = ({ avttProjectName, url, recordedBlob }: ReviewRecordingAudioPlayerProps) => {
    const fetchBlob = useCallback(() => {
        return recordedBlob ? Promise.resolve(recordedBlob) : API.getReviewRecordingBlob(avttProjectName, url)
    }, [avttProjectName, url, recordedBlob])

    return <AudioFetchPlayer fetchBlob={fetchBlob} />
}
