import { useTranslation } from 'react-i18next'

import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { Option } from '../projectSettings/projectResources/MultiSelect'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../utils/TextInput'

interface PassageNameEditorProps {
    isAdd: boolean
    isMultipleAdd: boolean
    passageName: string
    setNameError: (error: string) => void
    setPassageName: (name: string) => void
    setReferences: (references: RefRange[]) => void
    setSelectedPericopes: (selected: Option<string>[]) => void
    references: RefRange[]
    nameError: string
    referencesError: boolean
    setReferencesError: (error: boolean) => void
    rt: Root
    validatePassageName: (name: string) => string
}

export const PassageNameEditor = ({
    isAdd,
    isMultipleAdd,
    passageName,
    setNameError,
    setPassageName,
    setSelectedPericopes,
    references,
    setReferences,
    nameError,
    referencesError,
    setReferencesError,
    rt,
    validatePassageName
}: PassageNameEditorProps) => {
    const { t } = useTranslation()

    const setNewReferences = (newReferences: RefRange[]) => {
        setReferences(newReferences)
        if (isMultipleAdd) {
            setSelectedPericopes([])
        }
    }

    const setName = (value: string) => {
        setNameError(validatePassageName(value))
        setPassageName(value)
        if (isMultipleAdd) {
            setSelectedPericopes([])
        }

        try {
            const parsedNewReferences = rt.parseReferences(value.trim(), isMultipleAdd)
            if (parsedNewReferences.length > 0) {
                // Continue to show old references until the user types a valid reference
                setNewReferences(parsedNewReferences)
                setReferencesError(false)
            }
        } catch (error) {
            // Ignore, since we just want to know whether the string can be parsed as a reference
        }
    }

    return (
        <>
            <LabeledFieldWithIcon
                iconName="fa-tag"
                title={isAdd && isMultipleAdd ? t('titlePrefixOptional') : t('Title')}
                field={<SimpleTextInput autoFocus value={passageName} setValue={setName} errorMessage={nameError} />}
            />
            <LabeledFieldWithIcon
                iconName="fa-book"
                title={t('References')}
                field={
                    <ReferenceInput
                        refInput={rt}
                        refs={references}
                        setRefs={setNewReferences}
                        errored={referencesError}
                        setErrored={setReferencesError}
                        showSuggestions
                        autoComplete
                    />
                }
            />
        </>
    )
}
