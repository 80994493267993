import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { WaveSurferAudioPlayerCore } from './WaveSurferAudioPlayerCore'
import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Root } from '../../models3/Root'
import { AudioEncodeType, getPassageAudio } from '../utils/DownloadPassage'
import { LoadingIcon } from '../utils/Icons'
import { AdvancedDownloadingMessage } from '../video/DownloadingMessage'
import { VideoMessage } from '../video/VideoMessage'

interface WaveSurferWithRootProps {
    rt: Root
    minPxPerSecond: number
    setBiblicalTermMarker: (marker: BiblicalTermMarker) => void
    setZoomOutEnabled: (value: boolean) => void
    setMinPxPerSecond: (value: number) => void
}

export const WaveSurferAudioPlayer = observer(
    ({ rt, minPxPerSecond, setBiblicalTermMarker, setZoomOutEnabled, setMinPxPerSecond }: WaveSurferWithRootProps) => {
        const { currentVideos, passageVideo, passage } = rt
        const [audioUrl, setAudioUrl] = useState('')

        const computedDuration = passageVideo?.isAudioOnly() ? undefined : passageVideo?.computedDuration

        useEffect(() => {
            let wavSrc = ''
            let effectCancelled = false

            const createBuffer = async () => {
                if (passageVideo && passage) {
                    const { blob: wavBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.wav)
                    if (!wavBlob) {
                        return
                    }

                    wavSrc = URL.createObjectURL(wavBlob)
                    if (effectCancelled) {
                        URL.revokeObjectURL(wavSrc)
                        return
                    }

                    setAudioUrl(wavSrc)
                }
            }

            createBuffer()

            return () => {
                effectCancelled = true
                setAudioUrl('')
                URL.revokeObjectURL(wavSrc)
            }
            // We use rt.currentURLs to tell if any part of the passage recording has changed.
            // We use passageVideo.computedDuration to tell if the total viewable length of the
            // recording has changed. This can happen when you edit the start/end of a segment.
            // THIS IS A HACK!!!
        }, [passage, passageVideo, rt.currentURLs, computedDuration])

        if (!passage || passage.videoBeingCompressed || !passageVideo) {
            return (
                <div className="wavesurfer-audio-player">
                    <VideoMessage rt={rt} />
                </div>
            )
        }

        if (!currentVideos.downloaded) {
            return (
                <div className="wavesurfer-audio-player">
                    <AdvancedDownloadingMessage
                        urls={currentVideos.viewableVideos.map((vv) => vv.video.url)}
                        creator={currentVideos.creator}
                    />
                </div>
            )
        }

        if (!audioUrl) {
            return (
                <div className="wavesurfer-audio-player">
                    <LoadingIcon className="" />
                </div>
            )
        }

        return (
            <div className="wavesurfer-audio-player">
                <WaveSurferAudioPlayerCore
                    url={audioUrl}
                    rt={rt}
                    passage={passage}
                    passageVideo={passageVideo}
                    playbackRate={rt.playbackRate}
                    minPxPerSecond={minPxPerSecond}
                    setBiblicalTermMarker={setBiblicalTermMarker}
                    setZoomOutEnabled={setZoomOutEnabled}
                    setMinPxPerSecond={setMinPxPerSecond}
                />
            </div>
        )
    }
)
