import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnlineStatus } from '../components/app/OnlineStatusContext'
import { systemError } from '../components/utils/Errors'
import API from '../models3/API'
import { Root } from '../models3/Root'

export const useDeleteProject = () => {
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [deletionInProgress, setDeletionInProgress] = useState(false)
    const { checkIsOnline } = useOnlineStatus()

    const deleteProject = async (projectRoot: Root) => {
        setDeletionInProgress(true)
        const { project } = projectRoot
        const { reviewProject, portions } = project

        try {
            const isOnline = await checkIsOnline()
            if (!isOnline) {
                setError(t('projectDeleteNoInternetError'))
                setDeletionInProgress(false)
                return
            }

            if (reviewProject) {
                const videos = portions.flatMap((portion) => portion.passages.flatMap((passage) => passage.videos))
                await Promise.all(
                    videos.map((video) => video.getLatestReviewRecording(reviewProject)?.setIsActive(false))
                )
                await reviewProject.setIsActive(false)
            }
            await API.deleteProject(projectRoot.project.name, projectRoot.group)
        } catch (e) {
            systemError(e)
            setError(t('projectDeleteError'))
        }

        setDeletionInProgress(false)
    }
    return { deleteProject, error, deletionInProgress }
}

export default useDeleteProject
