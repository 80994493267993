import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { RefRange } from '../../resources/RefRange'
import { useBiblePericopes } from '../app/BiblePericopesContext'
import { MultiSelect, Option } from '../projectSettings/projectResources/MultiSelect'
import { LoadingIcon } from '../utils/Icons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'

export const PericopeTypeField = ({
    references,
    passageName,
    setSelectedPericopes,
    selectedPericopeType,
    setSelectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (selected: Option<string>[]) => void
}) => {
    const { t } = useTranslation()
    const { getPericopesByReference, pericopeTypeOptions, isLoading } = useBiblePericopes()

    const srvPericopes = getPericopesByReference(
        references,
        [{ label: 'passage per Study Guide', value: 'srvPericopes' }],
        passageName
    )

    useEffect(() => {
        if (
            references.length &&
            srvPericopes.length === 0 &&
            selectedPericopeType.some((pericopeType) => pericopeType.value === 'srvPericopes')
        ) {
            setSelectedPericopeType([])
        }
    }, [references, setSelectedPericopeType, srvPericopes, selectedPericopeType])

    const setNewPericopeType = (pericopeType: Option<string>[]) => {
        setSelectedPericopeType(pericopeType)
        setSelectedPericopes([])
    }

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    return (
        <LabeledFieldWithIcon
            iconName="fa-paragraph"
            title={t('passagePericopeType')}
            field={
                <MultiSelect
                    singleSelect
                    options={pericopeTypeOptions(references)}
                    selected={selectedPericopeType}
                    setSelected={setNewPericopeType}
                />
            }
        />
    )
}

export const PericopesToAddField = ({
    references,
    passageName,
    selectedPericopes,
    setSelectedPericopes,
    selectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    selectedPericopes: Option<string>[]
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
}) => {
    const { t } = useTranslation()
    const { getPericopesByReference, isLoading } = useBiblePericopes()

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    const pericopes = getPericopesByReference(references, selectedPericopeType, passageName)

    return (
        <LabeledFieldWithIcon
            iconName="fa-plus-square"
            title={t('passagesToAdd')}
            field={
                <MultiSelect
                    options={pericopes}
                    selected={selectedPericopes}
                    setSelected={setSelectedPericopes}
                    disabled={selectedPericopeType.length === 0}
                />
            }
        />
    )
}

export const MultipleAddFields = ({
    references,
    passageName,
    selectedPericopes,
    setSelectedPericopes,
    selectedPericopeType,
    setSelectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    selectedPericopes: Option<string>[]
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (selected: Option<string>[]) => void
}) => {
    return (
        <>
            <PericopeTypeField
                references={references}
                passageName={passageName}
                setSelectedPericopes={setSelectedPericopes}
                selectedPericopeType={selectedPericopeType}
                setSelectedPericopeType={setSelectedPericopeType}
            />
            <PericopesToAddField
                references={references}
                passageName={passageName}
                selectedPericopes={selectedPericopes}
                setSelectedPericopes={setSelectedPericopes}
                selectedPericopeType={selectedPericopeType}
            />
        </>
    )
}
