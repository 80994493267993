import { useFetchBlob } from '../../hooks/useFetchBlob'
import { getMediaTranslation, getMediumPath, PERICOPES_MEDIA_PATH, Medium } from '../../resources/ExegeticalResources'
import { MimeType } from '../../types'
import { LoadingIcon } from '../utils/Icons'

const StepMedium = ({ language, medium }: { language: string; medium: Medium }) => {
    const translation = getMediaTranslation({ language, medium })

    // We need to use a blob URL to display the media so that the entire content will be correctly cached.
    // However, when clicked, we will open image in a new tab using the original URL so that the user can share the link.
    // The image should be cached already by this point, so it should be safe from caching opaque responses.
    const path = getMediumPath({ language, medium })
    const src = `${PERICOPES_MEDIA_PATH}/${path}`
    const { blobUrl, isError, isLoading } = useFetchBlob(src)

    if (!translation || !path || isError) return null

    const isVideo = path.startsWith('videos/')

    return (
        <>
            <div key={medium.key} className="media-title">
                {translation.title}
            </div>
            {isLoading && <LoadingIcon className="media-loading" />}
            {!isLoading &&
                (isVideo ? (
                    <video width="100%" controls>
                        <source key={medium.key} src={blobUrl} type={MimeType.MP4} />
                    </video>
                ) : (
                    <a href={src} target="_blank" rel="noopener noreferrer">
                        <img key={medium.key} width="100%" src={blobUrl} alt={medium.key} />
                    </a>
                ))}
        </>
    )
}

export const StepMediaRenderer = ({ language, media }: { language: string; media: Medium[] }) => {
    return (
        <>
            {media.map((medium) => (
                <StepMedium key={medium.key} language={language} medium={medium} />
            ))}
        </>
    )
}
