import { Component, useState } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import SplitPane, { SplitPaneProps } from 'react-split-pane'

import HorizontalAccordion from './HorizontalAccordion'
import TranslationRightPane from './TranslationRightPane'
import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { LocalStorageKeys } from '../app/slttAvtt'
import NoteDialog, { INoteRoot } from '../notes/NoteDialog'
import PassageList from '../passages/PassageList'
import { PublishedBibleSettingsProvider } from '../publishedBibleSettings'
import ErrorBoundary from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'
import VideoMain from '../video/VideoMain'
import { AVTTRecordingState } from '../video/VideoRecorder'

import './Resizer.css'
import './Translation.css'
import '../notes/Note.css'
import '../video/Video.css'

type AVTTTranslationRightPaneProps = {
    rt: Root
    recordingState: AVTTRecordingState
    setRecordingState: (state: AVTTRecordingState) => void
}

export enum RightPaneSplit {
    Single,
    DoubleVertical,
    DoubleHorizontal,
    TripleVertical,
    TripleHorizontal
}

export const isHorizontalSplit = (split: RightPaneSplit) =>
    [RightPaneSplit.DoubleHorizontal, RightPaneSplit.TripleHorizontal].includes(split)

export const isVerticalSplit = (split: RightPaneSplit) =>
    [RightPaneSplit.DoubleVertical, RightPaneSplit.TripleVertical].includes(split)

const splitPaneCounts: Record<RightPaneSplit, number> = {
    [RightPaneSplit.Single]: 1,
    [RightPaneSplit.DoubleVertical]: 2,
    [RightPaneSplit.DoubleHorizontal]: 2,
    [RightPaneSplit.TripleVertical]: 3,
    [RightPaneSplit.TripleHorizontal]: 3
}

const numberOfSplitPanes = (split: RightPaneSplit) => splitPaneCounts[split]

export type PaneSize = {
    width: number
    height: number
}

export enum RightPaneId {
    Primary = 'resourcesPanePrimary',
    Secondary = 'resourcesPaneSecondary',
    Tertiary = 'resourcesPaneTertiary'
}

const MAX_PASSAGE_PANE_WIDTH = '300px'

const AVTTTranslationRightPaneSplit = observer(
    ({ rt, recordingState, setRecordingState }: AVTTTranslationRightPaneProps) => {
        const { t } = useTranslation()
        const [isPlaying, setIsPlaying] = useState('')
        const [selectedVerse, setSelectedVerse] = useState<RefRange[]>([])

        const [rightPaneSplit, setRightPaneSplit] = useState<RightPaneSplit>(
            Number(localStorage.getItem(LocalStorageKeys.RESOURCES_PANE_SPLIT))
        )

        const setAndPersistRightPaneSplit = (value: RightPaneSplit) => {
            localStorage.setItem(LocalStorageKeys.RESOURCES_PANE_SPLIT, value.toString())
            setRightPaneSplit(value)
        }

        const { portion, passage, iAmBackTranslator } = rt
        const leftPaneTitle = portion && passage ? portion.getLongPassageName(passage) : t('Passages')

        const split = isVerticalSplit(rightPaneSplit) ? 'vertical' : 'horizontal'
        const panesCount = numberOfSplitPanes(rightPaneSplit)

        const translationSinglePane = (paneId: RightPaneId, showClosePaneButton?: boolean) => (
            <TranslationRightPane
                rt={rt}
                rightPaneSplit={rightPaneSplit}
                setRightPaneSplit={setAndPersistRightPaneSplit}
                showClosePaneButton={showClosePaneButton}
                paneId={paneId}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                selectedVerse={selectedVerse}
                setSelectedVerse={setSelectedVerse}
            />
        )

        const baseSplitPaneProps: SplitPaneProps = {
            split,
            allowResize: false,
            style: {
                position: 'relative',
                height: '100%',
                overflow: 'visible'
            },
            paneStyle: { minHeight: 0 }
        }

        const primarySplitPaneProps = {
            ...baseSplitPaneProps,
            defaultSize: panesCount === 1 ? '100%' : panesCount === 2 ? '50%' : '33%'
        }

        const secondarySplitPaneProps = {
            ...baseSplitPaneProps,
            defaultSize: panesCount === 2 ? '100%' : '50%'
        }

        return (
            <PublishedBibleSettingsProvider>
                <HorizontalAccordion
                    maxWidthItem={{ item: 'left', width: MAX_PASSAGE_PANE_WIDTH }}
                    left={{
                        title: leftPaneTitle,
                        content: (
                            <ErrorBoundary>
                                <PassageList rt={rt} />
                            </ErrorBoundary>
                        )
                    }}
                    middle={{
                        title: t('horizontalAccordionTitleRecording'),
                        content: (
                            <VideoMain rt={rt} recordingState={recordingState} setRecordingState={setRecordingState} />
                        )
                    }}
                    right={{
                        title: t('horizontalAccordionTitleStudying'),
                        content: !iAmBackTranslator && (
                            <div className="translation-right-pane">
                                {panesCount === 1 && translationSinglePane(RightPaneId.Primary)}
                                {panesCount === 2 && (
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    <SplitPane {...primarySplitPaneProps}>
                                        {translationSinglePane(RightPaneId.Primary)}
                                        {translationSinglePane(RightPaneId.Secondary, true)}
                                    </SplitPane>
                                )}
                                {panesCount === 3 && (
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    <SplitPane {...primarySplitPaneProps}>
                                        {translationSinglePane(RightPaneId.Primary)}
                                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                        <SplitPane {...secondarySplitPaneProps}>
                                            {translationSinglePane(RightPaneId.Secondary)}
                                            {translationSinglePane(RightPaneId.Tertiary, true)}
                                        </SplitPane>
                                    </SplitPane>
                                )}
                            </div>
                        )
                    }}
                />
            </PublishedBibleSettingsProvider>
        )
    }
)
interface ITranslationEditor {
    rt: Root
}

class TranslationEditor extends Component<ITranslationEditor> {
    topDiv: HTMLDivElement | null = null

    listenersAdded = false

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    componentDidMount() {
        if (!this.topDiv || this.listenersAdded) return

        this.topDiv.addEventListener('dragover', this.preventDrop, false)
        this.topDiv.addEventListener('drop', this.preventDrop, false)
        this.listenersAdded = true
    }

    preventDrop(e: DragEvent) {
        if (!e) return

        e.preventDefault()

        if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        }
    }

    render() {
        const { rt } = this.props
        const { note, initialized, loadingMessage, useMobileLayout } = rt
        const { recordingState } = this

        if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

        // Is this the right thing to do? All we want is to ensure note is never
        // undefined in the note dialog
        const noteRoot = rt as INoteRoot
        const closeNoteDialog = () => rt.setNote(undefined)

        return (
            <div
                ref={(c) => {
                    this.topDiv = c
                }}
            >
                {note && <NoteDialog {...{ noteRoot, closeNoteDialog }} />}
                <div className="translation-top-container">
                    {useMobileLayout ? (
                        <div className="translation-left-pane">
                            <ErrorBoundary>
                                <PassageList rt={rt} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <VideoMain
                                    rt={rt}
                                    recordingState={recordingState}
                                    setRecordingState={(state) => (this.recordingState = state)}
                                />
                            </ErrorBoundary>
                        </div>
                    ) : (
                        <ErrorBoundary>
                            <AVTTTranslationRightPaneSplit
                                rt={rt}
                                recordingState={recordingState}
                                setRecordingState={(state) => (this.recordingState = state)}
                            />
                        </ErrorBoundary>
                    )}
                </div>
            </div>
        )
    }
}

export default observer(TranslationEditor)
