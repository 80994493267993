import { BlobWriter, ZipWriter, BlobReader } from '@zip.js/zip.js'

import { serializeResponse } from './ResponseSerializer'
import { ptxBookIds } from '../../../resources/bookNames'
import { MimeType, FileExtension, CacheRequest, CacheType, CacheIndexEntry, CacheSource } from '../../../types'
import { getHostname } from '../../app/slttAvtt'
import { exportToFile, generateBinary, getRangeString } from '../../utils/Helpers'

const PUBLIC_RESOURCES_CACHE = 'avtt-resources-public'
const PRIVATE_RESOURCES_CACHE = 'avtt-resources-private'

const isPublicResource = (url: string) =>
    Boolean(url.match(/^https:\/\/.*\.cloudfront\.net\/public\/(APIBIBLE|SLMARBLE|SRV)\//))

const isPrivateResource = (url: string) =>
    Boolean(url.match(/^https:\/\/.*\.execute-api\..*\.amazonaws\.com\/.*\/resources\/(APIBIBLE|SLMARBLE)\//))

const getCacheName = (url: string) => {
    if (isPublicResource(url)) {
        return PUBLIC_RESOURCES_CACHE
    }
    if (isPrivateResource(url)) {
        return PRIVATE_RESOURCES_CACHE
    }
    return ''
}

const serializeCacheStorage = async (responses: Response[]) => {
    const fileContents: { name: string; contents: Blob }[] = []
    let index = 0
    for (const response of responses) {
        const { url } = response
        const cacheName = getCacheName(url)
        if (!cacheName) {
            continue
        }

        const serializedResponse = await serializeResponse(response)
        const binaryBlob = generateBinary({ url, response: serializedResponse })
        fileContents.push({
            name: `${CacheSource.CacheStorage}/${cacheName}/${index}`,
            contents: binaryBlob
        })
        index++
    }
    return fileContents
}

const serializeCacheIndex = (cacheType: CacheType, indexEntries: CacheIndexEntry[]) => {
    return [
        {
            name: `${CacheSource.CacheIndex}/${cacheType}${FileExtension.JSON}`,
            contents: new Blob([JSON.stringify(indexEntries)], { type: MimeType.JSON })
        }
    ]
}

const createZipFile = async (fileEntries: { name: string; contents: Blob }[]) => {
    // No compression, since the zip might contain binary files that are already compressed
    const zipWriter = new ZipWriter(new BlobWriter(MimeType.ZIP), { level: 0 })
    await Promise.all(fileEntries.map(({ name, contents }) => zipWriter.add(name, new BlobReader(contents))))
    return zipWriter.close()
}

export const getExportFileNamePrefix = () => {
    const hostnameParts = getHostname().split('.')
    const hostnamePartsWithoutTLD = hostnameParts.length < 2 ? hostnameParts : hostnameParts.slice(0, -1)
    const hostnameWithoutTLD = hostnamePartsWithoutTLD.join('.')
    return [hostnameWithoutTLD.replaceAll('.', '-'), 'resources'].join('_')
}

export const exportTranslationResources = async ({
    bookNumbers,
    responses,
    cacheIndexEntries,
    cacheType,
    mediaType,
    bibleVersion,
    language
}: {
    bookNumbers: number[]
    responses: Response[]
    cacheIndexEntries: CacheIndexEntry[]
} & CacheRequest) => {
    const bookNames = bookNumbers.map((bookNumber) => ptxBookIds[bookNumber - 1])
    const fileNameParts = [
        getExportFileNamePrefix(),
        cacheType,
        cacheType === CacheType.EXEGETICAL_RESOURCES && language,
        bibleVersion?.abbreviation,
        mediaType,
        getRangeString(bookNames)
    ]
    const fileName = fileNameParts.filter(Boolean).join('_')

    const [cacheStorageEntries, indexedDBCaches] = await Promise.all([
        serializeCacheStorage(responses),
        serializeCacheIndex(cacheType, cacheIndexEntries)
    ])
    const exportedCaches = [...cacheStorageEntries, ...indexedDBCaches]

    const zipFile = await createZipFile(exportedCaches)
    exportToFile(zipFile, fileName, FileExtension.ZIP)
}
