import { FC, useState, useContext, PropsWithChildren } from 'react'

import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
    SortableContext,
    arrayMove,
    horizontalListSortingStrategy,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { RecordingLayoutSortableItem } from './RecordingLayoutSortableItem'
import { StudyTabLayoutSortableItem } from './StudyTabLayoutSortableItem'
import { Project } from '../../../models3/Project'
import { RootContext } from '../../app/RootContext'
import { SegmentEditorPanel } from '../../segments/SegmentPanelOrder'
import { StudyTabKey } from '../../translation/StudyTabsOrder'
import { TableIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

type RecordingLayoutPreferencesProps = {
    project: Project
    iconClassName: string
}

type LayoutContainer = 'segmentEditor' | 'studyTabs'

const StudyTabContainer = ({ id, items }: { id: LayoutContainer; items: StudyTabKey[] }) => {
    const { setNodeRef } = useDroppable({ id })
    const rt = useContext(RootContext)
    if (!rt) {
        return null
    }

    return (
        <SortableContext id={id} items={items} strategy={horizontalListSortingStrategy}>
            <div ref={setNodeRef} className="sortable-container sortable-container-horizontal">
                {items.map((item) => (
                    <StudyTabLayoutSortableItem rt={rt} key={item} id={item} />
                ))}
            </div>
        </SortableContext>
    )
}

const SegmentEditorPanelContainer = ({ id, items }: { id: LayoutContainer; items: SegmentEditorPanel[] }) => {
    const { setNodeRef } = useDroppable({ id })
    const { t } = useTranslation()
    const rt = useContext(RootContext)
    if (!rt) {
        return null
    }

    return (
        <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
            <div ref={setNodeRef} className="sortable-container">
                <div style={{ textAlign: 'center' }}>
                    <label>{t('segmentEditor')}</label>
                </div>
                {items.map((item) => (
                    <RecordingLayoutSortableItem rt={rt} key={item} id={item} />
                ))}
            </div>
        </SortableContext>
    )
}

const EditorLayoutView = <T extends string>({
    items,
    setItems,
    children
}: PropsWithChildren<{
    items: { [key: string]: T[] }
    setItems: (newItems: { [key: string]: T[] }) => void
}>) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const findContainer = (id: T) => {
        if (id in items) {
            return id
        }

        return Object.keys(items).find((key: string) => items[key].includes(id))
    }

    const handleDragEnd = async (event: any) => {
        const { active, over } = event
        const { id } = active
        const { id: overId } = over

        const activeContainer = findContainer(id)
        const overContainer = findContainer(overId)

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return
        }

        const activeIndex = items[activeContainer].indexOf(id)
        const overIndex = items[overContainer].indexOf(overId)
        if (activeIndex !== overIndex) {
            const newItems = {
                ...items,
                [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }
            setItems(newItems)
        }
    }

    return (
        <div className="recording-layout-preferences">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                modifiers={[restrictToParentElement]}
                onDragEnd={handleDragEnd}
            >
                {children}
            </DndContext>
        </div>
    )
}

const SegmentEditorPanelLayoutView = ({ project }: { project: Project }) => {
    const defaultItems: { [key: string]: SegmentEditorPanel[] } = {
        segmentEditor: project.segmentEditorPanelLayout
    }

    const [items, setItems] = useState(defaultItems)

    const _setItems = async (newItems: { [key: string]: SegmentEditorPanel[] }) => {
        setItems(newItems)
        await project.setSegmentEditorPanelLayout(newItems.segmentEditor)
    }

    return (
        <EditorLayoutView items={items} setItems={_setItems}>
            <SegmentEditorPanelContainer id="segmentEditor" items={items.segmentEditor} />
        </EditorLayoutView>
    )
}

const StudyTabsLayoutView = ({ project }: { project: Project }) => {
    const defaultItems: { [key: string]: StudyTabKey[] } = {
        studyTabs: project.studyTabLayout
    }

    const [items, setItems] = useState(defaultItems)

    const _setItems = async (newItems: { [key: string]: StudyTabKey[] }) => {
        setItems(newItems)
        await project.setStudyTabLayout(newItems.studyTabs)
    }

    return (
        <EditorLayoutView items={items} setItems={_setItems}>
            <StudyTabContainer id="studyTabs" items={items.studyTabs} />
        </EditorLayoutView>
    )
}

export const RecordingLayoutPreferences: FC<RecordingLayoutPreferencesProps> = observer(
    ({ project, iconClassName }) => {
        const { t } = useTranslation()
        const { overrideRecordingLayout } = project

        return (
            <>
                <div className="project-preferences-switch">
                    <Switch
                        value={overrideRecordingLayout}
                        setValue={(value) => project.setOverrideRecordingLayout(value)}
                        tooltip={t('Override default layout')}
                    >
                        <TableIcon className={`generic-icon ${iconClassName}`} tooltip={t('Recording page layout')} />
                    </Switch>
                </div>
                {overrideRecordingLayout && (
                    <div className="override-recording-layout-container">
                        <SegmentEditorPanelLayoutView project={project} />
                        <StudyTabsLayoutView project={project} />
                    </div>
                )}
            </>
        )
    }
)
