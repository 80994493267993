import { observable } from 'mobx'

import { IDB } from './IDB'
import { StubDB } from './StubDB'
import { normalizeUsername } from './Utils'

export const RANK_STEP = 100

export abstract class DBObject {
    _id: string

    db: IDB

    rank = 'xxxxxxxxxxxxxxxx'

    @observable removed = false

    creator: string

    creationDate: string // '0000-00-00 hh:mm'

    modDate: string // '0000-00-00 hh:mm'

    modBy = ''

    constructor(_id: string, db?: IDB) {
        // If not db supplied, create a stub. It will throw if accessed.
        db = db ?? new StubDB()

        this._id = _id
        this.db = db
        this.creator = normalizeUsername(db.username)

        this.creationDate = db.getDate()
        this.modDate = this.creationDate
        this.modBy = this.creator
    }

    _toDocument(init: any, useExistingModDate?: boolean, useExistingModBy?: boolean) {
        const { _id, creator, creationDate, modDate, modBy, db } = this
        const doc: any = { _id, creator, creationDate }
        doc.modDate = useExistingModDate && modDate ? modDate : db.getDate()
        doc.modBy = useExistingModBy && modBy ? modBy : normalizeUsername(db.username)
        Object.assign(doc, init)
        return doc
    }

    setUMTRank() {
        // Create a rank based on UMT time
        let iso = new Date(Date.now()).toISOString()
        iso = iso.replace('-', '/')
        iso = iso.replace('-', '/') // replace second occurence of -
        iso = iso.replace('T', ' ')
        this.rank = iso // format: 2020/07/30 17:15
    }

    // Return the rank as a number.
    // If the rank is not a valid number default to 1 so that
    // later calculations return a valid value.
    get rankAsNumber() {
        const rank = parseFloat(this.rank)
        if (isNaN(rank)) return 1
        return rank
    }

    static numberToRank(rank: number): string {
        // Create string form of number that correctly sorts by numeric value
        return rank.toFixed(4).padStart(16, '0')
    }

    static getNextRank(dbObjects: DBObject[]) {
        const rank = dbObjects.length ? dbObjects[dbObjects.length - 1].rankAsNumber + RANK_STEP : RANK_STEP
        return DBObject.numberToRank(rank)
    }
}
