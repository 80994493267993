import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { DEFAULT_LANGUAGE_CODE } from '../components/utils/Languages'
import { fetchGlossaryIndex, getPericopeGlossaryTermsSteps, Step } from '../resources/ExegeticalResources'

export const useGlossary = (pericopeId: string) => {
    const { i18n } = useTranslation()
    const uiLanguage = i18n.language ?? DEFAULT_LANGUAGE_CODE
    const [glossaryTerms, setGlossaryTerms] = useState<Step[]>([])

    useEffect(() => {
        const fetchTerms = async () => {
            if (pericopeId) {
                const [{ glossary }] = await Promise.all([fetchGlossaryIndex()])
                const glossaryTermsSteps = getPericopeGlossaryTermsSteps(pericopeId, glossary, uiLanguage)
                setGlossaryTerms(glossaryTermsSteps)
            } else {
                setGlossaryTerms([])
            }
        }
        fetchTerms()
    }, [pericopeId, uiLanguage])

    return glossaryTerms
}
