import React, { Component, FC, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'

import { Passage } from '../../models3/Passage'
import { PassageSegmentApproval } from '../../models3/PassageSegment'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { useAppRoot } from '../app/RootContext'
import { CopyProjectDataModal } from '../modals/project/CopyDataModal'
import { PassageNotificationList } from '../notifications/Notifications'
import { CopyButton, SlttHelp } from '../utils/Buttons'
import { displayError, systemError } from '../utils/Errors'
import { CheckCircle, CheckIcon, ThinCircleIcon } from '../utils/Icons'
import { MemberDisplay } from '../utils/MemberDisplay'

import 'react-confirm-alert/src/react-confirm-alert.css'
import './Passage.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Passage')

type PassageNameProps = {
    rt: Root
    passage: Passage
    nameOverflowed: boolean
    buttonRef: React.RefObject<HTMLButtonElement>
    onClick: () => void
}

const PassageName: FC<PassageNameProps> = observer(({ rt, passage, nameOverflowed, buttonRef, onClick }) => {
    const [trackedProject, setTrackedProject] = useState<Project | undefined>(undefined)

    const { project } = rt
    const { members } = project
    const { assignee } = passage
    const { videos } = passage

    const appRoot = useAppRoot()

    useEffect(() => {
        async function getTrackedProject() {
            if (passage.trackedProjectName.trim() !== '') {
                const trackedRt = appRoot.rts.find((r) => r.name === passage.trackedProjectName)
                await trackedRt?.initialize()
                setTrackedProject(trackedRt?.project)
            } else {
                setTrackedProject(undefined)
            }
        }

        getTrackedProject()
    }, [trackedProject?.displayName, passage.trackedProjectName, appRoot.rts])

    const present = videos.length > 0
    const passageSelected = rt.passage && passage && rt.passage._id === passage._id

    const buttonCN = `btn passage-button${passageSelected ? ' passage-selected' : ''}${
        present ? ' passage-video-present' : ''
    }`

    const currentMember = members.find((mem) => mem.email === assignee)
    const plan = project.plans.length ? project.plans[0] : null
    const task = plan?.tasks.find((planTask) => planTask.id === passage.task)
    if (currentMember) {
        const tooltip = (
            <Tooltip id="passage-tooltip">
                {passage.name}
                {trackedProject && (
                    <div>{t('Copied from project {{projectName}}', { projectName: trackedProject.displayName })}</div>
                )}
                {task && passage.task !== '' && <div>{`${t('Current task:')} ${task.displayedName}`}</div>}
                <div>
                    {t('Assigned to:')}{' '}
                    <MemberDisplay member={currentMember} imageSize="small" onlyShowAvailableParts />
                </div>
            </Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip} placement="bottom" delay={{ show: 1000, hide: 0 }}>
                <button type="button" className={buttonCN} ref={buttonRef} onClick={onClick}>
                    {passage.name}
                </button>
            </OverlayTrigger>
        )
    }

    if (nameOverflowed) {
        const tooltip = (
            <Tooltip id="passage-tooltip">
                {passage.name}
                {trackedProject && (
                    <div>{t('Copied from project {{projectName}}', { projectName: trackedProject.displayName })}</div>
                )}
            </Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip} placement="bottom">
                <button type="button" className={buttonCN} ref={buttonRef} onClick={onClick}>
                    {passage.name}
                </button>
            </OverlayTrigger>
        )
    }

    if (trackedProject) {
        const tooltip = (
            <Tooltip id="passage-tooltip">
                {passage.name}
                <div>{t('Copied from project {{projectName}}', { projectName: trackedProject.displayName })}</div>
            </Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip} placement="bottom" delay={{ show: 1000, hide: 0 }}>
                <button type="button" className={buttonCN} ref={buttonRef} onClick={onClick}>
                    {passage.name}
                </button>
            </OverlayTrigger>
        )
    }

    return (
        <button type="button" className={buttonCN} ref={buttonRef} onClick={onClick}>
            {passage.name}
        </button>
    )
})

type PassageMenuProps = {
    iAmTranslatorForPassage: (passage: Passage) => boolean
    passage: Passage
    setCopyModalOpen: (value: boolean) => void
    setEditing: (editing: boolean) => void
    onDelete: () => void
}

const PassageMenu: FC<PassageMenuProps> = ({
    iAmTranslatorForPassage,
    passage,
    setCopyModalOpen,
    setEditing,
    onDelete
}) => (
    <div className="passage-menu">
        {iAmTranslatorForPassage(passage) && (
            <>
                <SlttHelp tooltip={t('Edit')} id="passages-info" place="bottom">
                    <span
                        className="passage-menu-button"
                        data-id={`edit-${passage.name}`}
                        onClick={() => setEditing(true)}
                    >
                        <i className="fas fa-fw fa-pencil-alt" />
                    </span>
                </SlttHelp>
                <span
                    className="passage-menu-button"
                    data-toggle="tooltip"
                    data-id={`delete-${passage.name}`}
                    title={t('Delete passage.')}
                    onClick={onDelete}
                >
                    <i className="fas fa-fw fa-trash-alt" />
                </span>
            </>
        )}
        <CopyButton
            onClick={() => setCopyModalOpen(true)}
            buttonClassName="passage-copy-button"
            className="passage-menu-button"
            tooltip={t('Copy passage')}
            enabled
        />
    </div>
)

function overallApprovalState(approvalStates: PassageSegmentApproval[]) {
    if (!approvalStates.length) {
        return PassageSegmentApproval.State0
    }

    const hasState1 = (state: PassageSegmentApproval) =>
        state === PassageSegmentApproval.State1 || state === PassageSegmentApproval.State3
    const hasState2 = (state: PassageSegmentApproval) =>
        state === PassageSegmentApproval.State2 || state === PassageSegmentApproval.State3
    const hasState3 = (state: PassageSegmentApproval) => state === PassageSegmentApproval.State3

    if (approvalStates.every(hasState3)) {
        return PassageSegmentApproval.State3
    }
    if (approvalStates.every(hasState2)) {
        return PassageSegmentApproval.State2
    }
    if (approvalStates.every(hasState1)) {
        return PassageSegmentApproval.State1
    }

    return PassageSegmentApproval.State0
}

type PassageApprovalStatusProps = {
    rt: Root
    passage: Passage
}

const PassageApprovalStatus: FC<PassageApprovalStatusProps> = observer(({ rt, passage }) => {
    const { passageVideo } = rt
    let currentDraft = passage.latestVideo // Default to latest video draft
    // If this is the currently selected passage, show the status for the currently selected passageVideo draft
    if (passage._id === rt.passage?._id && passageVideo) {
        currentDraft = passageVideo
    }

    const approvalStates = currentDraft?.visibleSegments(passage).map((seg) => seg.approved) || []
    const approvalState = overallApprovalState(approvalStates)

    const options = [
        <span className="passage-menu-item sl-fa-button">&mdash;</span>,
        <CheckIcon className="passage-menu-item" tooltip="" />,
        <ThinCircleIcon className="passage-menu-item" tooltip="" />,
        <CheckCircle className="passage-menu-item" tooltip="" />
    ]
    return approvalState > PassageSegmentApproval.State0 ? (
        <div className="passage-approval-state">{options[approvalState]}</div>
    ) : null
})

// CRASHING when I try to use MouseHoveringDetection
// export default MouseHoveringDetection(Passage)

interface IPassageView {
    rt: Root
    passage: Passage
    copyModalOpen: boolean
    setCopyModalOpen: (copyModalOpen: boolean) => void
    editing: boolean
    setEditing: (editing: boolean) => void
    showEditingIcons: boolean
}

class PassageView extends Component<IPassageView> {
    @observable uploadMessage = ''

    buttonRef = React.createRef<HTMLButtonElement>()

    @observable nameOverflowed = false

    uploadVideoUrl = ''

    timer: any

    constructor(props: IPassageView) {
        super(props)
        this.onDelete = this.onDelete.bind(this)
        this.setNameOverflowed = this.setNameOverflowed.bind(this)
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchUploadStatus(), 2000)
        this.setNameOverflowed()
    }

    componentDidUpdate() {
        this.setNameOverflowed()
    }

    componentWillUnmount() {
        this.clearMyTimer()
    }

    onClick = () => {
        // e.preventDefault()  is this needed???
        this.setPassage()
    }

    onDelete() {
        // e.preventDefault()
        log('onDelete')
        const { rt, passage } = this.props
        const { portion, project } = rt
        const { reviewProject } = project

        this.confirmDeletion(() => {
            if (reviewProject) {
                passage.videos.forEach((video) => video.getLatestReviewRecording(reviewProject)?.setIsActive(false))
            }

            const { _id } = passage
            if (rt.passage && rt.passage._id === _id) {
                rt.setPassage(null)
            }

            portion?.removePassage(_id)
        })
    }

    setNameOverflowed() {
        const { current } = this.buttonRef
        if (current) {
            this.nameOverflowed = current.scrollWidth > current.offsetWidth
        }
    }

    setPassage() {
        const { rt, passage } = this.props
        rt.setPassage(passage).catch((err: any) => displayError(err))
    }

    confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('deletePassageTitle'),
            message: t('deletePassageMessage'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => {}
                },
                {
                    label: t('Yes'),
                    onClick: doDeletion
                }
            ]
        })
    }

    /**
     * Set uploadMessage to contain upload status.
     * Empty if current video does not need uploaded or has completed upload.
     */
    fetchUploadStatus() {
        const { rt, passage } = this.props
        const { passage: _passage, passageVideo } = rt

        let videoUrl = ''
        if (_passage && passage._id === _passage._id) {
            // If this is the currently selected passage, use the url for the selected video
            videoUrl = (passageVideo && passageVideo.url) || ''
        } else if (passage.videos.length) {
            // Otherwise use the url for the latest video for this passage
            videoUrl = passage.videos.slice(-1)[0].url
        }

        // If this is the same url we check last time and it had no status
        // it is unnecessary to check again.
        if (videoUrl === this.uploadVideoUrl && this.uploadMessage === '') return

        if (!videoUrl) {
            // check for no current video
            this.uploadVideoUrl = ''
            this.uploadMessage = ''
            return
        }

        this.uploadVideoUrl = videoUrl
        try {
            const uploadMessage = VideoCache.queryVideoUpload(videoUrl)
            this.uploadMessage = uploadMessage
        } catch (error: any) {
            this.clearMyTimer()
            systemError(error)
        }
    }

    clearMyTimer() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }

    render() {
        const { rt, passage, copyModalOpen, setCopyModalOpen, setEditing, showEditingIcons } = this.props
        const { portion, iAmTranslatorForPassage, useMobileLayout } = rt
        const { onDelete, buttonRef, nameOverflowed, uploadMessage, onClick } = this

        if (!portion) {
            return null
        }

        return (
            <div className="passage-box">
                {copyModalOpen && (
                    <CopyProjectDataModal
                        setOpenModal={setCopyModalOpen}
                        source={rt.project}
                        copyDataType="passage"
                        copyData={passage}
                    />
                )}
                <div className="passage-name">
                    <PassageName {...{ rt, passage, nameOverflowed, onClick, buttonRef }} />
                    <div className="passage-notifications">
                        <PassageNotificationList rt={rt} passage={passage} />
                    </div>
                    <PassageApprovalStatus {...{ rt, passage }} />
                    {!useMobileLayout && showEditingIcons && (
                        <PassageMenu
                            {...{ iAmTranslatorForPassage, passage, setCopyModalOpen, setEditing, onDelete }}
                        />
                    )}
                </div>
                {uploadMessage && <div className="passage-upload-status"> {uploadMessage} </div>}
            </div>
        )
    }
}

export default observer(PassageView)
