import { FC } from 'react'

import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import MemberRoleView from './MemberRole'
import { Member, MemberRole } from '../../../models3/Member'
import { Root } from '../../../models3/Root'
import { DeleteButton } from '../../utils/Buttons'
import { displayError } from '../../utils/Errors'
import { MemberDisplay } from '../../utils/MemberDisplay'

import '../ProjectSettings.css'

interface IMemberView {
    member: Member
    rt: Root
    isSoleAdmin: boolean
}

const MemberView: FC<IMemberView> = ({ member, rt, isSoleAdmin }) => {
    const { t } = useTranslation()
    const { iAmAdmin } = rt

    const onSetRole = (role: MemberRole) => {
        rt.project.setMemberRole(member.email, role).catch(displayError)
    }

    const removeMember = () => {
        confirmAlert({
            title: t('removeUser'),
            message: t('removeUserConfirm', { userEmail: member.email }),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('removeUser'),
                    onClick: () => rt.project.removeMember(member.email).catch(displayError)
                }
            ]
        })
    }

    return (
        <tr>
            <td>
                <MemberDisplay member={member} imageSize="large" showFullName />
            </td>
            <td className="vertically-centered-text">
                <MemberRoleView member={member} iAmAdmin={iAmAdmin} isSoleAdmin={isSoleAdmin} onChange={onSetRole} />
            </td>
            <td>
                {iAmAdmin && !isSoleAdmin && (
                    <DeleteButton
                        className="sl-delete-button"
                        tooltip={t('Remove this member from project')}
                        enabled
                        onClick={removeMember}
                    />
                )}
            </td>
        </tr>
    )
}

export default MemberView
