import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import ReactDOMServer from 'react-dom/server'
import WaveSurfer from 'wavesurfer.js'
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers'
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions'
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { Root } from '../../models3/Root'
import { TimelineSelection } from '../../models3/RootBase'
import NoteMarker from '../notes/NoteMarker'
import { getNoteColor } from '../notes/NoteMarkers'
import { getAllSegmentStatusMarkers } from '../segments/SegmentStatus'
import { clamp, doRangesOverlap } from '../utils/Helpers'
import { StarIcon } from '../utils/Icons'

import './WaveSurferAudioPlayerCore.css'

const MARKER_SIZE_PX = 15
const MARKER_LABEL_CLASS_NAME = 'marker-label'

const enum RegionType {
    segment = 'segment',
    selection = 'selection'
}

const getMarkerId = (wavesurferMarker: any) => {
    let markerId = ''
    const markerLabelElement: any = Array.from(wavesurferMarker.el.children).find(
        (el: any) => el.className === MARKER_LABEL_CLASS_NAME
    )
    if (markerLabelElement) {
        const markerElement: any = Array.from(markerLabelElement.children).find(
            (el: any) => el.dataset.markerId !== undefined
        )
        if (markerElement) {
            markerId = markerElement.dataset.markerId
        }
    }
    return markerId
}

const findMarker = ({
    markerId,
    passage,
    passageVideo
}: {
    markerId: string
    passage: Passage
    passageVideo: PassageVideo
}) => {
    return (
        passageVideo.getVisibleReferenceMarkers(passage).find((v) => v._id === markerId) ??
        passageVideo.getVisibleBiblicalTermMarkers(passage).find((v) => v._id === markerId)
    )
}

const findNoteMarker = ({
    markerId,
    passage,
    passageVideo,
    showConsultantOnlyNotes
}: {
    markerId: string
    passage: Passage
    passageVideo: PassageVideo
    showConsultantOnlyNotes: boolean
}) => {
    return passageVideo.getVisibleNotes(passage, showConsultantOnlyNotes).find((v) => v._id === markerId)
}

interface SpanWithWidth extends HTMLSpanElement {
    width: number
}

const createNoteMarker = ({
    note,
    enabled,
    mostRecentNoteIdViewed,
    noteColors,
    noteLabels
}: {
    note: PassageNote
    enabled: boolean
    mostRecentNoteIdViewed: string
    noteColors: string[]
    noteLabels: string[]
}) => {
    const color = getNoteColor(note, noteColors, enabled)
    const style = { color }

    let markerShape = (
        <NoteMarker
            className="note-icon"
            enabled={enabled}
            note={note}
            noteColors={noteColors}
            noteLabels={noteLabels}
        />
    )

    if (mostRecentNoteIdViewed === note._id) {
        markerShape = (
            <span className="wavesurfer-recent-note" style={style}>
                {markerShape}
            </span>
        )
    }

    const markerElement = document.createElement('span') as SpanWithWidth
    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(markerShape)
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = note._id
    return markerElement
}

export const createSegmentApprovalMarker = (segmentStatusLabels: string[], segment: PassageSegment) => {
    const className = 'approval-icon'
    const approvalTypeIndex = Number(segment.approved) - 1
    if (approvalTypeIndex < 0 || approvalTypeIndex > segmentStatusLabels.length - 1) {
        return
    }

    const tooltip = segmentStatusLabels[approvalTypeIndex]
    const markerShape = getAllSegmentStatusMarkers({ className, tooltip })[approvalTypeIndex]
    const markerElement = document.createElement('span') as SpanWithWidth
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.className = 'approval-icon-wrapper'
    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(markerShape)
    return markerElement
}

export const createBiblicalTermMarker = ({ marker, terms }: { marker: BiblicalTermMarker; terms: ProjectTerm[] }) => {
    const markerElement = document.createElement('span') as SpanWithWidth
    let glossText = ''
    for (const term of terms) {
        for (const gloss of term.glosses) {
            if (gloss._id === marker.targetGlossId) {
                glossText = gloss.text
                break
            }
        }
        if (glossText !== '') {
            break
        }
    }

    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(<StarIcon className="key-term-marker-icon" />)

    markerElement.setAttribute('title', glossText)
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = marker._id
    return markerElement
}

const getVerseReferenceMarkerLabel = (references: string) => {
    if (!references.includes(';') && references.includes(':')) {
        return references.substring(references.lastIndexOf(' ') + 1)
    }

    return references
}

export const createVerseReferenceMarker = ({ marker, rt }: { marker: ReferenceMarker; rt: Root }) => {
    const references = rt.displayableReferences(marker.references)
    const label = getVerseReferenceMarkerLabel(references)
    const color = 'violet'

    const markerElement = document.createElement('span') as SpanWithWidth
    markerElement.setAttribute('title', references)
    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <div className="verse-marker">
            <svg viewBox="0 0 24 24" className="verse-icon">
                <g>
                    <line x1="4" y1="8" x2="12" y2="24" strokeWidth="5" stroke={color} />
                    <line x1="12" y1="24" x2="20" y2="8" strokeWidth="5" stroke={color} />
                </g>
            </svg>
            <div style={{ color, fontWeight: 600 }}> {label}</div>
        </div>
    )

    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = marker._id
    return markerElement
}

const placeMarker = ({
    marker,
    rt,
    wavesurferRef
}: {
    marker: ReferenceMarker | BiblicalTermMarker
    rt: Root
    wavesurferRef: MutableRefObject<WaveSurfer | null>
}) => {
    const { iAmInterpreter, passage, portion, project } = rt
    let markerElement
    if (marker instanceof ReferenceMarker) {
        markerElement = createVerseReferenceMarker({ marker, rt })
    } else {
        if (!passage) {
            return
        }

        const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion })
        const terms = project.getKeyTermsThatOccurInVerses(refRanges)
        markerElement = createBiblicalTermMarker({ marker, terms })
    }

    wavesurferRef.current?.markers.add({
        time: marker.time,
        position: 'top',
        draggable: iAmInterpreter,
        markerElement
    })
}

interface WaveSurferAudioPlayerCoreProps {
    url: string
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    playbackRate: number
    minPxPerSecond: number
    setZoomOutEnabled: (value: boolean) => void
    setMinPxPerSecond: (value: number) => void
    setBiblicalTermMarker: (marker: BiblicalTermMarker) => void
}

export const WaveSurferAudioPlayerCore = observer(
    ({
        url,
        rt,
        playbackRate,
        passage,
        passageVideo,
        minPxPerSecond,
        setBiblicalTermMarker,
        setZoomOutEnabled,
        setMinPxPerSecond
    }: WaveSurferAudioPlayerCoreProps) => {
        const [ready, setReady] = useState(false)
        const [draggingSelection, setDraggingSelection] = useState(false)
        const wavesurferRef = useRef<WaveSurfer | null>(null)

        // These will force the component to rerender when anything on passage changes.
        // This is necessary to get the regions and markers to update when any field on
        // the underlying data is changed.
        const { mostRecentNoteIdViewed } = rt
        const { selection } = rt.timeline
        const { _rev } = passage

        const removeRegions = (regionType: RegionType) => {
            if (wavesurferRef.current) {
                Object.values(wavesurferRef.current.regions.list)
                    .filter((region) => region.attributes.type === regionType)
                    .forEach((region) => region.remove())
            }
        }

        // handle initial load and set up wavesurfer
        useEffect(() => {
            if (url) {
                const waveSurfer = WaveSurfer.create({
                    container: '#waveform',
                    waveColor: '#337ab7',
                    progressColor: '#337ab7',
                    cursorWidth: 2,
                    minPxPerSec: minPxPerSecond,
                    scrollParent: true,
                    plugins: [
                        TimelinePlugin.create({
                            container: '#timeline',
                            deferInit: true
                        }),
                        RegionsPlugin.create({
                            dragSelection: true
                        }),
                        MarkersPlugin.create({
                            // There is a bug in wavesurfer where if you don't provide
                            // any markers initially, you can't drag any markers. The fix
                            // is to provide a placeholder marker, which you can then
                            // delete right away.
                            // https://github.com/katspaugh/wavesurfer.js/issues/2417#issuecomment-1053659377
                            markers: [{ draggable: true, time: 0 }]
                        })
                    ]
                })

                wavesurferRef.current = waveSurfer

                wavesurferRef.current.load(url)

                wavesurferRef.current.on('ready', () => {
                    wavesurferRef.current?.initPlugin('timeline')

                    const duration = wavesurferRef.current?.getDuration()
                    const timeline = wavesurferRef.current?.timeline
                    if (duration && timeline) {
                        // set scrolling
                        const { clientLeft, clientWidth } = timeline.wrapper
                        const totalWidth = timeline.canvases.reduce(
                            (size, canvas) => size + canvas.getBoundingClientRect().width,
                            0
                        )
                        const scrollWidth = clientWidth - clientLeft
                        setZoomOutEnabled(scrollWidth < totalWidth)

                        // Removing Math.round causes this effect to run too many times on certain
                        // recordings on certain computers (William's Windows laptop).
                        setMinPxPerSecond(Math.round(totalWidth / duration))
                    }

                    setReady(true)
                })

                wavesurferRef.current.on('seek', (seekTime) => {
                    if (wavesurferRef.current) {
                        // seek time is from 0..1
                        rt.resetCurrentTime(seekTime * wavesurferRef.current.getDuration())
                    }
                })

                wavesurferRef.current.on('region-click', () => {
                    rt.timeline.selection = undefined
                })

                wavesurferRef.current.on('region-created', (region) => {
                    if (region.attributes.type !== RegionType.segment) {
                        region.attributes.type = RegionType.selection
                    }
                })

                const seekToRegionEnd = (regionEnd: number) => {
                    if (!wavesurferRef.current) return

                    const seekTo = regionEnd / wavesurferRef.current.getDuration()
                    if (!isNaN(seekTo) && seekTo !== Infinity) {
                        wavesurferRef.current.seekTo(seekTo)
                    }
                }

                wavesurferRef.current.on('region-updated', (region) => {
                    if (region.attributes.type === RegionType.selection) {
                        if (wavesurferRef.current) {
                            seekToRegionEnd(region.end)
                        }
                        setDraggingSelection(true)
                        rt.timeline.selection = new TimelineSelection(region.start, region.end)
                    }
                })

                wavesurferRef.current.on('region-update-end', (region) => {
                    if (region.attributes.type === RegionType.selection) {
                        const segmentsRange = Object.values(wavesurferRef.current?.regions.list || {})
                            .filter((r) => r.attributes.type === RegionType.segment)
                            .map((r) => ({
                                start: r.start,
                                end: r.end
                            }))
                        const selectedSegmentRange = segmentsRange.filter((range) => doRangesOverlap(range, region))

                        if (wavesurferRef.current) {
                            const buffer = 0.001
                            if (selectedSegmentRange.length === 1) {
                                seekToRegionEnd(region.end)
                                rt.timeline.selection = new TimelineSelection(region.start, region.end)
                            }

                            if (selectedSegmentRange.length === 2) {
                                const startDiff = selectedSegmentRange[0].end - region.start
                                const endDiff = region.end - selectedSegmentRange[1].start
                                const isStart = startDiff > endDiff

                                const start = isStart ? region.start : selectedSegmentRange[1].start + buffer
                                const end = isStart ? selectedSegmentRange[0].end - buffer : region.end

                                seekToRegionEnd(end)
                                rt.timeline.selection = new TimelineSelection(start, end)
                            }

                            if (selectedSegmentRange.length > 2) {
                                const rangeDiffArr = selectedSegmentRange.map((range, i) => {
                                    if (i === 0) {
                                        return {
                                            start: region.start,
                                            end: range.end,
                                            diff: range.end - region.start
                                        }
                                    }

                                    if (i === selectedSegmentRange.length - 1) {
                                        return {
                                            start: range.start,
                                            end: region.end,
                                            diff: region.end - range.start
                                        }
                                    }

                                    return {
                                        start: range.start,
                                        end: range.end,
                                        diff: range.end - range.start
                                    }
                                })

                                const rangeDiffMax = rangeDiffArr.reduce(
                                    (prev, current) => (prev.diff > current.diff ? prev : current),
                                    rangeDiffArr[0]
                                )

                                seekToRegionEnd(rangeDiffMax.end - buffer)
                                rt.timeline.selection = new TimelineSelection(
                                    rangeDiffMax.start + buffer,
                                    rangeDiffMax.end - buffer
                                )
                            }
                        }

                        setDraggingSelection(false)
                    }
                })

                wavesurferRef.current.on('marker-drag', () => {
                    wavesurferRef.current?.regions.disableDragSelection()
                })

                wavesurferRef.current.on('marker-drop', (wavesurferMarker) => {
                    wavesurferRef.current?.regions.enableDragSelection({})

                    const markerId = getMarkerId(wavesurferMarker)
                    if (!markerId) {
                        return
                    }

                    const marker = findMarker({ markerId, passage, passageVideo })
                    if (!marker) {
                        return
                    }

                    const time = wavesurferMarker.time
                    const { computedDuration } = passageVideo
                    const index = wavesurferRef.current?.markers.markers.indexOf(wavesurferMarker) ?? -1

                    let markers
                    if (marker instanceof ReferenceMarker) {
                        markers = passageVideo.getVisibleReferenceMarkers(passage)
                    } else if (marker instanceof BiblicalTermMarker) {
                        markers = passageVideo.getVisibleBiblicalTermMarkers(passage)
                    } else {
                        return
                    }

                    if (marker.canChangePositionToTime({ time, computedDuration, markers })) {
                        passageVideo.saveMarkerPosition(passage, time, marker)
                    } else if (index >= 0) {
                        // Move marker back to where it was
                        wavesurferRef.current?.markers.remove(index)
                        placeMarker({ marker, rt, wavesurferRef })
                    }
                })

                wavesurferRef.current.on('marker-click', (wavesurferMarker) => {
                    const markerId = getMarkerId(wavesurferMarker)
                    if (!markerId) {
                        return
                    }

                    const marker = findMarker({ markerId, passage, passageVideo })
                    if (marker instanceof ReferenceMarker) {
                        rt.verseReference = marker
                    } else if (marker instanceof BiblicalTermMarker) {
                        setBiblicalTermMarker(marker)
                    } else {
                        const note = findNoteMarker({
                            markerId,
                            passage,
                            passageVideo,
                            showConsultantOnlyNotes: rt.canViewConsultantOnlyFeatures
                        })
                        if (note) {
                            rt.setNote(note)
                        }
                    }
                })
            }

            return () => {
                setReady(false)
                wavesurferRef.current?.unAll()
                wavesurferRef.current?.destroy()
                wavesurferRef.current = null
            }
        }, [
            minPxPerSecond,
            passage,
            passageVideo,
            rt,
            setBiblicalTermMarker,
            setMinPxPerSecond,
            setZoomOutEnabled,
            url
        ])

        // handle when playing or dragging
        useEffect(() => {
            if (!ready || !wavesurferRef.current || wavesurferRef.current.isPlaying() || draggingSelection) {
                return
            }
            const duration = wavesurferRef.current.getDuration()
            if (!duration) {
                return
            }
            // Disable seeking so that we don't update rt.currentTime. Sometimes
            // there is a rounding error when seeking, which causes rt.currentTime
            // to be changed.
            wavesurferRef.current.setDisabledEventEmissions(['seek'])

            const timeline = wavesurferRef.current.timeline
            const { clientLeft, clientWidth, scrollLeft } = timeline.wrapper
            const totalWidth = timeline.canvases.reduce(
                (size, canvas) => size + canvas.getBoundingClientRect().width,
                0
            )
            const scrollRight = scrollLeft + (clientWidth - clientLeft)

            // Seek time must be between 0 and 1
            const seekTo = clamp(rt.currentTime / duration, 0, 1)
            const cursorOutOfView = seekTo * totalWidth < scrollLeft || seekTo * totalWidth > scrollRight
            if (rt.playing || cursorOutOfView) {
                wavesurferRef.current.seekAndCenter(seekTo)
            } else {
                wavesurferRef.current.seekTo(seekTo)
            }

            // Object.values(wavesurferRef.current.regions.list).forEach((region) =>
            //     region.update({ color: 'transparent' })
            // )
            // wavesurferRef.current.regions.getCurrentRegion()?.update({ color: 'rgba(68,85,90,0.1)' })

            wavesurferRef.current.setDisabledEventEmissions([])
        }, [rt.currentTime, ready, rt.playing, draggingSelection])

        // handle playback rate change
        useEffect(() => {
            wavesurferRef.current?.setPlaybackRate(playbackRate)
        }, [playbackRate])

        // handle selection
        useEffect(() => {
            if (!wavesurferRef.current || !ready) {
                return
            }

            removeRegions(RegionType.selection)

            if (!selection) {
                return
            }

            const { start, end } = selection
            wavesurferRef.current?.regions.add({
                start,
                end,
                drag: false,
                resize: false,
                attributes: { type: RegionType.selection }
            })
        }, [selection, ready])

        // handle segment changes and marker drops
        useEffect(() => {
            if (!wavesurferRef.current || !ready) {
                return
            }

            // This should remove any elements we have created, so we don't have to do it manually.
            removeRegions(RegionType.segment)
            wavesurferRef.current.markers.clear()

            // put down segment lines and segment approval markers first since they do not need to be clicked
            passageVideo.getVisibleBaseSegments().forEach((segment, index) => {
                const actualSegment = segment.actualSegment(passage)
                const start = actualSegment.positionToTime(actualSegment.position)
                const end = actualSegment.positionToTime(actualSegment.endPosition)

                wavesurferRef.current?.regions.add({
                    start,
                    end,
                    drag: false,
                    resize: false,
                    color: 'transparent',
                    attributes: {
                        type: RegionType.segment,
                        patched: segment.isPatched ? 'true' : 'false',
                        segment: segment._id,
                        label: `${index + 1}`
                    }
                })

                const markerElement = createSegmentApprovalMarker(rt.project.segmentStatusLabels, actualSegment)
                if (markerElement) {
                    wavesurferRef.current?.markers.add({
                        time: actualSegment.time,
                        position: 'top',
                        markerElement
                    })
                }
            })

            /// do not put other markers for mobile layout
            if (rt.useMobileLayout) {
                return
            }

            // put down  markers
            passageVideo.getVisibleReferenceMarkers(passage).forEach((marker) => {
                placeMarker({ marker, rt, wavesurferRef })
            })

            // put down biblical term markers
            passageVideo.getVisibleBiblicalTermMarkers(passage).forEach((marker) => {
                placeMarker({ marker, rt, wavesurferRef })
            })

            // put down note markers
            const enabled = !passage.videoBeingCompressed
            const noteColors = rt.project.allNoteColors
            passageVideo.getVisibleNotes(passage, rt.canViewConsultantOnlyFeatures).forEach((note) => {
                const markerElement = createNoteMarker({
                    noteLabels: rt.project.noteLabels,
                    note,
                    enabled,
                    mostRecentNoteIdViewed,
                    noteColors
                })
                wavesurferRef.current?.markers.add({
                    time: note.time,
                    position: 'bottom',
                    markerElement
                })
            })
        }, [passage, passageVideo, ready, rt, _rev, mostRecentNoteIdViewed]) // _rev and mostRecentNoteIdViewed need to here to detect any underlying changes

        return (
            <>
                <div id="timeline" />
                <div id="waveform" />
            </>
        )
    }
)
