import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppRoot } from './RootContext'
import { isGoAVTT, LocalStorageKeys } from './slttAvtt'
import { getPublishedBibles } from '../../resources/publishedBibles'
import { PublishedBible } from '../../types'
import { LocaleLanguages } from '../utils/Languages'

const getVisiblePublishedBibleIds = () => {
    const visibleBibles = localStorage.getItem(LocalStorageKeys.VISIBLE_PUBLISHED_BIBLES)
    return new Set(visibleBibles?.split(','))
}

const setVisiblePublishedBibleIds = (visibleBibleIds: Set<string>) =>
    localStorage.setItem(LocalStorageKeys.VISIBLE_PUBLISHED_BIBLES, Array.from(visibleBibleIds).join(','))

// order is ascending
const biblePriority = (bible: PublishedBible) => {
    let priority = 0
    if (bible.hasAudio) {
        priority += 1000 // prioritize audio
    }

    if (bible.abbreviation.endsWith('+')) {
        priority += 100 // enhanced resource
    }

    priority += bible.endBook - bible.startBook // prioritize those with more books
    return priority
}

interface PublishedBiblesContextValue {
    defaultBibleId: string
    publishedBibles: PublishedBible[]
    visibleBibles: PublishedBible[]
    visibleBibleIds: Set<string>
    updateVisibleBibles: (newVisibleBibleIds: Set<string>) => void
}

const PublishedBiblesContext = createContext<PublishedBiblesContextValue | undefined>(undefined)

export const PublishedBiblesProvider: FC = ({ children }) => {
    const {
        i18n: { language }
    } = useTranslation()
    const { rt } = useAppRoot()
    const { project } = rt ?? {}
    const [publishedBibles, setPublishedBibles] = useState<PublishedBible[]>([])
    const [visibleBibleIds, setVisibleBibleIds] = useState(getVisiblePublishedBibleIds)

    const visibleBibles = !visibleBibleIds.size
        ? publishedBibles
        : publishedBibles.filter((bible) => visibleBibleIds.has(bible.id))

    useEffect(() => {
        const fetchBibles = async () => {
            try {
                const allBibles = await getPublishedBibles()
                setPublishedBibles(isGoAVTT ? allBibles.filter((bible) => !bible.isForFOBAIOnly) : allBibles)
            } catch (error) {
                console.error('Error fetching published Bibles. Some AVTT features may not work properly.', error)
            }
        }
        fetchBibles()
    }, [project])

    const defaultBibleId = useMemo(() => {
        const languageName = LocaleLanguages.find((lang) => lang.code === language)?.name

        const languageBibles = publishedBibles
            .filter((bible) => bible.language.name === languageName)
            .map((bible) => ({ id: bible.id, name: bible.name, priority: biblePriority(bible) }))
            .sort((a, b) => b.priority - a.priority || a.name.localeCompare(b.name))
        return languageBibles.length ? languageBibles[0].id : 'GNT+' // Good News Translation
    }, [language, publishedBibles])

    const updateVisibleBibles = async (newVisibleBibleIds: Set<string>) => {
        setVisibleBibleIds(newVisibleBibleIds)
        setVisiblePublishedBibleIds(newVisibleBibleIds) // persist in local storage
    }

    return (
        <PublishedBiblesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                defaultBibleId,
                publishedBibles,
                visibleBibles,
                visibleBibleIds,
                updateVisibleBibles
            }}
        >
            {children}
        </PublishedBiblesContext.Provider>
    )
}

export const usePublishedBibles = () => {
    const context = useContext(PublishedBiblesContext)
    if (!context) {
        throw new Error('usePublishedBibles must be used within a PublishedBiblesProvider')
    }
    return context
}
