import { Fragment } from 'react'

import { observer } from 'mobx-react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { TableRow } from './TableRow'
import { Root } from '../../../models3/Root'
import { getBookNames } from '../../../resources/bookNames'
import { CachingProgress, MediaType } from '../../../types'
import { useExegeticalResources } from '../../app/ExegeticalResourcesContext'
import { usePublishedBibles } from '../../app/PublishedBiblesContext'
import { useTranslationResourceCaching } from '../../app/TranslationResourceCachingContext'
import { groupBy } from '../../utils/Helpers'
import { GenericIcon, HeadsetIcon, LoadingIcon } from '../../utils/Icons'
import { LocaleLanguages } from '../../utils/Languages'

import '../ProjectSettings.css'

export const ProjectResourcesTable = observer(({ rt }: { rt: Root }) => {
    const { t, i18n } = useTranslation()
    const { progress, isLoading } = useTranslationResourceCaching()
    const { exegeticalResourceMediaTypes } = useExegeticalResources()
    const { visibleBibles } = usePublishedBibles()
    const { project } = rt

    const entries = Object.entries(project.bookNames)

    const uiNames = getBookNames(i18n.language)

    const projectBookNames = entries.map((entry) => entry[1])

    if (uiNames.length !== projectBookNames.length) {
        return null
    }

    const cachedResourceMap = groupBy(
        Array.from(progress.entries())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => [CachingProgress.CACHED, CachingProgress.UPDATE_AVAILABLE].includes(value))
            .map(([key]) => key),
        (key) => {
            const parts = key.split('-')

            if (parts.length > 2) {
                const langCodes = LocaleLanguages.map((lang) => lang.code)

                if (langCodes.includes(parts[2])) {
                    // either "Audio" or "Text" or "Images"
                    return 'StudyGuides'
                }
                // same as above, either "Audio" or "Text"
                return 'Bibles'
            }

            return 'Images'
        }
    )
    if (isLoading) {
        return (
            <p>
                <LoadingIcon className="" />
            </p>
        )
    }

    if (!Object.keys(cachedResourceMap).length) {
        return <p>{t('noDownloadedResources')}</p>
    }

    const showMARBLEImage = Boolean(cachedResourceMap.Images?.length)
    const showFIAStudyGuide = Boolean(cachedResourceMap.StudyGuides?.length)
    const bibles = cachedResourceMap.Bibles || []
    // key.split('-')[2] is the Bible version id
    const downloadedBibles = Array.from(new Set(bibles.map((key) => key.split('-')[2])))
    const filteredVisibleBibles = visibleBibles.filter(({ id }) => downloadedBibles.includes(id))

    return (
        <Table striped style={{ width: 'fit-content' }}>
            <thead className="book-name-table-head">
                <tr>
                    <th>{t('book')}</th>
                    {showMARBLEImage && <th className="project-resource-table-cell-content">{t('marbleImages')}</th>}
                    {showFIAStudyGuide && exegeticalResourceMediaTypes.length > 0 && (
                        <th
                            className="project-resource-table-cell-content"
                            colSpan={exegeticalResourceMediaTypes.length}
                        >
                            {t('fiaStudyGuide')}
                        </th>
                    )}
                    {filteredVisibleBibles.map((version) => (
                        <th
                            key={version.id}
                            title={version.name}
                            className="project-resource-table-cell-content"
                            colSpan={version.hasAudio ? 2 : 1}
                        >
                            <span className="bible-abbreviation">{version.abbreviation}</span>
                        </th>
                    ))}
                </tr>
                <tr>
                    <th />
                    {showMARBLEImage && (
                        <th className="project-resource-table-cell-content">
                            <GenericIcon
                                iconName="fa-image"
                                className="download-bible-text-success-icon download-progress-icon"
                                tooltip={t('marbleImages')}
                                iconType="far"
                            />
                        </th>
                    )}
                    {showFIAStudyGuide &&
                        exegeticalResourceMediaTypes.map((mediaType) => (
                            <th key={mediaType} className="project-resource-table-cell-content">
                                {mediaType === MediaType.TEXT && (
                                    <GenericIcon
                                        iconName="fa-book-open"
                                        className="download-bible-text-success-icon download-progress-icon"
                                        tooltip={t('Text')}
                                        iconType="fas"
                                    />
                                )}
                                {mediaType === MediaType.AUDIO && (
                                    <HeadsetIcon className="download-bible-audio-success-icon" tooltip={t('Audio')} />
                                )}
                                {mediaType === MediaType.IMAGES && (
                                    <GenericIcon
                                        iconName="fa-image"
                                        className="download-bible-text-success-icon download-progress-icon"
                                        tooltip={t('Images')}
                                        iconType="far"
                                    />
                                )}
                            </th>
                        ))}
                    {filteredVisibleBibles.map((version) => (
                        <Fragment key={version.id}>
                            <th className="project-resource-table-cell-content">
                                <GenericIcon
                                    iconName="fa-bible"
                                    className="download-bible-text-success-icon download-progress-icon"
                                    tooltip={t('Text')}
                                    iconType="fas"
                                />
                            </th>
                            {version.hasAudio && (
                                <th className="project-resource-table-cell-content">
                                    <HeadsetIcon className="download-bible-audio-success-icon" tooltip={t('Audio')} />
                                </th>
                            )}
                        </Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {uiNames.map((name, i) => (
                    <TableRow
                        key={name}
                        projectBookName={projectBookNames[i]}
                        bookNumber={i + 1}
                        visibleBibles={filteredVisibleBibles}
                        showMARBLEImage={showMARBLEImage}
                        showFIAStudyGuide={showFIAStudyGuide}
                    />
                ))}
            </tbody>
        </Table>
    )
})
