import { useState } from 'react'

import { Form, FormGroup, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PassageDocumentCopyType } from '../../../types'
import { ModalFooter } from '../Modals'

import '../Modals.css'

interface CopyToPassageDocumentModalProps {
    setOpen: (value: boolean) => void
    onOK: (options: { copyType: PassageDocumentCopyType }) => void
    enableCopyingRecording: boolean
}

export const CopyToPassageDocumentModal = ({
    setOpen,
    onOK,
    enableCopyingRecording
}: CopyToPassageDocumentModalProps) => {
    const [copyType, setCopyType] = useState<PassageDocumentCopyType>(
        enableCopyingRecording ? 'passageRecording' : 'backTranslation'
    )
    const { t } = useTranslation()

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('recordingCopyDraftToPassageResource')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <FormGroup controlId="copy-to-passage-document-options">
                        <Form.Label>{t('passageResourceDataToCopy')}</Form.Label>
                        <Form.Check
                            type="radio"
                            id="passageRecording"
                            disabled={!enableCopyingRecording}
                            name="radioGroup"
                            onChange={() => {
                                setCopyType('passageRecording')
                            }}
                            checked={copyType === 'passageRecording'}
                            label={t('passageRecording')}
                        />
                        <Form.Check
                            type="radio"
                            id="backTranslation"
                            name="radioGroup"
                            onChange={() => {
                                setCopyType('backTranslation')
                            }}
                            checked={copyType === 'backTranslation'}
                            label={t('backTranslation')}
                        />
                        <Form.Check
                            type="radio"
                            id="segmentTranscription"
                            name="radioGroup"
                            onChange={() => {
                                setCopyType('transcription')
                            }}
                            checked={copyType === 'transcription'}
                            label={t('transcriptionPanel')}
                        />
                    </FormGroup>
                </form>
            </Modal.Body>
            <ModalFooter
                onOK={() => {
                    onOK({ copyType })
                    setOpen(false)
                }}
                onCancel={() => setOpen(false)}
            />
        </Modal>
    )
}
