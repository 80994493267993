/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

import { ImageResolution } from './MARBLEImages'
import { RefRange } from './RefRange'
import { MARBLE_IMAGES_BASE_PATH } from '../components/app/slttAvtt'
import { fmt } from '../components/utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ImageMetadata')

export class ImageDefinition {
    languageCode = ''

    title = ''

    description = ''
}

export class ImageMetadata {
    id = ''

    definitions: ImageDefinition[] = []

    path = '' // e.g., "TESTPROJECT"

    fileName = '' // e.g., "IMG001.jpg"

    private _sortKey = '' // determines order images are shown to the user

    copyright = ''

    references: RefRange[] = []

    constructor() {
        this.imagePath = this.imagePath.bind(this)
    }

    // Create a sort key for image.
    // Storyboards come first, then other project images, finally non project images.
    // If the title of an image begins or ends with a number we treated it as one image
    // in a storyboard.
    setSortKey() {
        const { fileName, definitions, title, references } = this
        if (definitions.length === 0) throw Error('Must set definitions before sortKey')
        if (!fileName) throw Error('Must set fileName before sortKey')

        const prefix = '|' // non project (i.e. MARBLE) images last
        const sequence = `@${references[0].startRef}@${title}` // order MARBLE images by their reference/title

        const _sortKey = prefix + sequence
        log('_sortKey', fmt({ prefix, sequence }))

        this._sortKey = _sortKey
    }

    get sortKey() {
        const { _sortKey } = this
        if (!_sortKey) throw Error('SortKey was not set')
        return _sortKey
    }

    getDefinition(language: string) {
        const { definitions } = this

        if (definitions.length === 0) return new ImageDefinition()

        let definition = definitions.find((def) => def.languageCode === language)
        definition = definition ?? definitions.find((def) => def.languageCode === 'en')
        definition = definition ?? definitions[0]

        return definition
    }

    /**
     * Use the title of the English definition as the title for the item.
     */
    get title() {
        return this.getDefinition('en').title.trim()
    }

    imagePath(resolution: ImageResolution) {
        const { path, fileName } = this
        return `${MARBLE_IMAGES_BASE_PATH}/images_resolutions/${resolution}/${path}/${fileName}`
    }

    fallsInRanges(refRanges: RefRange[]) {
        return refRanges.some((refRange) => refRange.overlaps(this.references))
    }
}
