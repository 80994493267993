import { TFunction } from 'i18next'
import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { Project } from '../../models3/Project'
import { InternalProjectStatus } from '../../models3/ProjectTask'
import { Root } from '../../models3/Root'
import { PassageDocumentCopyType, RecordingType } from '../../types'
import { uploadRecording } from '../filePickers/BaseRecordingFilePicker'
import { CopyPassageVersionModal } from '../modals/project/CopyPassageVersionModal'
import { CopyToPassageDocumentModal } from '../modals/project/CopyToPassageDocumentModal'
import { AudioEncodeType, getPassageAudio } from '../utils/DownloadPassage'
import { displayError, displayInfo, systemError } from '../utils/Errors'
import { CopyIcon, FolderIcon } from '../utils/Icons'
import VideoCompressor from '../utils/VideoCompressor'

const downloadPassageVideo = async (rt: Root, setProgressMessage: (message: string) => void) => {
    const { currentVideos, passage } = rt
    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project

    if (!passage || !(await VideoCompressor.checkIfServerRunning())) {
        setProgressMessage('')
        return
    }

    const compressor = new VideoCompressor(
        {
            crf: compressedVideoQuality,
            resolution: compressedVideoResolution,
            maxFileSizeMB: maxVideoSizeMB
        },
        setProgressMessage
    )
    return compressor.concatenateSelection(currentVideos, passage, -1, -1)
}

export const downloadPassageRecording = async (rt: Root, t: TFunction) => {
    const { passage, passageVideo } = rt
    if (!passage || !passageVideo) {
        return
    }

    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

    try {
        setProgressMessage(t('Initializing...'))
        const blob = await downloadPassageVideo(rt, setProgressMessage)
        setProgressMessage('')
        return { blob }
    } catch (error) {
        setProgressMessage('')
        systemError(error)
        return { blob: undefined }
    }
}

const copyRecordingToNewDraft = async ({
    rt,
    preserveSegmentation,
    t
}: {
    rt: Root
    preserveSegmentation: boolean
    t: TFunction
}) => {
    const { passage, passageVideo } = rt
    if (!passage || !passageVideo) {
        return
    }

    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const saveAsAudio = rt.currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())
        const recording = saveAsAudio
            ? await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
            : await downloadPassageRecording(rt, t)

        const fileName = saveAsAudio ? 'concatenatedBlob.ogg' : 'concatenatedBlob.mp4'
        if (recording && recording.blob) {
            await uploadRecording({
                file: new File([recording.blob], fileName, { type: recording.blob.type }),
                rt,
                passage,
                videoToCopyFrom: passageVideo,
                t,
                baseVideoOptions: { preserveSegmentation },
                recordingType: RecordingType.BASE
            })
        }
    } catch (err) {
        displayError(t('recordingUnsuccessfulCopy'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const copyAudioRecordingToPassageResource = async ({ rt, title, t }: { rt: Root; title: string; t: TFunction }) => {
    const { passage, passageVideo } = rt
    if (!passage || !passageVideo) {
        return
    }

    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const { blob: concatenatedBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
        if (concatenatedBlob) {
            const document = passage.createDocument(title)
            await passage.addDocument(document)
            await document.uploadAudioFile(
                new File([concatenatedBlob], 'concatenatedBlob.ogg', { type: concatenatedBlob.type }),
                rt.name
            )
            displayInfo(t('passageResourceSuccessfulCreation', { title }))
        }
    } catch (err) {
        displayError(t('passageResourceUnsuccessfulCreation'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const createPassageResource = async ({
    rt,
    title,
    getText,
    t
}: {
    rt: Root
    title: string
    getText: (params: { passage: Passage; project: Project }) => string
    t: TFunction
}) => {
    const { passage, passageVideo } = rt
    if (!passage || !passageVideo) {
        return
    }

    try {
        const document = passage.createDocument(title)
        document.text = getText({ passage, project: rt.project })
        await passage.addDocument(document)
        displayInfo(t('passageResourceSuccessfulCreation', { title }))
    } catch (err) {
        displayError(t('passageResourceUnsuccessfulCreation'))
    }
}

const copyBackTranslationToPassageResource = async ({ rt, title, t }: { rt: Root; title: string; t: TFunction }) => {
    createPassageResource({
        rt,
        title,
        getText: (params) => rt.passageVideo?.getBackTranslation(params).text || '',
        t
    })
}

const copySegmentTranscriptionToPassageResource = async ({
    rt,
    title,
    t
}: {
    rt: Root
    title: string
    t: TFunction
}) => {
    createPassageResource({
        rt,
        title,
        getText: (params) => rt.passageVideo?.getTranscription(params).text || '',
        t
    })
}

const copyToPassageResource = async ({
    rt,
    copyType,
    t
}: {
    rt: Root
    copyType: PassageDocumentCopyType
    t: TFunction
}) => {
    const { passage, passageVideo } = rt
    if (!passage || !passageVideo) {
        return
    }

    type TypeMap = {
        [key in PassageDocumentCopyType]: {
            titlePrefix: string
            copyFunction: (args: { rt: Root; title: string; t: TFunction }) => void
        }
    }

    const getTypeMapping = (type: PassageDocumentCopyType) => {
        const typeMap: TypeMap = {
            passageRecording: {
                titlePrefix: t('passageRecording'),
                copyFunction: copyAudioRecordingToPassageResource
            },
            backTranslation: {
                titlePrefix: t('backTranslation'),
                copyFunction: copyBackTranslationToPassageResource
            },
            transcription: {
                titlePrefix: t('transcriptionPanel'),
                copyFunction: copySegmentTranscriptionToPassageResource
            }
        }
        return typeMap[type]
    }

    const { titlePrefix, copyFunction } = getTypeMapping(copyType)

    const title = `${titlePrefix} (${passageVideo.label(passage)})`
    copyFunction({ rt, title, t })
}

export const VideoToolbarCopyMenuItems = observer(
    ({
        rt,
        className,
        isCopyNewVersionModalOpen: copyModalOpen,
        setIsCopyNewVersionModalOpen: setCopyModalOpen,
        isCopyToDocumentModalOpen: copyToPassageDocumentModalOpen,
        setIsCopyToDocumentModalOpen: setCopyToPassageDocumentModalOpen
    }: {
        rt: Root
        className: string
        isCopyNewVersionModalOpen: boolean
        setIsCopyNewVersionModalOpen: (value: boolean) => void
        isCopyToDocumentModalOpen: boolean
        setIsCopyToDocumentModalOpen: (value: boolean) => void
    }) => {
        const { t } = useTranslation()
        const { passage, passageVideo, currentVideos, iAmTranslatorForPassage } = rt

        if (!passage || !passageVideo) {
            return null
        }

        const iconClassName = 'main-video-clipboard-icon fa-fw'

        return (
            <>
                {copyModalOpen && (
                    <CopyPassageVersionModal
                        setOpen={setCopyModalOpen}
                        onOK={({ preserveSegmentation }) => copyRecordingToNewDraft({ rt, preserveSegmentation, t })}
                    />
                )}
                {copyToPassageDocumentModalOpen && (
                    <CopyToPassageDocumentModal
                        setOpen={setCopyToPassageDocumentModalOpen}
                        enableCopyingRecording={currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())}
                        onOK={({ copyType }) => {
                            if (!passage || !passageVideo) {
                                return
                            }

                            copyToPassageResource({ rt, copyType, t })
                        }}
                    />
                )}
                <Dropdown.Item
                    className={className}
                    onClick={() => setCopyModalOpen(true)}
                    disabled={
                        !iAmTranslatorForPassage(passage) || passageVideo.status === InternalProjectStatus.FINISHED
                    }
                >
                    <>
                        <CopyIcon className={iconClassName} tooltip="" />
                        {t('recordingCreateConcatenatedDraft')}
                    </>
                </Dropdown.Item>
                <Dropdown.Item
                    className={className}
                    onClick={() => setCopyToPassageDocumentModalOpen(true)}
                    disabled={!rt.iAmInterpreter}
                >
                    <>
                        <FolderIcon className={iconClassName} tooltip="" />
                        {t('recordingCopyDraftToPassageResource')}
                    </>
                </Dropdown.Item>
            </>
        )
    }
)
