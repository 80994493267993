import { t } from 'i18next'

import { ptxBookIds } from './bookNames'
import { RefRange, refToBookId } from './RefRange'
import { avttVersion, EXEGETICAL_RESOURCES_PATH } from '../components/app/slttAvtt'
import { deduplicateArrayByField, fetchAndWaitForBody, nnn } from '../components/utils/Helpers'
import { MediaType } from '../types'

export const PERICOPES_MEDIA_PATH = `${EXEGETICAL_RESOURCES_PATH}/media`

export const GLOSSARY_PATH = `${EXEGETICAL_RESOURCES_PATH}/glossary`

export const getExegeticalResourceBasePath = (language: string) => `${EXEGETICAL_RESOURCES_PATH}/${language}`

const getChapterPericopesUrl = (language: string, bbbccc: string) => {
    return `${getExegeticalResourceBasePath(language)}/${bbbccc}.json`
}

const getGlossaryAudioUrl = (audioFile: string) => {
    return `${GLOSSARY_PATH}/${audioFile}`
}

const getPericopeAudioUrl = (language: string, audioFile: string) => {
    return `${getExegeticalResourceBasePath(language)}/${audioFile}`
}

const getMediaItemUrl = (path: string) => {
    return `${PERICOPES_MEDIA_PATH}/${path}`
}

export interface BookIndex {
    bookChapterId: string
    audioFiles: string[]
}

export interface ExegeticalResourceBookIndex {
    generatedAt: number
    bookIndexes: BookIndex[]
}

interface Translation {
    languageCode: string
    title: string
    path?: string
}

// key is the unique identifier
export interface Medium {
    id: string
    key: string
    type: string
    translations: Translation[]
    path?: string
}

export interface PericopeMedia {
    [pericopeId: string]: Medium[]
}

export interface PericopeMediaIndex {
    generatedAt: number
    pericopeMedia: PericopeMedia
}

interface GlossaryTermTranslation {
    [key: string]: string | string[] | number | null
    id: string
    languageCode: string
    audio: string
    translatedTerm: string
    versionNumberLong: string
    versionTotal: number
    dateCompleteText: string
    dateCompleteAudio: string
    alternates: string[] | null
    descriptionHint: string | null
    textAsJson: string | null
}

interface GlossaryTerm {
    uniqueIdentifier: string
    pericopes: string[]
    bookIds: string[]
    translations: GlossaryTermTranslation[]
}

export interface Glossary {
    [id: string]: GlossaryTerm
}

export interface GlossaryIndex {
    generatedAt: number
    glossary: Glossary
}

export interface Step {
    id: string
    title: string
    audio?: string
    media?: Medium[]
    html?: string
    json?: string
    number?: number
}

export interface Pericope {
    id: string
    startChapter: number
    startVerse: number
    endChapter: number
    endVerse: number
    verseRange: string
    steps: Step[]
}

type BasePathRequest = {
    bookNumber?: number
    language: string
}

type TextPathRequest = BasePathRequest & {
    bookIndexes: BookIndex[]
}

type MediaPathRequest = BasePathRequest & {
    pericopeMedia: PericopeMedia
}

type AudioPathRequest = BasePathRequest & {
    glossary: Glossary
    bookIndexes: BookIndex[]
}

export type ExegeticalResourcePathRequest = MediaPathRequest &
    AudioPathRequest &
    TextPathRequest & {
        mediaType: MediaType
    }

const MIN_GENERATED_AT = 0

export const fetchExegeticalResourceBookIndexesResponseWithBody = async (language: string) => {
    // force re-caching whenever avttVersion changes
    const url = `${getExegeticalResourceBasePath(language)}/index.json?avttVersion=${avttVersion}`

    try {
        const { response, body } = await fetchAndWaitForBody(url)

        if (!response.ok) {
            console.error(`${response.url}: ${response.statusText}`)
            return { response, body: [] }
        }

        return { response, body }
    } catch (error) {
        console.error(url, error)
        return { response: undefined, body: [] }
    }
}

export const fetchExegeticalResourceBookIndexes = async (language: string): Promise<ExegeticalResourceBookIndex> => {
    const { response, body } = await fetchExegeticalResourceBookIndexesResponseWithBody(language)
    if (response) {
        if (Array.isArray(body)) {
            // old format
            return { generatedAt: MIN_GENERATED_AT, bookIndexes: body as BookIndex[] }
        }

        return body as ExegeticalResourceBookIndex
    }

    // just swallow error, since language index may not exist
    return { generatedAt: MIN_GENERATED_AT, bookIndexes: [] }
}

export const fetchPericopeMediaIndexResponseWithBody = async () => {
    const url = `${PERICOPES_MEDIA_PATH}/index.json?avttVersion=${avttVersion}`
    const { response, body } = await fetchAndWaitForBody(url)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    return { response, body }
}

export const fetchPericopeMediaIndex = async () => {
    const { body } = await fetchPericopeMediaIndexResponseWithBody()
    return body as PericopeMediaIndex
}

export const fetchGlossaryIndexResponseWithBody = async () => {
    const url = `${GLOSSARY_PATH}/index.json?avttVersion=${avttVersion}`
    const { response, body } = await fetchAndWaitForBody(url)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    return { response, body }
}

export const fetchGlossaryIndex = async () => {
    const { body } = await fetchGlossaryIndexResponseWithBody()
    return body as GlossaryIndex
}

const pericopesInRefRanges = (bbb: string, pericopes: Pericope[], refRanges: RefRange[]) =>
    pericopes.filter(({ startChapter, startVerse, endChapter, endVerse }) => {
        const pericopeRef = new RefRange(
            `${bbb}${nnn(startChapter)}${nnn(startVerse)}`,
            `${bbb}${nnn(endChapter)}${nnn(endVerse)}`
        )
        return pericopeRef.overlaps(refRanges)
    })

export const getMediaTranslation = ({ medium, language }: { medium: Medium; language: string }) => {
    const localeTranslation = medium.translations.find((translation) => translation.languageCode === language)
    if (localeTranslation) {
        return localeTranslation
    }

    return medium.translations.find((translation) => translation.languageCode === 'en')
}

export const getMediumPath = ({ medium, language }: { medium: Medium; language: string }) => {
    const translation = getMediaTranslation({ language, medium })
    return medium.path || translation?.path
}

const getMediumPaths = (mediums: Medium[], language: string) => {
    return mediums.map((medium) => getMediumPath({ medium, language }) ?? '').filter(Boolean)
}

const getMediaPaths = ({ bookNumber, language, pericopeMedia }: MediaPathRequest) => {
    const getMediums = () => {
        if (bookNumber === undefined) {
            return Object.values(pericopeMedia).flat()
        }

        // convert between bookNumber and ptx book id
        // then go through pericope media to find all media for this book
        const pericopeBook = ptxBookIds[bookNumber - 1].toLowerCase()
        const pericopes = Object.keys(pericopeMedia).filter((key) => key.startsWith(pericopeBook))
        return pericopes.flatMap((key) => pericopeMedia[key])
    }

    const mediums = getMediums()
    const uniqueMediums = deduplicateArrayByField(mediums, 'key')
    return getMediumPaths(uniqueMediums, language)
}

const findTranslation = (term: GlossaryTerm, languageCode: string) =>
    term.translations.find((translation) => translation.languageCode === languageCode)

const mergeTranslations = (
    defaultTranslation: GlossaryTermTranslation,
    languageTranslation?: GlossaryTermTranslation
): GlossaryTermTranslation => {
    if (!languageTranslation) {
        return { ...defaultTranslation }
    }

    return Object.entries(languageTranslation).reduce(
        (acc, [key, value]) => {
            if (value) {
                acc[key] = value
            }
            return acc
        },
        { ...defaultTranslation }
    )
}

export const getExegeticalResourcePaths = ({
    language,
    bookIndexes,
    pericopeMedia,
    glossary,
    bookNumber,
    mediaType
}: ExegeticalResourcePathRequest) => {
    const bookNumberId = bookNumber === undefined ? '' : nnn(bookNumber)
    const allBookIndexes = bookIndexes.filter(({ bookChapterId }) => bookChapterId.startsWith(bookNumberId))

    if (mediaType === MediaType.TEXT) {
        return allBookIndexes.map(({ bookChapterId }) => getChapterPericopesUrl(language, bookChapterId))
    }

    const getGlossaryTerms = () => {
        const allTerms = Object.values(glossary)
        if (bookNumber === undefined) {
            return allTerms
        }
        return allTerms.filter((entry) => entry.bookIds.includes(bookNumberId))
    }

    if (mediaType === MediaType.AUDIO) {
        const stepAudioPaths = allBookIndexes
            .flatMap(({ audioFiles }) => audioFiles)
            .map((audioFile) => getPericopeAudioUrl(language, audioFile))

        const glossaryPaths = getGlossaryTerms()
            .flatMap((entry) => {
                const defaultTranslation = findTranslation(entry, 'en')
                const languageTranslation = findTranslation(entry, language)

                if (!defaultTranslation) {
                    return ''
                }

                // TODO: what if defaultTranslation is undefined but languageTranslation is not?
                const mergedTranslation = mergeTranslations(defaultTranslation, languageTranslation)
                return mergedTranslation.audio
            })
            .filter(Boolean)
            .map((audioFile) => getGlossaryAudioUrl(audioFile))
        return [...stepAudioPaths, ...glossaryPaths]
    }

    return getMediaPaths({ bookNumber, language, pericopeMedia }).map((path) => getMediaItemUrl(path))
}

export const getPericopeGlossaryTermsSteps = (pericopeId: string, glossary: Glossary, language: string) => {
    const terms = Object.values(glossary).filter((term) => term.pericopes.includes(pericopeId))

    const steps: Step[] = []
    terms.forEach((term) => {
        const defaultTranslation = findTranslation(term, 'en')
        const languageTranslation = findTranslation(term, language)

        if (defaultTranslation) {
            const mergedTranslation = mergeTranslations(defaultTranslation, languageTranslation)
            const audio = mergedTranslation.audio ? `${GLOSSARY_PATH}/${mergedTranslation.audio}` : ''
            const title = mergedTranslation.translatedTerm
            steps.push({
                id: `${pericopeId}-glossary-${mergedTranslation.id}`,
                title,
                audio,
                json: mergedTranslation.textAsJson ?? undefined
            })
        }
    })
    return steps.sort((a, b) => a.title.localeCompare(b.title))
}

const getPericopeMediaStep = (pericopeId: string, pericopeMedia: PericopeMedia) => {
    const mediaItems = pericopeMedia[pericopeId]
    return mediaItems?.length
        ? ({
              id: `${pericopeId}-media`,
              title: `📸 ${t('visualResources')}`,
              media: mediaItems
          } as Step)
        : undefined
}

const fetchChapterPericopes = async (language: string, bbbccc: string) => {
    const url = getChapterPericopesUrl(language, bbbccc)
    const response = await fetch(url)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    const pericopes = await response.json()
    return pericopes as Pericope[]
}

export const fetchPericopes = async (language: string, refs: RefRange[]) => {
    const [{ pericopeMedia }, { glossary }] = await Promise.all([fetchPericopeMediaIndex(), fetchGlossaryIndex()])

    const allPericopes = new Map<string, Pericope[]>()

    for (const ref of refs) {
        for (const bbbccc of ref.chapterIterator()) {
            const pericopes = await fetchChapterPericopes(language, bbbccc)
            const pericopesInRefs = pericopesInRefRanges(refToBookId(bbbccc), pericopes, refs)

            pericopesInRefs.forEach((pericope) => {
                pericope.steps.unshift({ id: `${pericope.id}-heading`, title: pericope.verseRange })

                pericope.steps.forEach((step) => {
                    step.title = step.number ? `${step.number}. ${step.title}` : step.title
                    step.audio = step.audio ? `${getExegeticalResourceBasePath(language)}/${step.audio}` : ''
                })

                const mediaStep = getPericopeMediaStep(pericope.id, pericopeMedia)
                if (mediaStep) {
                    pericope.steps.push(mediaStep)
                }

                const glossaryTermsSteps = getPericopeGlossaryTermsSteps(pericope.id, glossary, language)
                if (glossaryTermsSteps.length) {
                    pericope.steps.push({
                        id: `${pericope.id}-glossary-terms`,
                        title: `🔗 ${t('glossary')}`,
                        audio: '',
                        json: ''
                    })
                }
            })

            if (pericopesInRefs.length) {
                allPericopes.set(bbbccc, pericopesInRefs)
            }
        }
    }

    return allPericopes
}
