import { FC } from 'react'

import { Redirect } from 'react-router-dom'

import { Root } from '../../models3/Root'

interface Props {
    rt: Root
}

const AuthRoute: FC<Props> = ({ children, rt }) => {
    const isBackTranslator = Boolean(rt.iAmBackTranslator)

    return isBackTranslator ? <Redirect to="/" /> : <div>{children}</div>
}

export default AuthRoute
