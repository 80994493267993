import { useTranslation } from 'react-i18next'

import SLToolWithCognito from './amplify/SLToolWithCognito'

export const App = () => {
    const { i18n } = useTranslation()

    document.body.dir = i18n.dir()
    document.body.lang = i18n.language

    return <SLToolWithCognito />
}
