import { FunctionComponent } from 'react'

import { LocaleLanguages } from '../utils/Languages'

interface ILocaleSelector {
    currentValue: string
    chooseOption: (option: string) => void
}

export const LocaleOptions = () => (
    <>
        {LocaleLanguages.map((item) => {
            const { code, name } = item
            return (
                <option key={code} value={code}>
                    {name}
                </option>
            )
        })}
    </>
)

export const LocaleSelector: FunctionComponent<ILocaleSelector> = ({ currentValue, chooseOption }) => (
    <div>
        <select className="custom-select" value={currentValue} onChange={(e) => chooseOption(e.target.value)}>
            <LocaleOptions />
        </select>
    </div>
)
