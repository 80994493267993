import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { TextHistoryEntry } from '../types'

export class PassageSegmentTranscription extends DBObject {
    @observable text = ''

    @observable textHistory: TextHistoryEntry[] = []

    private static model = 19

    toDocument(useExistingModDate?: boolean, useExistingModBy?: boolean) {
        const { text } = this
        return this._toDocument(
            { text, model: PassageSegmentTranscription.model },
            useExistingModDate,
            useExistingModBy
        )
    }

    copy() {
        let copy = new PassageSegmentTranscription(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setText(text: string, modBy?: string, modDate?: string) {
        // Users probably want to preserve formatting in their transcription
        if (this.text === text) {
            return
        }

        const doc = this._toDocument({ text, model: PassageSegmentTranscription.model })
        if (modBy) {
            doc.modBy = modBy
        }
        if (modDate) {
            doc.modDate = modDate
        }
        await this.db.put(doc)
    }
}
