import { FC, useCallback, useEffect, useState } from 'react'

import { TFunction } from 'i18next'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { PassageDocumentModal, PassageDocumentInputParams } from './PassageDocumentModal'
import { PassageDocumentSelector } from './PassageDocumentSelector'
import { AppRoot } from '../../models3/AppRoot'
import { Passage } from '../../models3/Passage'
import { PassageDocument, getText } from '../../models3/PassageDocument'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { useAppRoot } from '../app/RootContext'
import { AudioPlayer } from '../audio/AudioPlayer'
import { PassageResourceFilePicker } from '../filePickers/PassageResourceFilePicker'
import { AddButton, HelpLinkWithMessage, PencilButton, TrashButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { getTimeStamp } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'
import { EditableRichText } from '../utils/RichTextEditor'
import { TextHistoryDropdown } from '../utils/TextHistoryDropdown'
import { BlobDownloading } from '../utils/VideoDownloading'

import './Passage.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:PassageDocuments')

export type VisibleDocument = {
    document: PassageDocument
    group?: string
}

function deleteResource(onConfirm: () => Promise<void>, onCancel: () => void, t: TFunction) {
    confirmAlert({
        title: t('Delete resource'),
        message: t('Are you sure you want to delete this resource?'),
        buttons: [
            {
                label: t('Cancel'),
                onClick: onCancel
            },
            {
                label: t('Delete'),
                onClick: onConfirm
            }
        ]
    })
}

type PDFLinkProps = {
    url: string
    linkText: string
}

const Link = observer(({ url, linkText }: PDFLinkProps) => {
    const [blob, setBlob] = useState<Blob>()
    const [blobSrc, setBlobSrc] = useState('')

    useEffect(() => {
        let src = ''
        if (blob) {
            src = window.URL.createObjectURL(blob)
            setBlobSrc(src)
        }

        return () => {
            if (src.trim() !== '') {
                window.URL.revokeObjectURL(src)
            }
        }
    }, [blob])

    useEffect(() => {
        setBlob(undefined)
        setBlobSrc('')
    }, [url])

    if (url.trim() !== '' && blobSrc.trim() === '') {
        return <BlobDownloading {...{ url, onEnded: setBlob }} />
    }

    return (
        <a href={blobSrc} target="_blank" rel="noreferrer">
            {linkText}
        </a>
    )
})

type PassageDocumentEditorProps = {
    document: PassageDocument
    rt: Root
    editing: boolean
    setEditing: (editing: boolean) => void
    disabled: boolean
    uploadingAudio: boolean
    uploadingPDF: boolean
}

const PassageDocumentEditor: FC<PassageDocumentEditorProps> = observer(
    ({ document, rt, setEditing, editing, disabled, uploadingAudio, uploadingPDF }) => {
        const { t } = useTranslation()
        const { project } = rt

        const [savedText, setSavedText] = useState('...')
        const [isLoading, setIsLoading] = useState(true)
        const [isOlderVersion, setIsOlderVersion] = useState<boolean>(false)
        const [versionTimeStamp, setVersionTimeStamp] = useState<string>('')
        const textHistory = document.textHistory.slice().reverse()
        const mostRecentVersion =
            textHistory.length > 0 ? textHistory[0] : { modBy: document.modBy, modDate: document.modDate }

        log('PassageDocumentEditor render', fmt({ documentTitle: document.title }))

        useEffect(() => {
            async function loadText() {
                // We don't want to load an updated text when editing because the user
                // would lose their edits.
                if (editing || isOlderVersion) return

                log('PassageDocumentEditor loadText')
                setSavedText(await getText(document.text, VideoCache))
                setVersionTimeStamp(getTimeStamp(mostRecentVersion.modBy, mostRecentVersion.modDate, rt.dateFormatter))
                setIsLoading(false)
            }

            loadText()
        }, [
            document.text,
            editing,
            mostRecentVersion.modBy,
            mostRecentVersion.modDate,
            rt.dateFormatter,
            isOlderVersion
        ])

        const { iAmInterpreter } = rt

        const saveText = useCallback((text: string) => document.setText(text, project.name), [document, project.name])

        const handleViewOlderVersion = async (
            index: number,
            selectedText: string,
            selectedName: string,
            selectedDate: string
        ) => {
            setVersionTimeStamp(getTimeStamp(selectedName, selectedDate, rt.dateFormatter))
            setIsOlderVersion(index !== 0)
            setIsLoading(true)
            setSavedText('...')
            const downloadedText = await getText(selectedText, VideoCache)
            setSavedText(downloadedText)
            setIsLoading(false)
        }

        return (
            <>
                <TextHistoryDropdown
                    className="passage-resource-version-history"
                    versionTimeStamp={versionTimeStamp}
                    textHistory={textHistory}
                    dateFormatter={rt.dateFormatter}
                    disabled={!iAmInterpreter || editing}
                    handleViewOlderVersion={handleViewOlderVersion}
                />
                {uploadingAudio && <LoadingIcon className="" />}
                {!uploadingAudio && document.audioUrl.trim() !== '' && (
                    <div className="passage-document-audio">
                        <AudioPlayer audioSrc={document.audioUrl} />
                    </div>
                )}
                {uploadingPDF && <LoadingIcon className="" />}
                {!uploadingPDF && document.pdfUrl.trim() !== '' && (
                    <Link url={document.pdfUrl} linkText={t('viewPDF')} />
                )}
                {isLoading && <LoadingIcon className="" />}
                {!isLoading && (
                    <div className="passage-document-text">
                        <EditableRichText
                            sizeLimit={0}
                            savedText={savedText}
                            save={saveText}
                            cancel={() => {
                                setEditing(false)
                            }}
                            editorOpen={editing}
                            setEditorOpen={setEditing}
                            prefix="passage-document"
                        />
                        {iAmInterpreter && !disabled && (
                            <div className="passage-document-edit-button">
                                <PencilButton
                                    enabled={!editing && !isOlderVersion}
                                    onClick={() => setEditing(true)}
                                    className="small-passage-document-button passage-document-edit-document-button opaque-on-hover"
                                    tooltip={t('Edit')}
                                />
                            </div>
                        )}
                    </div>
                )}
            </>
        )
    }
)

interface ResourceToolbarProps {
    rt: Root
    passage: Passage
    document: PassageDocument
    disabled: boolean
    setUploadingAudio: (value: boolean) => void
    setUploadingPDF: (value: boolean) => void
    deleteDocument: (document: PassageDocument) => void
    setDocumentId: (value: string) => void
}

const ResourceToolbar = observer(
    ({
        rt,
        passage,
        document,
        disabled,
        setUploadingAudio,
        setUploadingPDF,
        deleteDocument,
        setDocumentId
    }: ResourceToolbarProps) => {
        const { t } = useTranslation()
        const { project } = rt
        const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false)

        const updateDocument = async ({ title, isGlobal }: PassageDocumentInputParams) => {
            if (isGlobal === document.isGlobal) {
                await document.setTitle(title)
                return
            }

            const doc = isGlobal
                ? project.createDocumentFromExisting(document)
                : passage.createDocumentFromExisting(document)
            doc.title = title
            if (isGlobal) {
                await project.addDocument(doc)
                await passage.removeDocument(document._id)
            } else {
                await passage.addDocument(doc)
                await project.removeDocument(document._id)
            }
            setDocumentId(doc._id)
        }

        const uploadAudioFile = useCallback(
            async (file: File) => {
                try {
                    setUploadingAudio(true)
                    await document.uploadAudioFile(file, project.name)
                    setUploadingAudio(false)
                } catch (error) {
                    systemError(error)
                }
            },
            [document, project.name, setUploadingAudio]
        )

        const uploadPDFFile = useCallback(
            async (file: File) => {
                setUploadingPDF(true)
                await document.uploadPDFFile(file, project.name)
                setUploadingPDF(false)
            },
            [document, project.name, setUploadingPDF]
        )

        return (
            <div className="passage-document-toolbar">
                {isDocumentModalOpen && (
                    <PassageDocumentModal
                        rt={rt}
                        passage={passage}
                        document={document}
                        save={updateDocument}
                        setOpen={setIsDocumentModalOpen}
                    />
                )}
                <div className="passage-document-toolbar-button">
                    <PencilButton
                        enabled={!disabled}
                        onClick={() => setIsDocumentModalOpen(true)}
                        className="small-passage-document-button passage-document-edit-title-button opaque-on-hover"
                        tooltip={t('editTitle')}
                    />
                </div>
                <div className="passage-document-toolbar-button">
                    <PassageResourceFilePicker
                        enabled={!disabled}
                        className="small-passage-document-button passage-document-import-button opaque-on-hover"
                        resourceType="audio"
                        uploadFile={uploadAudioFile}
                    />
                </div>
                <div className="passage-document-toolbar-button">
                    <PassageResourceFilePicker
                        enabled={!disabled}
                        className="small-passage-document-button passage-document-import-button opaque-on-hover"
                        resourceType="pdf"
                        uploadFile={uploadPDFFile}
                    />
                </div>
                <div className="passage-document-toolbar-push-right passage-document-toolbar-button">
                    <TrashButton
                        onClick={() => deleteDocument(document)}
                        enabled={!disabled}
                        buttonClassName="passage-document-trash-button"
                        className="small-passage-document-button opaque-on-hover"
                        tooltip={t('Delete')}
                    />
                </div>
            </div>
        )
    }
)

interface NoResourcesProps {
    iAmInterpreter: boolean
}
const NoResources: FC<NoResourcesProps> = ({ iAmInterpreter }) => {
    const { t } = useTranslation()

    return (
        <HelpLinkWithMessage
            message={!iAmInterpreter ? t('noResourcesMessageAVTT') : t('noResourcesCreated')}
            id="passages-resources"
        />
    )
}

type PassageDocumentsProps = {
    rt: Root
    passage: Passage
    displayEditButtons: boolean
    persistenceTag: string
}

const getGroupDocuments = ({
    passage,
    appRoot,
    group
}: {
    passage: Passage
    appRoot: AppRoot
    group: string
}): VisibleDocument[] => {
    const groupRoot = appRoot.rtsMap.get(group)
    if (!groupRoot) return []

    const groupDocuments = [
        ...groupRoot.project.getPassageDocumentsByRef(passage.references),
        ...groupRoot.project.documents
    ].map((document) => ({ group, document }))

    return groupDocuments
}

const compareTitles = (a: VisibleDocument, b: VisibleDocument) =>
    a.document.title.toLowerCase().localeCompare(b.document.title.toLowerCase())

export const getAllDocumentsForPassage = ({
    passage,
    rt,
    appRoot
}: {
    passage: Passage
    rt: Root
    appRoot: AppRoot
}): VisibleDocument[] => {
    const { group, project } = rt

    const sortedProjectVisibles: VisibleDocument[] = [...passage.documents, ...project.documents]
        .map((document) => ({ group: undefined, document }))
        .sort(compareTitles)
    const sortedGroupVisibles: VisibleDocument[] = group
        ? getGroupDocuments({ passage, appRoot, group }).sort(compareTitles)
        : []

    return [...sortedProjectVisibles, ...sortedGroupVisibles]
}

const PassageDocuments: FC<PassageDocumentsProps> = observer(({ rt, passage, displayEditButtons, persistenceTag }) => {
    const { t } = useTranslation()
    const appRoot = useAppRoot()
    const { iAmInterpreter, project } = rt

    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [uploadingAudio, setUploadingAudio] = useState(false)
    const [uploadingPDF, setUploadingPDF] = useState(false)

    const visibleDocuments = getAllDocumentsForPassage({ passage, rt, appRoot })
    const hasVisibleDocuments = visibleDocuments.length > 0
    const defaultDocument =
        visibleDocuments.find(({ document }) => rt.getDefault(persistenceTag) === document._id) ||
        (hasVisibleDocuments && visibleDocuments[0])
    const [selectedDocumentId, setSelectedDocumentId] = useState(defaultDocument ? defaultDocument.document._id : '')

    const selectDocument = (documentId: string) => {
        rt.setDefault(persistenceTag, documentId)
        setSelectedDocumentId(documentId)
    }

    const addDocument = async ({ title, isGlobal }: PassageDocumentInputParams) => {
        const document = isGlobal ? project.createDocument(title) : passage.createDocument(title)
        const { _id: documentId } = isGlobal ? await project.addDocument(document) : await passage.addDocument(document)
        selectDocument(documentId)
    }

    const deleteDocument = (document: PassageDocument) => {
        const { _id: documentId, isGlobal } = document
        const onConfirm = async () => {
            if (isGlobal) {
                await project.removeDocument(documentId)
            } else {
                await passage.removeDocument(documentId)
            }
            selectDocument('')
        }

        deleteResource(onConfirm, () => {}, t)
    }

    return (
        <>
            {!hasVisibleDocuments && <NoResources iAmInterpreter={iAmInterpreter} />}
            <div className="passage-document-viewer-top">
                {isDocumentModalOpen && (
                    <PassageDocumentModal
                        rt={rt}
                        passage={passage}
                        save={addDocument}
                        setOpen={setIsDocumentModalOpen}
                    />
                )}
                {iAmInterpreter && displayEditButtons && (
                    <AddButton
                        className="passage-document-add-button small-passage-document-button"
                        buttonClassName=""
                        onClick={() => setIsDocumentModalOpen(true)}
                        tooltip={t('add')}
                        enabled={!editing}
                    />
                )}
                {hasVisibleDocuments && (
                    <PassageDocumentSelector
                        visibleDocuments={visibleDocuments}
                        documentId={selectedDocumentId}
                        setDocumentId={selectDocument}
                        idSuffix={persistenceTag}
                        disabled={editing}
                    />
                )}
                {defaultDocument && iAmInterpreter && displayEditButtons && (
                    <ResourceToolbar
                        rt={rt}
                        passage={passage}
                        document={defaultDocument.document}
                        disabled={editing || !!defaultDocument.group || !displayEditButtons}
                        setUploadingAudio={setUploadingAudio}
                        setUploadingPDF={setUploadingPDF}
                        deleteDocument={deleteDocument}
                        setDocumentId={selectDocument}
                    />
                )}
            </div>
            {defaultDocument && <div className="passage-document-separator" />}
            {defaultDocument && (
                <PassageDocumentEditor
                    document={defaultDocument.document}
                    key={defaultDocument.document._id}
                    rt={rt}
                    editing={editing}
                    setEditing={setEditing}
                    disabled={!!defaultDocument.group || !displayEditButtons}
                    uploadingAudio={uploadingAudio}
                    uploadingPDF={uploadingPDF}
                />
            )}
        </>
    )
})

export default PassageDocuments
