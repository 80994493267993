import { useTheme, Heading } from '@aws-amplify/ui-react'
import { useTranslation } from 'react-i18next'

const Header = () => {
    const { tokens } = useTheme()
    const { t } = useTranslation()

    return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4} translate="no">
            {t('signInToYourProject')}
        </Heading>
    )
}

export const SignIn = { Header }
