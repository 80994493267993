import { RefRange, refToVerseId } from './RefRange'
import { PUBLIC_RESOURCE_BASE_PATH } from '../components/app/slttAvtt'
import { fetchAndWaitForBody, inRangeInclusive } from '../components/utils/Helpers'
import { convertAudioBufferToWav, createSingleBuffer, getAudioBufferFromObject } from '../components/utils/Wav'
import API from '../models3/API'
import { getVideoDuration } from '../models3/VideoDuration'
import { PublishedBible } from '../types'

const PUBLISHED_AUDIO_KEY_PREFIX = 'APIBIBLE/audio'
const PUBLISHED_AUDIO_TIMECODES_BASE_PATH = `${PUBLIC_RESOURCE_BASE_PATH}/${PUBLISHED_AUDIO_KEY_PREFIX}`

export type PlaybackRange = {
    start?: number
    end?: number
}

export const getAudioResponseWithBody = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const resourceKey = `${PUBLISHED_AUDIO_KEY_PREFIX}/${resource}/${bbbccc}.opus`
    const { response, body } = await API.getEnhancedResourceResponseWithBody(resourceKey)

    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }

    return { response, body }
}

const getAudio = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const { body } = await getAudioResponseWithBody(resource, bbbccc)
    return body as Blob
}

export interface ChapterTimeCodes {
    codes: InternalVerseRangeTimeCode[]
}

interface VerseRangeTimeCode {
    verseId: string // e.g. 1 or 1-2
    start: string // e.g. 4.160
    end: string // e.g. 4.160
}

interface InternalVerseRangeTimeCode extends VerseRangeTimeCode {
    chapter: string
}

export const getTimeCodesResponseWithBody = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const url = `${PUBLISHED_AUDIO_TIMECODES_BASE_PATH}/${resource}/${bbbccc}.json`
    const { response, body } = await fetchAndWaitForBody(url)

    if (!response.ok) {
        if (response.status === 403) {
            // some chapters don't have timecodes
            return { response, body: [] }
        }

        throw Error(`${response.url}: ${response.statusText}`)
    }

    return { response, body }
}

const getTimeCodes = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const { body } = await getTimeCodesResponseWithBody(resource, bbbccc)
    return body as VerseRangeTimeCode[]
}

export const getAllVersesInRange = (range: string) => {
    // verse range may contain letters, e.g. "1b-4b", and not just numbers like "3-7"
    const boundaries = range.split('-').map((verse) => Number(verse.replace(/\D/g, '')))
    if (!boundaries.length) {
        return []
    }
    const difference = boundaries[boundaries.length - 1] - boundaries[0]
    return Array.from(Array(difference + 1), (x, i) => String(i + boundaries[0]))
}

const convertBbbcccvvvToPublishedAudioVerse = (bbbcccvvv: string) => {
    return Number(refToVerseId(bbbcccvvv)).toString()
}

const mergeTimeCodes = (timeCodes: ChapterTimeCodes[], durations: number[]) => {
    let currentOffset = 0
    return timeCodes.reduce<ChapterTimeCodes>(
        (acc, chapterCodes, index) => {
            const { codes } = chapterCodes
            if (codes.length === 0) {
                return acc
            }

            const newCodes = codes.map((code) => ({
                ...code,
                start: (Number(code.start) + currentOffset).toString(),
                end: (Number(code.end) + currentOffset).toString()
            }))

            currentOffset += durations[index]
            return {
                ...acc,
                codes: [...acc.codes, ...newCodes]
            }
        },
        { codes: [] }
    )
}

const mergeTimeRanges = (timeRanges: Required<PlaybackRange>[]) => {
    if (timeRanges.length === 0) {
        return { start: 0, end: 0 }
    }

    const start = timeRanges[0].start
    const duration = timeRanges.reduce((acc, range) => acc + range.end - range.start, 0)
    const end = start + duration
    return { start, end }
}

const mergeAudioBlobs = async (blobs: Blob[]) => {
    const buffers = await Promise.all(blobs.map((blob) => getAudioBufferFromObject(blob)))
    const singleBuffer = createSingleBuffer(buffers)
    return convertAudioBufferToWav(singleBuffer)
}

export const getBibleAudio = async (bible: PublishedBible, reference: RefRange) => {
    const chaptersToFetch = Array.from(reference.chapterIterator())
        .map((chapter) => {
            const verses = reference
                .getAllVerses(bible.versification)
                .filter((refString) => refString.startsWith(chapter))
                .map((verse) => convertBbbcccvvvToPublishedAudioVerse(verse))

            return { verses, chapter }
        })
        .filter((chapter) => chapter.verses.length > 0)

    const allBlobs = await Promise.all(chaptersToFetch.map(({ chapter }) => getAudio(bible.id, chapter)))

    const durations = await Promise.all(allBlobs.map((blob) => getVideoDuration(blob)))

    const rawTimeCodes = await Promise.all(
        chaptersToFetch.map(async ({ verses, chapter }) => {
            const chapterTimecodes = await getTimeCodes(bible.id, chapter)
            if (chapterTimecodes.length === 0) {
                return { chapter, timeCodes: [] }
            }

            const firstVerseNumber = Number(verses[0])
            const lastVerseNumber = Number(verses[verses.length - 1])

            const timeCodes = chapterTimecodes.filter(({ verseId }) => {
                const versesInRange = getAllVersesInRange(verseId)
                return versesInRange.some((rangeVerse) =>
                    inRangeInclusive(Number(rangeVerse), firstVerseNumber, lastVerseNumber)
                )
            })

            return { chapter, timeCodes }
        })
    )

    const allTimeRanges = rawTimeCodes.map(({ timeCodes }, index) => {
        const isFirstIndex = index === 0
        const isLastIndex = index === rawTimeCodes.length - 1
        const hasTimeCodes = timeCodes.length > 0
        const start = isFirstIndex && hasTimeCodes ? Number(timeCodes[0].start) : 0
        const end = hasTimeCodes && isLastIndex ? Number(timeCodes[timeCodes.length - 1].end) : durations[index]
        return { start, end }
    })
    const allTimeCodes = rawTimeCodes.map(({ chapter, timeCodes }) => {
        return { codes: timeCodes.map((code) => ({ ...code, chapter })) }
    })

    if (allTimeCodes.length === 0 || allBlobs.length === 0 || allTimeRanges.length === 0) {
        return
    }

    const timeCodes = mergeTimeCodes(allTimeCodes, durations)
    const timeRange = mergeTimeRanges(allTimeRanges)
    const wavBlob = await mergeAudioBlobs(allBlobs)

    return {
        timeRange,
        blob: wavBlob,
        timeCodes
    }
}
