import { FC } from 'react'

import { observer } from 'mobx-react'
import Select from 'react-select'

import { useAppRoot } from './RootContext'
import { Root } from '../../models3/Root'
import { groupBy } from '../utils/Helpers'

interface ProjectSelectorProps {
    currentRoot: Root
    selectProject: (projectName: string) => void
}

const mapToOption = ({ name, displayName }: { name: string; displayName: string }) => ({
    value: name,
    label: displayName
})

export const ProjectSelector: FC<ProjectSelectorProps> = observer(({ currentRoot, selectProject }) => {
    const appRoot = useAppRoot()

    const projects = appRoot.rts.map(({ name, displayName, group, groupProjects }) => {
        const isGroup = Boolean(groupProjects)
        return {
            isGroup,
            name,
            displayName,
            group: isGroup ? name : group ?? ''
        }
    })

    const projectsByGroup = groupBy(projects, ({ group }) => group)
    const groupedOptions = Object.keys(projectsByGroup)
        .sort((a, b) => a.localeCompare(b))
        .map((group) => ({
            label: group,
            options: projectsByGroup[group]
                .sort((a, b) => {
                    if (a.isGroup !== b.isGroup) {
                        return a.isGroup ? -1 : 1
                    }
                    return a.displayName.localeCompare(b.displayName)
                })
                .map(mapToOption)
        }))

    const targetOptionValue = groupedOptions
        .flatMap((group) => group.options)
        .find((option) => option.value === mapToOption(currentRoot).value)

    return (
        <div className="project-selector-dropdown">
            <Select
                className="project-selector-custom-select"
                value={targetOptionValue}
                onChange={(selectedOption) => {
                    selectProject(selectedOption?.value || '')
                }}
                options={groupedOptions}
                isSearchable
                menuShouldBlockScroll
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        paddingLeft: '27px',
                        backgroundColor: state.isSelected ? '#337ab7' : state.isFocused ? '#e8e8e8' : ''
                    }),
                    groupHeading: (provided) => ({
                        ...provided,
                        textTransform: 'none',
                        fontSize: '15px'
                    })
                }}
            />
        </div>
    )
})
