import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { isWithinTolerance } from '../components/utils/Helpers'

export type PassageVideoMarkerType = 'reference' | 'biblicalTerm'
export class PassageVideoMarker extends DBObject {
    @observable position = 0 // position after the start of the containing video

    time = 0 // time for this reference in main video timeline

    segmentIndex = 0 // which segment is this in on the base (non-patched) segment?

    static minDistance = 0.2 // minimum time between each marker of same type

    constructor(_id: string, db: IDB, creationDate?: Date) {
        super(_id, db)
        let creationDateString = this.creationDate
        if (creationDate) {
            creationDateString = db.getDate(creationDate)
        }
        this.creationDate = creationDateString
    }

    // Adjust the position of each marker so that it appears before maxPosition. Try to
    // do this while still maintaining a minimum distance between markers. If we can't
    // do this, then place the marker at maxPosition.
    static adjustMarkerPositions(markers: PassageVideoMarker[], maxPosition: number) {
        const placedMarkers: PassageVideoMarker[] = []
        const unplacedMarkers: PassageVideoMarker[] = []
        markers.forEach((marker) =>
            marker.position > maxPosition ? unplacedMarkers.push(marker) : placedMarkers.push(marker)
        )

        let guess = maxPosition
        const guessStep = 0.05
        unplacedMarkers
            .sort((a, b) => a.position - b.position)
            .forEach((marker) => {
                guess -= PassageVideoMarker.minDistance
                while (
                    guess > 0 &&
                    placedMarkers.some((placedMarker) =>
                        isWithinTolerance(guess, placedMarker.position, PassageVideoMarker.minDistance)
                    )
                ) {
                    guess -= guessStep
                }
                marker.position = guess > 0 ? guess : maxPosition // put marker at the end if it cannot be placed
                placedMarkers.push(marker)
            })

        return placedMarkers
    }

    static tooClose(markers: PassageVideoMarker[], time: number) {
        return markers.find((ref) => isWithinTolerance(time, ref.time, PassageVideoMarker.minDistance))
    }

    canChangePositionToTime({
        time,
        computedDuration,
        markers
    }: {
        time: number
        computedDuration: number
        markers: PassageVideoMarker[]
    }) {
        const visibleMarkers = markers.filter((ktm) => ktm._id !== this._id)
        return !PassageVideoMarker.tooClose(visibleMarkers, time) && time >= 0 && time <= computedDuration
    }
}
