import { observer } from 'mobx-react'

import { AudioPlayer } from './AudioPlayer'
import { AudioPlayerPlaceholder } from './AudioPlayerPlaceholder'

export const AudioPlayerWithPlaceholder = observer(({ recordedBlob, url }: { recordedBlob?: Blob; url: string }) => {
    if (recordedBlob) {
        return <AudioPlayer audioSrc={recordedBlob} />
    }

    if (url) {
        return <AudioPlayer audioSrc={url} />
    }

    return <AudioPlayerPlaceholder />
})
