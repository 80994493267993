import { Project } from '../../models3/Project'

export type SegmentEditorPanel = 'recordingPanel' | 'backTranslationPanel' | 'transcriptionPanel'

export const SegmentEditorPanelLayout: SegmentEditorPanel[] = ['backTranslationPanel', 'transcriptionPanel']

export const panelOrder = (project: Project, panel: SegmentEditorPanel) => {
    if (!project.overrideRecordingLayout) return ''

    const order = project.segmentEditorPanelLayout.indexOf(panel) + 1
    return order ? `order-${order}` : ''
}

export const panelVisibility = (project: Project, panel: SegmentEditorPanel) =>
    project.overrideRecordingLayout ? !project.segmentEditorHiddenPanels.includes(panel) : true
