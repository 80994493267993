/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useTranslation } from 'react-i18next'

import { Root } from '../../../models3/Root'
import { StudyGuideTabIcon } from '../../translation/StudyGuideTabIcon'
import { StudyTabKey, studyTabsVisibility } from '../../translation/StudyTabsOrder'
import { DragAndDropIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

export const StudyTabLayoutSortableItem = ({ rt, id }: { rt: Root; id: StudyTabKey }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    const { t } = useTranslation()
    const { project } = rt
    const [visible, setVisible] = useState(studyTabsVisibility(project, id))

    return (
        <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
            <div className="sortable-container-item horizontal-sortable-container-item">
                <div {...attributes} {...listeners} data-toggle="tooltip">
                    <DragAndDropIcon className="fa-lg" />
                </div>
                <span className={visible ? '' : 'hidden-item'}>
                    <StudyGuideTabIcon tabName={id} />
                </span>
                <Switch
                    value={visible}
                    setValue={async (value) => {
                        setVisible(value)
                        await project.setStudyTabHiddenTabs(id, value)
                    }}
                    tooltip={t('showOrHide')}
                    className="sortable-container-item-switch"
                />
            </div>
        </div>
    )
}
