import { getAudioResponseWithBody, getTimeCodesResponseWithBody } from './AudioResource'
import { EnhancedResources } from './EnhancedResources'
import {
    ExegeticalResourcePathRequest,
    fetchExegeticalResourceBookIndexesResponseWithBody,
    fetchGlossaryIndexResponseWithBody,
    fetchPericopeMediaIndexResponseWithBody,
    getExegeticalResourcePaths
} from './ExegeticalResources'
import { ImageMetadata } from './ImageMetadata'
import { ImageResolution, MARBLEImages } from './MARBLEImages'
import { RefRange } from './RefRange'
import {
    chunkArray,
    deduplicateArrayByField,
    fetchAndWaitForBody,
    processChunksAsync
} from '../components/utils/Helpers'
import { MediaType, PublishedBible, ResponseWithBody } from '../types'

const SAFE_FETCH_CHUNK_SIZE = 20

const getChunkedChapters = (bookNumber: number, versification: string) => {
    const allChapters = RefRange.getAllChaptersInBook(bookNumber, versification)
    return chunkArray(allChapters, SAFE_FETCH_CHUNK_SIZE)
}

const filterOKResponses = (responses: (Response | undefined)[]) =>
    responses.filter((response) => response?.ok) as Response[]

const filterOKResponsesWithBody = (responsesWithBody: ResponseWithBody[]) => {
    const responses = responsesWithBody.map(({ response }) => response)
    return filterOKResponses(responses)
}

export const cacheImagesInBook = async ({ bookNumber }: { bookNumber: number }) => {
    const imagesVersification = 'English'
    const chunks = getChunkedChapters(bookNumber, imagesVersification)

    // Find out which images we need to download
    const allImages = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            const images = (await Promise.all(chapters.map((chapter) => MARBLEImages.fetchInfo(chapter)))).flat()
            return deduplicateArrayByField(images, 'id')
        })
    ).flat()

    // Then fetch all the images
    const allUniqueImages = deduplicateArrayByField(allImages, 'id')
    const imageResolutions = Object.values(ImageResolution)
    const imageResponsesWithBody = (
        await processChunksAsync(
            chunkArray(allUniqueImages, Math.floor(SAFE_FETCH_CHUNK_SIZE / imageResolutions.length)),
            async (images: ImageMetadata[]) => {
                return Promise.all(
                    images.flatMap((image) =>
                        imageResolutions.map((resolution) => fetchAndWaitForBody(image.imagePath(resolution)))
                    )
                )
            }
        )
    ).flat()

    // Fetch image metadata again, because we need to return the responses
    const imageMetadataResponsesWithBody = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            return (
                await Promise.all(chapters.map((chapter) => MARBLEImages.getMARBLEImagesResponseWithBody(chapter)))
            ).flat()
        })
    ).flat()

    return filterOKResponsesWithBody([...imageResponsesWithBody, ...imageMetadataResponsesWithBody])
}

const cacheSingleExegeticalResourceInBook = async ({
    language,
    bookIndexes,
    pericopeMedia,
    glossary,
    bookNumber,
    mediaType
}: ExegeticalResourcePathRequest & { bookNumber: number }) => {
    const allItems = getExegeticalResourcePaths({
        language,
        bookIndexes,
        pericopeMedia,
        glossary,
        bookNumber,
        mediaType
    })

    const responsesWithBody = (
        await processChunksAsync(chunkArray(allItems, SAFE_FETCH_CHUNK_SIZE), async (items: string[]) => {
            return Promise.all(items.map((item) => fetchAndWaitForBody(item)))
        })
    ).flat()

    return filterOKResponsesWithBody(responsesWithBody)
}

export const cacheExegeticalResourceInBook = async ({
    language,
    bookNumber,
    mediaType
}: {
    language: string
    bookNumber: number
    mediaType: MediaType
}) => {
    // The exegetical resource book index response may not exist. This is fine, because when the
    // client tries to fetch the index, we supply a fallback value.
    const [
        {
            response: indexResponse,
            body: { bookIndexes }
        },
        {
            response: pericopeMediaIndexResponse,
            body: { pericopeMedia }
        },
        {
            response: glossaryIndexResponse,
            body: { glossary }
        }
    ] = await Promise.all([
        fetchExegeticalResourceBookIndexesResponseWithBody(language),
        fetchPericopeMediaIndexResponseWithBody(),
        fetchGlossaryIndexResponseWithBody()
    ])

    const moreResponses = await cacheSingleExegeticalResourceInBook({
        language,
        bookNumber,
        bookIndexes,
        pericopeMedia,
        glossary,
        mediaType
    })

    // Non-text media must be downloaded with text in order for it to be displayed
    if (mediaType !== MediaType.TEXT) {
        const textResponses = await cacheSingleExegeticalResourceInBook({
            language,
            bookNumber,
            bookIndexes,
            pericopeMedia,
            glossary,
            mediaType: MediaType.TEXT
        })
        return filterOKResponses([
            indexResponse,
            pericopeMediaIndexResponse,
            glossaryIndexResponse,
            ...moreResponses,
            ...textResponses
        ])
    }

    return filterOKResponses([indexResponse, pericopeMediaIndexResponse, glossaryIndexResponse, ...moreResponses])
}

export const cachePublishedBibleBook = async ({
    bookNumber,
    bibleVersion,
    mediaType
}: {
    bookNumber: number
    bibleVersion: PublishedBible
    mediaType: MediaType
}) => {
    const chunks = getChunkedChapters(bookNumber, bibleVersion.versification)
    const fetch = (chapter: string) =>
        mediaType === MediaType.TEXT
            ? Promise.all([EnhancedResources.fetchResponseWithBody(bibleVersion.id, chapter)])
            : Promise.all([
                  getAudioResponseWithBody(bibleVersion.id, chapter),
                  getTimeCodesResponseWithBody(bibleVersion.id, chapter)
              ])

    const responsesWithBody = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            return (await Promise.all(chapters.map((chapter) => fetch(chapter)))).flat()
        })
    ).flat()

    return filterOKResponsesWithBody(responsesWithBody)
}
