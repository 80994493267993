import { observable, computed } from 'mobx'
import _ from 'underscore'

import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { Passage } from './Passage'
import { PassageNoteItem } from './PassageNoteItem'
import { normalizeUsername, remove, smallNoteMarker } from './Utils'
import { isWithinTolerance } from '../components/utils/Helpers'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

const defaultBuffer = 10

export class PassageNote extends DBObject {
    @observable type = '0'

    @observable description = ''

    @observable canResolve = true

    @observable items: PassageNoteItem[] = []

    @observable position = 0

    @observable startPosition = 0

    @observable endPosition = 0

    @observable _rev = 0

    // The following variables are not persisted in the online store.
    // They are set by videoPassage.setupNotes

    time = 0 // time for this note in main video timeline

    onTop = true // true if this note is not covered up by a later patch

    inIgnoredSegment = false

    y = 0 // vertical pixel offset for this note in main video timeline

    x = 0 // horizontal pixel offset

    width = 15 // marker width in timeline in pixels

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.rank = DBObject.numberToRank(this.position)
    }

    toDocument() {
        const { type, description, canResolve, position, startPosition, endPosition, rank } = this
        return this._toDocument({ type, description, canResolve, position, startPosition, endPosition, rank })
    }

    toSnapshot() {
        const snapshot = this.toDocument()
        snapshot.items = this.items.map((item) => item.toSnapshot())

        return snapshot
    }

    dbg(verbose?: boolean) {
        const doc = this.toDocument()
        doc.time = this.time.toFixed(2)
        doc.onTop = this.onTop
        doc.y = this.y
        doc.x = this.x
        doc.width = this.width
        if (verbose) {
            doc.items = this.items.map((item) => item.dbg())
        }

        return doc
    }

    copy() {
        let copy = new PassageNote(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.items = this.items.map((item) => item.copy())
        return copy
    }

    async setCanResolve(newValue: boolean) {
        if (this.canResolve === newValue) {
            return
        }
        const doc = this._toDocument({})
        doc.canResolve = newValue
        await this.db.put(doc)
    }

    async resolve(passage: Passage) {
        if (this.resolved) {
            return
        }
        const item = this.createItem()
        item.resolved = true
        await this.addItem(item, passage)
    }

    async unresolve(passage: Passage) {
        if (!this.resolved) {
            return
        }
        const item = this.createItem()
        item.unresolved = true
        await this.addItem(item, passage)
    }

    async setType(type: string) {
        const doc = this._toDocument({})
        if (this.type === type) {
            return
        }
        doc.type = type
        await this.db.put(doc)
    }

    async setDescription(description: string) {
        const doc = this._toDocument({})
        if (this.description === description) {
            return
        }
        doc.description = description
        await this.db.put(doc)
    }

    setDefaultStartPosition() {
        this.startPosition = Math.max(this.position - defaultBuffer, 0)
    }

    setDefaultEndPosition(duration: number) {
        if (!duration) {
            // log('WARNING setDefaultEndPosition but no duration available')
            duration = this.position + defaultBuffer
        }

        this.endPosition = Math.min(this.position + defaultBuffer, duration)
    }

    async setPositions(notePosition: number, start: number, end: number) {
        const { startPosition, position, endPosition } = this

        const doc = this._toDocument({})

        if (notePosition !== position) {
            doc.position = notePosition
        }

        if (start !== startPosition) {
            // Force start position to be before position.
            doc.startPosition = Math.min(start, notePosition - 0.1)
        }

        if (end !== endPosition) {
            // Force endPosition to be at least a little bit after position
            doc.endPosition = Math.max(end, notePosition + 0.1)
        }

        doc.rank = DBObject.numberToRank(notePosition)

        if (
            isWithinTolerance(doc.position, position) &&
            isWithinTolerance(doc.startPosition, startPosition) &&
            isWithinTolerance(doc.endPosition, endPosition)
        ) {
            return
        }

        // If position, startPosition, or endPosition have changed, write db entry
        if (doc.position !== undefined || doc.startPosition !== undefined || doc.endPosition !== undefined) {
            this.db.submitChange(doc)
        }
    }

    @computed get resolved() {
        let rsv = false
        for (const item of this.items) {
            if (item.resolved) rsv = true
            else if (item.unresolved) rsv = false
        }

        return rsv
    }

    unviewedItemsAfterDate(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        if (this.resolved) {
            return []
        }
        const lastUnresolvedIndex = _.findLastIndex(this.items, (item) => item.unresolved)
        const itemsAfterUnresolved = this.items.slice(lastUnresolvedIndex + 1)
        return itemsAfterUnresolved
            .filter((item) => includeConsultantOnlyNotes || !item.consultantOnly)
            .filter((item) => item.isUnviewedAfterDate(username, cutoff))
    }

    createItem() {
        const creationDate = this.db.getNewId(this.items, new Date(Date.now()))
        const itemId = `${this._id}/${creationDate}`
        const item = new PassageNoteItem(itemId, this.db)
        item.position = this.position

        return item
    }

    createItemFromExisting(item: PassageNoteItem) {
        if (item.removed) {
            return
        }

        const newItem = this.createItem()
        const copy = item.copy()
        copy._id = newItem._id
        return copy
    }

    async addItem(item: PassageNoteItem, passage: Passage | null, creator?: string): Promise<PassageNote> {
        log('PassageNote addItem', item._id, this._id, passage?._id)

        const doc = item.toDocument()
        if (creator) {
            doc.creator = normalizeUsername(creator)
        }

        await this.db.put(doc)

        const note = passage?.findNote(item._id)
        if (!note) {
            throw Error(`PassageNote not created ${this._id}, ${passage?._id}`)
        }
        return note
    }

    async removeItem(_id: string) {
        await remove(this.items, _id)
    }

    // Find the PassageVideo containing this note
    toVideo(passage: Passage) {
        for (const v of passage.videos) {
            for (const n of v.notes) {
                if (n._id === this._id) {
                    return v
                }
            }
        }
        return null
    }

    @computed get consultantOnly() {
        const items = this.items.filter((item) => !item.resolved && !item.unresolved)
        if (items.length === 0) return false
        return items.every((item) => item.consultantOnly)
    }

    // CALCULATE NOTE MARKER POSITION
    // Adjust width, y, time to avoid overlaps with other markers

    // True if this note marker overlaps with any previous note marker
    private overlaps(notes: PassageNote[], index: number) {
        for (let i = 0; i < index; ++i) {
            if (notes[index].overlap(notes[i])) return true
        }

        return false
    }

    // True if marker for this note overlaps with note b
    private overlap(b: PassageNote) {
        const { width, x, y } = this
        return isWithinTolerance(x, b.x, width) && isWithinTolerance(y, b.y, width)
    }

    private isSmallMarker() {
        return this.width === smallNoteMarker
    }

    // Shrink this marker all markers within 3 small marker widths
    // private shrinkMarker(notes: PassageNote[], index: number, secondsPerPixel: number) {
    //     let timeDelta = 3 * smallNoteMarker * secondsPerPixel // make everything within this time delta small

    //     for (let i = 0; i < notes.length; ++i) {
    //         if (notes[i].time >= this.time - timeDelta && notes[i].time <= this.time + timeDelta) {
    //             notes[i].width = smallNoteMarker
    //         }
    //     }
    // }

    private lowerMarker() {
        this.y = smallNoteMarker
    }

    private raiseMarker() {
        this.y = -smallNoteMarker
    }

    // Set the width, x, y, and (as a last resort) time for this note
    // so that its marker does not collide with other notes
    // ??? do we need a separate DrawablePassageNote class?
    setupMarker(notes: PassageNote[], index: number, duration: number, componentWidth: number) {
        const secondsPerPixel = duration / componentWidth
        const note = notes[index]
        note.x = note.time / secondsPerPixel - note.width / 2
        if (index === 0) return // first marker cannot overlap because no previous markers

        const xMax = duration / secondsPerPixel

        // Give up, after 200 tries we still have overlap
        for (let i = 0; i < 200; ++i) {
            if (!this.overlaps(notes, index)) break

            if (note.isSmallMarker()) {
                this.raiseMarker()
                if (!this.overlaps(notes, index)) break

                this.lowerMarker()
                if (!this.overlaps(notes, index)) break
            }

            this.y = 0 // reset to base line
            // push marker forward
            this.x = notes[index - 1].x + note.width + 1
            this.x = Math.min(note.x, xMax)

            //! !! cheating and letting all the notes overlap at then end
        }
    }

    visibleItems(includeConsultantOnly: boolean) {
        return this.items.filter((item) => includeConsultantOnly || !item.consultantOnly)
    }

    // END PassageNote
}
