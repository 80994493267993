import { Project } from '../../models3/Project'

export type StudyTabKey =
    | 'publishedBibles'
    | 'studyGuides'
    | 'bibleImages'
    | 'passageResources'
    | 'passageBiblicalTerms'
    | 'passageNotes'

export const StudyTabLayout: StudyTabKey[] = [
    'publishedBibles',
    'studyGuides',
    'bibleImages',
    'passageResources',
    'passageBiblicalTerms',
    'passageNotes'
]

export const studyTabsVisibility = (project: Project, tab: StudyTabKey) =>
    project.overrideRecordingLayout ? !project.studyTabHiddenTabs.includes(tab) : true
