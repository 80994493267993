import { FC } from 'react'

import { BibleBookProgress } from './BibleBookProgress'
import { CachingProgressIndicator } from './CachingProgressIndicator'
import { CacheType, MediaType, PublishedBible } from '../../../types'
import { useExegeticalResources } from '../../app/ExegeticalResourcesContext'
import { BookNameView } from '../references/BookNameRow'

import '../ProjectSettings.css'

interface IBookNameRow {
    projectBookName: string
    bookNumber: number
    visibleBibles: PublishedBible[]
    showMARBLEImage: boolean
    showFIAStudyGuide: boolean
}

export const TableRow: FC<IBookNameRow> = ({
    projectBookName,
    bookNumber,
    visibleBibles,
    showMARBLEImage,
    showFIAStudyGuide
}) => {
    const { exegeticalResourceMediaTypes } = useExegeticalResources()

    return (
        <tr className="project-resource-table-row">
            <td className="project-resource-table-cell">
                <BookNameView bookName={projectBookName} />
            </td>
            {showMARBLEImage && (
                <td className="project-resource-table-cell project-resource-table-cell-content">
                    <CachingProgressIndicator cacheRequest={{ bookNumber, cacheType: CacheType.IMAGES }} />
                </td>
            )}
            {showFIAStudyGuide &&
                exegeticalResourceMediaTypes.map((mediaType) => (
                    <td className="project-resource-table-cell project-resource-table-cell-content" key={mediaType}>
                        <CachingProgressIndicator
                            cacheRequest={{
                                bookNumber,
                                cacheType: CacheType.EXEGETICAL_RESOURCES,
                                mediaType: mediaType as MediaType
                            }}
                        />
                    </td>
                ))}
            {visibleBibles.map((version) => (
                <BibleBookProgress key={version.id} bookNumber={bookNumber} bibleVersion={version} />
            ))}
        </tr>
    )
}
