import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { BlobDownloading } from '../utils/VideoDownloading'

import './AudioPlayer.css'

type AudioPlayerProps = {
    audioSrc: string | Blob
}

export const AudioPlayer = observer(({ audioSrc }: AudioPlayerProps) => {
    const [audioBlob, setAudioBlob] = useState<Blob>()
    const [audioBlobSrc, setAudioBlobSrc] = useState('')

    useEffect(() => {
        let src = ''
        if (audioBlob) {
            src = window.URL.createObjectURL(audioBlob)
            setAudioBlobSrc(src)
        }

        return () => {
            if (src.trim() !== '') {
                window.URL.revokeObjectURL(src)
            }
        }
    }, [audioBlob])

    useEffect(() => {
        setAudioBlob(undefined)
        setAudioBlobSrc('')
        if (typeof audioSrc !== 'string') {
            const blob = new Blob([audioSrc], { type: 'audio/webm' })
            setAudioBlob(blob)
        }
    }, [audioSrc])

    if (typeof audioSrc === 'string' && audioSrc.length && !audioBlobSrc.length) {
        return (
            <div className="audio-player-wrapper">
                <BlobDownloading {...{ url: audioSrc, onEnded: setAudioBlob }} />
            </div>
        )
    }

    return (
        <div className="audio-player-wrapper">
            <audio src={audioBlobSrc} className="audio-player" controls />
        </div>
    )
})
