import { BlobWriter, ZipWriter, BlobReader, TextReader } from '@zip.js/zip.js'

import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { ExportTextFormat, MimeType } from '../../../types'
import { bookTextsToString } from '../../passages/utils'
import { AudioEncodeType, getPassageAudio, getPortionAudio } from '../../utils/DownloadPassage'

export interface ExportItem {
    recording?: {
        path: string
        data?: Blob
        isMissingParts: boolean
    }
    transcription?: {
        path: string
        data: string
    }
    backTranslation?: {
        path: string
        data: string
    }
}

interface InputParams {
    exportTextFormat: ExportTextFormat

    recording: {
        path: string
        audioEncodeType: AudioEncodeType
        secondsOfSilence?: number
    }
    transcription: {
        path: string
        project: Project
    }
    backTranslation: {
        path: string
        project: Project
    }
}

export const getExportItem = async ({
    passage,
    passageVideo,
    portion,
    inputParams
}: {
    passage?: Passage
    passageVideo?: PassageVideo
    portion?: Portion
    inputParams: Partial<InputParams>
}): Promise<ExportItem> => {
    const exportItem: ExportItem = {}
    const { exportTextFormat, recording, transcription, backTranslation } = inputParams

    if (recording) {
        let audio
        if (portion) {
            audio = await getPortionAudio(portion, recording.secondsOfSilence ?? 0, recording.audioEncodeType)
        } else if (passage && passageVideo) {
            audio = await getPassageAudio(passage, passageVideo, recording.audioEncodeType)
        }
        if (audio) {
            const { blob, isMissingParts } = audio
            exportItem.recording = { path: recording.path, data: blob, isMissingParts }
        }
    }

    if (transcription) {
        const { path, project } = transcription
        let data
        if (portion) {
            data = bookTextsToString(portion.getTranscription(project, exportTextFormat))
        } else if (passage && passageVideo) {
            const { text } = passageVideo.getTranscription({
                passage,
                project,
                exportTextFormat,
                includeHeader: true
            })
            data = text
        }

        if (data) {
            exportItem.transcription = { path, data }
        }
    }

    if (backTranslation) {
        const { path, project } = backTranslation
        let data
        if (portion) {
            data = bookTextsToString(portion.getBackTranslation(project, exportTextFormat))
        } else if (passage && passageVideo) {
            const { text } = passageVideo.getBackTranslation({
                passage,
                project,
                exportTextFormat,
                includeHeader: true
            })
            data = text
        }

        if (data) {
            exportItem.backTranslation = { path, data }
        }
    }

    return exportItem
}

export const generateZip = async (files: ExportItem[]) => {
    if (!files.some((item) => item.recording?.data) && !files.some((item) => item.transcription)) {
        return
    }
    const zipWriter = new ZipWriter(new BlobWriter(MimeType.ZIP))

    for (const item of files) {
        if (item.recording?.data) {
            await zipWriter.add(item.recording.path, new BlobReader(item.recording.data))
        }
        if (item.transcription) {
            await zipWriter.add(item.transcription.path, new TextReader(item.transcription.data))
        }
        if (item.backTranslation) {
            await zipWriter.add(item.backTranslation.path, new TextReader(item.backTranslation.data))
        }
    }

    const zipFile = await zipWriter.close()
    return new Blob([zipFile])
}
