import { Dropdown } from 'react-bootstrap'

import { IDateFormatter } from '../../models3/DateUtilities'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'
import { TextHistoryEntry } from '../../types'

export const TextHistoryDropdown = ({
    versionTimeStamp,
    textHistory,
    dateFormatter,
    className,
    disabled,
    handleViewOlderVersion
}: {
    versionTimeStamp: string
    textHistory: TextHistoryEntry[]
    className?: string
    disabled: boolean
    dateFormatter: IDateFormatter
    handleViewOlderVersion: (index: number, selectedText: string, selectedName: string, selectedDate: string) => void
}) => {
    if (textHistory.length === 0) {
        return null
    }

    const dropDownLabel = versionTimeStamp.split(' ').slice(1).join(' ')

    return (
        <Dropdown className={className}>
            <Dropdown.Toggle className="styled-dropdown-select passage-video-selector-2-toggle" disabled={disabled}>
                <label>{dropDownLabel}</label>
            </Dropdown.Toggle>

            <Dropdown.Menu className="styled-dropdown-select-menu">
                {textHistory.map(({ modDate, modBy, text }, index) => {
                    return (
                        <Dropdown.Item
                            key={modDate}
                            onClick={() => handleViewOlderVersion(index, text, modBy, modDate)}
                        >
                            <VisibleTimestamp
                                className="passage-document-timestamp"
                                name={modBy}
                                dateBy={modDate}
                                dateFormatter={dateFormatter}
                            />
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}
