import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { SortEnd, SortableContainer } from 'react-sortable-hoc'

import { NoPortionsMessage } from './NoPortionsMessage'
import PortionAdder from './PortionAdder'
import PortionEditor from './PortionEditor'
import PortionListItem from './PortionView'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { ExportSourceType } from '../../types'
import { ExportModal } from '../modals/export/ExportModal'
import { CopyProjectDataModal } from '../modals/project/CopyDataModal'
import { CopyButton, DownloadButton } from '../utils/Buttons'
import { systemError, CustomErrorBoundary } from '../utils/Errors'
import { FirstTimeSetupModal } from '../utils/FirstTimeSetupModal'
import LoadingMessage from '../utils/InitializationMessage'

import './Portion.css'

interface ISortableContainer {
    rt: Root
    portions: Portion[]
}

interface IPortionsEditor {
    rt: Root
}

const SortableList = SortableContainer((props: ISortableContainer) => {
    const { t } = useTranslation()
    const { rt, portions } = props
    const { iAmTranslator } = rt

    return (
        <div>
            {portions.map((portion, index) => (
                <CustomErrorBoundary
                    key={portion._id}
                    fallbackUI={
                        <div
                            className="portion-portion portion-error"
                            title={t('Something went wrong while displaying this portion')}
                        >
                            <b>{`${portion.name}???`}</b>
                        </div>
                    }
                >
                    <PortionListItem disabled={!iAmTranslator} index={index} rt={rt} portion={portion} />
                </CustomErrorBoundary>
            ))}
        </div>
    )
})

export const InitializedPortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { t } = useTranslation()
    const { project, useMobileLayout, iAmTranslator, iAmRoot } = rt
    const [isCopyPortionsModalOpen, setIsCopyPortionsModalOpen] = useState(false)
    const [isExportModalOpen, setIsExportModalOpen] = useState(false)
    const [isAddPortionModalOpen, setIsAddPortionModalOpen] = useState(false)
    const { portions } = project

    const isFirstTimeSetupNeeded = !iAmRoot && iAmTranslator && !portions.length
    const [isFirstTimeSetupModalOpen, setIsFirstTimeSetupModalOpen] = useState(isFirstTimeSetupNeeded)

    // TODO: show this modal on every page
    if (isFirstTimeSetupModalOpen) {
        return <FirstTimeSetupModal setIsModalOpen={setIsFirstTimeSetupModalOpen} />
    }

    const onSortEnd = (result: SortEnd) => {
        const { oldIndex, newIndex } = result

        project.movePortion(project.portions[oldIndex]._id, newIndex).catch(systemError)
    }

    return (
        <div className="portions-editor">
            <h3>
                {t('Portions')} {`(${project.displayName})`}
            </h3>

            {project.portions.length > 0 && (
                <div className="portions-menu">
                    <CopyButton
                        onClick={() => setIsCopyPortionsModalOpen(true)}
                        buttonClassName=""
                        className="portions-menu-item"
                        tooltip={t('Copy portions')}
                        enabled
                    />
                    {isCopyPortionsModalOpen && (
                        <CopyProjectDataModal
                            setOpenModal={setIsCopyPortionsModalOpen}
                            source={project}
                            copyDataType="portions"
                        />
                    )}

                    <DownloadButton
                        className="portions-menu-item"
                        tooltip={t('Export')}
                        onClick={() => setIsExportModalOpen(true)}
                        enabled
                    />

                    {isExportModalOpen && (
                        <ExportModal
                            rt={rt}
                            exportSourceType={ExportSourceType.PROJECT}
                            setOpen={setIsExportModalOpen}
                        />
                    )}
                </div>
            )}

            {isAddPortionModalOpen && (
                <PortionEditor rt={rt} setEditing={setIsAddPortionModalOpen} save={rt.saveNewPortion} />
            )}

            {portions.length === 0 && <NoPortionsMessage allowedToEdit={iAmTranslator} />}

            <SortableList portions={project.portions} rt={rt} useDragHandle onSortEnd={onSortEnd} distance={5} />
            {!useMobileLayout && iAmTranslator && <PortionAdder onClick={() => setIsAddPortionModalOpen(true)} />}
        </div>
    )
})

export const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { initialized, loadingMessage } = rt

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    return <InitializedPortionsEditor rt={rt} />
})
