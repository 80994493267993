import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AudioPlayerPlaceholder } from './AudioPlayerPlaceholder'
import { AudioRecordingRecorder } from './AudioRecorder'
import { ReviewRecordingAudioPlayer } from './ReviewRecordingAudioPlayer'
import { DeleteButton, RecordButton } from '../utils/Buttons'

import './AudioPlayer.css'

interface AudioPlayerAndRecorderProps {
    url: string
    setUrl: (url: string) => void
    recordedBlob: Blob | undefined
    setRecordedBlob: (blob: Blob | undefined) => void
    avttProjectName: string
}

export const ReviewRecordingPlayerAndRecorder = observer(
    ({ url, setUrl, recordedBlob, setRecordedBlob, avttProjectName }: AudioPlayerAndRecorderProps) => {
        const [isRecording, setIsRecording] = useState(false)
        const { t } = useTranslation()

        if (isRecording) {
            return (
                <AudioRecordingRecorder
                    closeRecorder={() => {
                        setRecordedBlob(undefined)
                        setIsRecording(false)
                    }}
                    onDoneRecording={(blob) => {
                        setRecordedBlob(blob)
                        setIsRecording(false)
                    }}
                />
            )
        }

        const hasRecordedBlobs = Boolean(recordedBlob)

        return (
            <>
                <div className="audio-button-row">
                    <RecordButton
                        enabled
                        className="small-button audio-recorder-record-button"
                        onClick={() => setIsRecording(true)}
                        tooltip={t('Start recording')}
                    />
                    {Boolean(hasRecordedBlobs || url) && (
                        <DeleteButton
                            enabled
                            className="small-button"
                            onClick={() => {
                                setRecordedBlob(undefined)
                                setUrl('')
                            }}
                            tooltip={t('Cancel')}
                        />
                    )}
                </div>
                {hasRecordedBlobs || url ? (
                    <ReviewRecordingAudioPlayer
                        url={url}
                        recordedBlob={recordedBlob}
                        avttProjectName={avttProjectName}
                    />
                ) : (
                    <AudioPlayerPlaceholder />
                )}
            </>
        )
    }
)
