import { observer } from 'mobx-react'
import { TFunction, useTranslation } from 'react-i18next'

import FilePicker from './FilePicker'
import { importEAFFile } from '../../elan/ELANCreator'
import { downloadFcpxml } from '../../finalcutpro/finalcutpro'
import { FfmpegParameters } from '../../models3/FfmpegParameters'
import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { RecordingType } from '../../types'
import { displayError, displayInfo } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { downloadWithFilename, incrementTime } from '../utils/Helpers'
import { appendSilenceToEndOfRecording, encodeOpus, shouldEncodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:BaseRecordingFilePicker')

interface BaseRecordingFilePickerProps {
    enabled: boolean
    rt: Root
    passage: Passage
    className: string
}

const writeTestFileToCache = async (file: File) => {
    const _id = `${file.name.slice(2, -4)}-1`
    log('writeTestFileToCache', _id)

    const vcr = VideoCacheRecord.get(_id)
    await vcr.deleteBlobs() // if existing video data for this file, remove it
    vcr.size = file.size
    await vcr.addBlob(file)

    log('!!!writeTestFileToCache DONE', _id)
}

const needsCompression = (file: File) => {
    if (file.name.includes('_force_compression_')) return true

    const sizeMB = file.size / (1024 * 1024)
    log('needsCompression?', fmt({ sizeMB: sizeMB.toFixed(0) }))

    if (shouldEncodeOpus(file.name)) return true

    if (file.name.toLowerCase().endsWith('.mxf')) return true
    if (file.name.toLowerCase().endsWith('.mov')) return true
    if (file.name.toLowerCase().endsWith('.wmv')) return true

    return sizeMB > 100
}

const validateFile = (file: File, maxVideoSizeMB: number, t: TFunction) => {
    if (file.size > maxVideoSizeMB * 1024 * 1024) {
        throw new Error(t('fileSizeTooLarge', { maxVideoSizeMB }))
    }

    const video = document.createElement('video')
    if (!video.canPlayType(file.type)) {
        throw new Error(t('Cannot play this type of file.'))
    }
}

interface UploaderProps {
    file: File
    rt: Root
    passage: Passage
    t: TFunction
    videoToCopyFrom?: PassageVideo
    baseVideoOptions?: {
        preserveSegmentation?: boolean
        warnAboutOldData?: boolean
    }
    audioCompressionOptions?: { bitrate: number }
    recordingType: RecordingType
}

export const uploadRecording = async ({
    file,
    rt,
    passage,
    t,
    videoToCopyFrom,
    baseVideoOptions,
    audioCompressionOptions,
    recordingType
}: UploaderProps) => {
    const updatePassageVideo = async (recording: PassageVideo | null) => {
        // We set passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the passageVideo and triggers
        // a VideoMain render before the passageVideo has all the info
        // to correctly display
        await rt.setPassageVideo(null)
        await rt.setPassageVideo(recording)
    }

    const addPatchAndUpdateState = async ({
        baseRecording,
        patch,
        baseSegment
    }: {
        baseRecording: PassageVideo
        patch: PassageVideo
        baseSegment: PassageSegment
    }) => {
        const onTopSegment = baseSegment.actualSegment(passage)
        const newVideo = await passage.addPatchVideo({
            baseRecording,
            patch,
            baseSegment,
            onTopSegment
        })

        const updatedPassageVideo = rt.passageVideo
        await updatePassageVideo(updatedPassageVideo)
        rt.setPassageSegment(baseSegment)
        rt.resetCurrentTime(incrementTime(onTopSegment.time))
        return newVideo
    }

    const handleBaseVideo = async (video: PassageVideo) => {
        // This will indirectly trigger uploading the video blob in the cache to S3
        // when DBAcceptor calls VideoCache.acceptPassageVideo
        const newVideo = await passage.addNewBaseVideo({
            video,
            videoToCopyFrom,
            project: rt.project,
            options: baseVideoOptions
        })

        await rt.setPassage(passage)
        await updatePassageVideo(newVideo)
        return newVideo
    }

    const handlePatchedSegment = async (video: PassageVideo) => {
        const { passageVideo, passageSegment, timeline } = rt
        if (!passageVideo || !passageSegment) {
            return
        }

        const segment = rt.patchableSelectionPresent()
            ? await passageVideo.createSelectionSegment(passage, timeline)
            : passageSegment
        return addPatchAndUpdateState({ baseRecording: passageVideo, patch: video, baseSegment: segment })
    }

    const uploadFile = async (fileToUpload: File, ffmpegParametersUsed?: FfmpegParameters) => {
        const video = await passage.uploadFile(fileToUpload, rt.name)

        if (ffmpegParametersUsed !== undefined) {
            video.ffmpegParametersUsed = ffmpegParametersUsed
        }

        if (recordingType === RecordingType.BASE) {
            return handleBaseVideo(video)
        }
        if (recordingType === RecordingType.PATCH) {
            return handlePatchedSegment(video)
        }
    }

    const uploadVideo = async (fileToUpload: File, ffmpegParametersUsed?: FfmpegParameters) => {
        try {
            validateFile(fileToUpload, rt.project.maxVideoSizeMB, t)
            return await uploadFile(fileToUpload, ffmpegParametersUsed)
        } catch (err) {
            displayError(err)
        }
    }

    const compressAndUpload = async (fileToUpload: File, setProgressMessage: (message: string) => void) => {
        log('compressAndUpload start')

        try {
            if (shouldEncodeOpus(fileToUpload.name)) {
                const compressedFile = await encodeOpus(fileToUpload)
                return await uploadVideo(compressedFile)
            }

            const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
            if (!isCompressorRunning) return

            let resolution = rt.project.compressedVideoResolution
            let quality = rt.project.compressedVideoQuality
            while (true) {
                const compressor = new VideoCompressor(
                    {
                        crf: rt.project.compressedVideoQuality,
                        resolution: rt.project.compressedVideoResolution,
                        maxFileSizeMB: rt.project.maxVideoSizeMB
                    },
                    setProgressMessage
                )
                const { compressedFile, ffmpegParameters } = await compressor.compressVideo(fileToUpload)

                const sizeMB = compressedFile.size / (1024 * 1024)
                log(
                    'compressAndUpload compressVideo',
                    fmt({
                        sizeMB,
                        resolution,
                        quality
                    })
                )

                if (sizeMB <= rt.project.maxVideoSizeMB) {
                    return await uploadVideo(compressedFile, ffmpegParameters)
                }

                if (resolution > 480) {
                    displayInfo(
                        `${t('Compressed 720p file too large. Try compressing to 480p.')} [${sizeMB.toFixed(0)}mb]`
                    )
                    resolution = 480
                    continue
                }

                if (quality < 28) {
                    quality = Math.min(rt.project.compressedVideoQuality + 3, 28)
                    displayInfo(
                        `${t('recordingCompressedTooLargeTryingAgain')} [${sizeMB.toFixed(0)}mb, crf=${quality}]`
                    )
                    continue
                }

                displayError(t('recordingCompressedTooLarge'))
                break
            }
        } catch (err) {
            const error = err as Error
            log('compressAndUpload ERROR', JSON.stringify(error))

            setProgressMessage('')

            if (error.name === 'NotEnoughFreeSpace') {
                displayError(t('Your hard drive does not have enough free space.'))
            } else if (error.name === 'PayloadTooLarge') {
                displayError(t('File upload is too large.'))
            } else if (error.name === 'CompressedFileTooLarge') {
                displayError(
                    t('recordingCouldNotCompressSmallerThanMax', {
                        maxVideoSizeMB: rt.project.maxVideoSizeMB
                    }) + t('Retry with different compression settings.')
                )
            } else {
                displayError(t('recordingCouldNotCompress'))
            }
        }
    }

    const testCompressionServer = async (fileToTest: File, setProgressMessage: (message: string) => void) => {
        const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
        if (!isCompressorRunning) {
            return
        }

        try {
            const compressor = new VideoCompressor(
                {
                    crf: rt.project.compressedVideoQuality,
                    resolution: rt.project.compressedVideoResolution,
                    maxFileSizeMB: rt.project.maxVideoSizeMB
                },
                setProgressMessage
            )
            const compressedData = await compressor.compressVideo(fileToTest)
            const { compressedFile } = compressedData

            let fileExtension = 'mp4'
            if (compressedFile.type.startsWith('audio')) {
                fileExtension = 'mp3'
            }

            // Download compressed video file
            const href = window.URL.createObjectURL(compressedFile)
            downloadWithFilename(href, `compressed-video.${fileExtension}`)
        } catch (err) {
            const error = err as Error
            setProgressMessage('')
            displayError(error.name)
        }
    }

    const upload = async () => {
        // Files with rt.names starting __video are treated as test files and directly
        // loaded to the video cache
        if (file.name.startsWith('__video')) {
            await writeTestFileToCache(file)
            return
        }

        if (!rt.iAmTranslatorForPassage(passage)) {
            displayError(t('recordingOnlyTranslatorsCanUpload'))
            return
        }

        const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

        const isAudioFile = file.type.startsWith('audio')
        const isVideoFile = file.type.startsWith('video')

        if (file.name.startsWith('_test_comp_')) {
            await testCompressionServer(file, setProgressMessage)
            return
        }

        if (rt.passageVideo) {
            // Process Final Cut Pro file
            if (file.name.endsWith('.fcpxml')) {
                downloadFcpxml(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }

            // Import ELAN gloss file
            if (file.name.endsWith('.eaf')) {
                importEAFFile(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }
        }

        const recordAudioOnly = rt.shouldRecordAudioOnly(recordingType, passage)

        if (recordAudioOnly && !isAudioFile) {
            displayError(t('This file must be an audio file.'))
            return
        }

        if (!recordAudioOnly && !isVideoFile) {
            displayError(t('This file must be a video file.'))
            return
        }

        setProgressMessage(t('Starting compression...'))
        const updatedFile =
            isAudioFile && recordingType === RecordingType.BASE
                ? await appendSilenceToEndOfRecording(file, { bitrate: audioCompressionOptions?.bitrate })
                : file

        const uploadedVideo = needsCompression(updatedFile)
            ? await compressAndUpload(updatedFile, setProgressMessage)
            : await uploadVideo(updatedFile)
        setProgressMessage('')
        return uploadedVideo
    }

    return upload()
}

export const uploadPatchRecording = async ({
    files,
    rt,
    passage,
    t
}: {
    files: FileList
    rt: Root
    passage: Passage
    t: TFunction
}) => {
    if (files.length !== 1) {
        displayError(t('You must drop exactly one file.'))
        return
    }
    const file = files[0]

    await uploadRecording({ file, rt, passage, t, recordingType: RecordingType.PATCH })
}

export const BaseRecordingFilePicker = observer(({ enabled, rt, passage, className }: BaseRecordingFilePickerProps) => {
    const { t } = useTranslation()
    const videoToCopyFrom = passage.latestVideo
    const isAudioOnly = rt.shouldRecordAudioOnly(RecordingType.BASE, passage)
    return (
        <FilePicker
            enabled={enabled}
            setSelectedFiles={(fileList) => {
                if (fileList.length > 0) {
                    const file = fileList[0]
                    uploadRecording({
                        file,
                        rt,
                        passage,
                        t,
                        videoToCopyFrom,
                        baseVideoOptions: { warnAboutOldData: true },
                        recordingType: RecordingType.BASE
                    })
                }
            }}
            className={className}
            iconName={isAudioOnly ? 'fa-file-audio' : 'fa-file-video'}
            accept={isAudioOnly ? 'audio/*' : 'video/*'}
        />
    )
})

export const PatchFilePicker = observer(({ enabled, rt, passage, className }: BaseRecordingFilePickerProps) => {
    const { t } = useTranslation()
    const isAudioOnly = rt.shouldRecordAudioOnly(RecordingType.PATCH, passage)
    return (
        <FilePicker
            enabled={enabled}
            setSelectedFiles={(fileList) => {
                uploadPatchRecording({ files: fileList, rt, passage, t })
            }}
            className={className}
            iconName={isAudioOnly ? 'fa-file-audio' : 'fa-file-video'}
            accept={isAudioOnly ? 'audio/*' : 'video/*'}
        />
    )
})
