import { FC, useState, useEffect } from 'react'

import { observer } from 'mobx-react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { SortableHandle, SortableElement } from 'react-sortable-hoc'

import PortionEditor from './PortionEditor'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { ExportSourceType } from '../../types'
import { useAppRoot } from '../app/RootContext'
import { ExportModal } from '../modals/export/ExportModal'
import { CopyProjectDataModal } from '../modals/project/CopyDataModal'
import { PortionNotificationList } from '../notifications/Notifications'
import { CopyButton, DownloadButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { DragAndDropIcon } from '../utils/Icons'

import 'react-confirm-alert/src/react-confirm-alert.css'
import './Portion.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:PortionView')

const DragHandle = SortableHandle(() => <DragAndDropIcon className="portion-handle" />)

interface PortionNameProps {
    portion: Portion
    setEditing: (value: boolean) => void
}

const PortionName = observer(({ portion, setEditing }: PortionNameProps) => {
    const [trackedProject, setTrackedProject] = useState<Project | undefined>(undefined)
    const appRoot = useAppRoot()
    const { t } = useTranslation()

    useEffect(() => {
        async function getTrackedProject() {
            if (portion.trackedProjectName.trim() !== '') {
                const trackedRt = appRoot.rts.find((r) => r.name === portion.trackedProjectName)
                await trackedRt?.initialize()
                setTrackedProject(trackedRt?.project)
            } else {
                setTrackedProject(undefined)
            }
        }

        getTrackedProject()
    }, [trackedProject?.displayName, portion.trackedProjectName, appRoot.rts])

    if (trackedProject) {
        const tooltip = (
            <Tooltip id="passage-tooltip">
                {portion.name}
                <div>{t('Copied from project {{projectName}}', { projectName: trackedProject.displayName })}</div>
            </Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip} placement="bottom" delay={{ show: 1000, hide: 0 }}>
                <div className="portion-name" data-id={portion.name} onDoubleClick={() => setEditing(true)}>
                    {portion.name}
                </div>
            </OverlayTrigger>
        )
    }

    return (
        <div className="portion-name" data-id={portion.name} onDoubleClick={() => setEditing(true)}>
            {portion.name}
        </div>
    )
})

interface PortionMenuProps {
    rt: Root
    portion: Portion
    setCopyModalOpen: (value: boolean) => void
    onDelete: () => void
    setEditing: (edit: boolean) => void
}

const PortionMenu: FC<PortionMenuProps> = ({ rt, portion, onDelete, setEditing, setCopyModalOpen }) => {
    const [exportModalOpen, setExportModalOpen] = useState(false)

    const { t } = useTranslation()

    return (
        <div className="portion-menu">
            {exportModalOpen && (
                <ExportModal
                    rt={rt}
                    exportSourceType={ExportSourceType.PORTION}
                    setOpen={setExportModalOpen}
                    portion={portion}
                />
            )}
            {rt.iAmTranslator && (
                <>
                    <span
                        className="portion-button"
                        data-toggle="tooltip"
                        data-id={`edit-${portion.name}`}
                        title={t('Edit')}
                        onClick={(e) => {
                            e.preventDefault()
                            setEditing(true)
                        }}
                    >
                        <i className="fas fa-fw fa-pencil-alt" />
                    </span>
                    <span
                        className="portion-button"
                        data-toggle="tooltip"
                        data-id={`delete-${portion.name}`}
                        title={t('Delete portion.')}
                        onClick={(e) => {
                            e.preventDefault()
                            onDelete()
                        }}
                    >
                        <i className="fas fa-fw fa-trash-alt" />
                    </span>
                </>
            )}

            <CopyButton
                onClick={() => setCopyModalOpen(true)}
                buttonClassName=""
                className="portion-button"
                tooltip={t('Copy portion')}
                enabled
            />

            <DownloadButton
                className="portion-button portion-download-button"
                tooltip={t('Export')}
                onClick={() => setExportModalOpen(true)}
                enabled
            />
        </div>
    )
}

interface IPortionView {
    rt: Root
    portion: Portion
    onDelete: () => void
}

const PortionView: FC<IPortionView> = observer(({ rt, portion, onDelete }) => {
    const [editing, setEditing] = useState(false)
    const [copyModalOpen, setCopyModalOpen] = useState(false)

    const { useMobileLayout, project } = rt

    const save = async (name: string, references: RefRange[]) => {
        try {
            await portion.setName(name)
            await portion.setReferences(references)
            rt.setDbsRefs(rt.portion)
        } catch (error) {
            displayError(error)
        }
    }

    return (
        <>
            {editing && <PortionEditor rt={rt} portion={portion} setEditing={setEditing} save={save} />}
            {copyModalOpen && (
                <CopyProjectDataModal
                    setOpenModal={setCopyModalOpen}
                    source={project}
                    copyDataType="portion"
                    copyData={portion}
                />
            )}
            <PortionName portion={portion} setEditing={setEditing} />
            <div className="portion-references">{rt.displayableReferences(portion.references)}</div>
            <div className="portion-notifications">
                <PortionNotificationList {...{ rt, portion }} />
            </div>
            {!useMobileLayout && (
                <PortionMenu
                    {...{
                        rt,
                        portion,
                        onDelete,
                        setEditing,
                        setCopyModalOpen
                    }}
                />
            )}
        </>
    )
})
interface ISortableElement {
    rt: Root
    portion: Portion
}

const PortionListItem = SortableElement(function (props: ISortableElement) {
    const { rt, portion } = props
    const { passages } = portion
    const { iAmTranslator, useMobileLayout, username, project } = rt
    const { reviewProject, removePortion } = project
    const { t } = useTranslation()

    function confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('deletePortionTitle'),
            message: t('deletePortionMessage'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => {}
                },
                {
                    label: t('Yes'),
                    onClick: doDeletion
                }
            ]
        })
    }

    function onDelete() {
        log('onDelete')
        confirmDeletion(() => {
            if (reviewProject) {
                const videos = passages.flatMap((passage) => passage.videos)
                videos.forEach((video) => video.getLatestReviewRecording(reviewProject)?.setIsActive(false))
            }

            removePortion(portion._id)
                .then(() => {
                    const { portions } = project
                    return rt.setPortion(portions.length ? portions[0] : null)
                })
                .catch(displayError)
        })
    }

    const assigned = passages.some((passage) => passage.assignee === username && passage.task !== 'Finished')
    return (
        <div className={`portion-portion ${assigned ? 'passage-assigned' : ''}`}>
            {iAmTranslator && !useMobileLayout && <DragHandle />}
            <PortionView portion={portion} rt={rt} onDelete={onDelete} />
        </div>
    )
})

export default PortionListItem
