import { hasReachedEnd, isWithinTolerance } from '../utils/Helpers'

const toleranceSeconds = 0.05

// The tolerance needs to be sufficiently larger than the time between ticks
export const hasReachedPlaybackEndTime = (time: number, endingTime: number) =>
    hasReachedEnd(time, endingTime, toleranceSeconds)

export const isContiguousVideo = (videoId1: string, endPosition: number, videoId2: string, position: number) => {
    return videoId1 === videoId2 && isWithinTolerance(endPosition, position, toleranceSeconds)
}
