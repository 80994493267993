/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useAppRoot } from '../app/RootContext'
import { CancelButton, StopButton } from '../utils/Buttons'
import { AVTTRecordingState, AudioRecorder } from '../video/VideoRecorder'
import { LiveWaveformVisualizerWrapper } from '../video/WaveformVisualizer'

import './AudioPlayer.css'

type AudioRecordingRecorderProps = {
    closeRecorder: () => void
    onDoneRecording: (blob: Blob) => void
}

export const AudioRecordingRecorder = observer(({ closeRecorder, onDoneRecording }: AudioRecordingRecorderProps) => {
    const { t } = useTranslation()
    const { rt } = useAppRoot()
    const { autoGainControl, recordingCountdown } = rt?.project ?? {}

    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const [audioRecorder] = useState(
        new AudioRecorder({
            recordingCountdown,
            onRecordingStateChange: setRecordingState,
            autoGainControl
        })
    )

    useEffect(() => {
        function _onDoneRecording(blob: Blob) {
            onDoneRecording(blob)
        }

        function _onError() {
            closeRecorder()
        }

        audioRecorder.addListener('onRecordingDone', _onDoneRecording)
        audioRecorder.addListener('onError', _onError)

        return () => {
            audioRecorder.removeListener('onRecordingDone', _onDoneRecording)
            audioRecorder.removeListener('onError', _onError)
        }
    })

    useEffect(() => {
        setTimeout(audioRecorder._record.bind(audioRecorder), 1000)

        return () => {
            if (audioRecorder.mediaRecorder) {
                const { state } = audioRecorder.mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    audioRecorder.cancel()
                }
            }
        }
    }, [])

    return (
        <div>
            <div className="audio-button-row">
                <StopButton
                    enabled={recordingState !== 'NOT_INITIALIZED' && recordingState !== 'INITIALIZED'}
                    onClick={() => {
                        audioRecorder.stopRecording() // will trigger onRecordingDone listener
                    }}
                    className="small-button audio-recorder-stop-button"
                    tooltip={t('Stop recording.')}
                />
                <CancelButton
                    enabled
                    onClick={() => {
                        audioRecorder.cancel()
                        closeRecorder()
                    }}
                    className="small-button"
                    tooltip={t('Cancel recording')}
                />
            </div>
            <div className="audio-recorder-visualization-wrapper">
                <LiveWaveformVisualizerWrapper
                    mediaStream={audioRecorder.mediaStream}
                    recordingState={recordingState}
                    isAudioOnly
                    className="audio-recorder-visualization"
                />
            </div>
        </div>
    )
})
