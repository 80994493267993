import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { TextHistoryEntry } from '../types'

export class PassageSegmentDocument extends DBObject {
    @observable text = ''

    @observable textHistory: TextHistoryEntry[] = []

    toDocument(useExistingModDate?: boolean, useExistingModBy?: boolean) {
        const { text } = this
        return this._toDocument({ text, model: 12 }, useExistingModDate, useExistingModBy)
    }

    copy() {
        let copy = new PassageSegmentDocument(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setText(text: string, modBy?: string, modDate?: string) {
        if (this.text.trim() === text.trim()) {
            return
        }

        const doc = this._toDocument({ text, model: 12 })
        if (modBy) {
            doc.modBy = modBy
        }
        if (modDate) {
            doc.modDate = modDate
        }
        await this.db.put(doc)
    }
}
