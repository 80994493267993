import React, { Component, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import SegmentViewerTimeline from './SegmentViewerTimeline'
import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { LocalStorageKeys } from '../app/slttAvtt'
import { PlayButton, PauseButton } from '../utils/Buttons'
import { hasReachedEnd } from '../utils/Helpers'
import VideoDownloading, { BlobDownloading } from '../utils/VideoDownloading'
import {
    AudioWaveformCreator,
    CurrentPositionMarkerVisualization,
    SelectionLayer,
    WaveformVisualizer
} from '../video/WaveformVisualizer'

type ClickLayerWrapperProps = {
    width: number
    className: string
    setCurrentTime: (time: number) => void
    startPosition: number
    endPosition: number
}

const SelectionLayerWrapper = observer(
    ({ width, className, startPosition, endPosition, setCurrentTime }: ClickLayerWrapperProps) => {
        function xToTime(x: number) {
            const canvasWidth = width
            const frac = x / canvasWidth
            return startPosition + frac * (endPosition - startPosition)
        }

        function onClick(x: number) {
            setCurrentTime(xToTime(x))
        }

        return <SelectionLayer className={className} onClick={onClick} onDrag={() => {}} onDragEnd={() => {}} />
    }
)

type SegmentAudioWaveformVisualizerProps = {
    segment: PassageSegment
    passage: Passage
    startPosition: number
    endPosition: number
    currentTime: number
    setCurrentTime: (time: number) => void
}

const OldSegmentWaveformVisualizer = observer(
    ({
        segment,
        passage,
        startPosition,
        endPosition,
        currentTime,
        setCurrentTime
    }: SegmentAudioWaveformVisualizerProps) => {
        const [waveformData, setWaveformData] = useState<number[]>([])
        const [width, setWidth] = useState(0)

        useEffect(() => {
            let mounted = true

            async function getTheData() {
                const samplesOnScreen = 300
                const waveformCreator = new AudioWaveformCreator()
                const slices = await segment.getPlayableSlices(passage)
                const waveform = await waveformCreator.getNormalizedPCMValues(slices, samplesOnScreen)
                if (mounted) {
                    setWaveformData(waveform.normalizedPCMValues)
                }
            }
            getTheData()

            return () => {
                mounted = false
            }
        }, [passage, segment])

        function xPosition(time: number) {
            return ((time - startPosition) / (endPosition - startPosition)) * width
        }

        const currentTimeX = xPosition(currentTime)

        // We render the selection layer in a wrapper component because it was
        // rerendering too often.

        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <WaveformVisualizer
                    waveformData={waveformData}
                    setWidth={setWidth}
                    className="old-segment-viewer-waveform"
                />
                <CurrentPositionMarkerVisualization
                    currentPosition={currentTimeX}
                    className="old-segment-viewer-waveform"
                />
                <SelectionLayerWrapper
                    width={width}
                    className="old-segment-viewer-waveform"
                    setCurrentTime={setCurrentTime}
                    startPosition={startPosition}
                    endPosition={endPosition}
                />
            </div>
        )
    }
)

interface IOldSegmentPlayer {
    video: PassageVideo
    segment: PassageSegment
    passage: Passage
}

class OldSegmentPlayer extends Component<IOldSegmentPlayer> {
    @observable src = ''

    @observable vc: HTMLVideoElement | HTMLAudioElement | null = null

    @observable currentTime = 0

    @observable isPlaying = false

    private timerId: NodeJS.Timeout | null = null

    constructor(props: IOldSegmentPlayer) {
        super(props)

        this.play = this.play.bind(this)
        this.stop = this.stop.bind(this)
        this.timeUpdate = this.timeUpdate.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
    }

    componentWillUnmount() {
        this.stopUpdater()
    }

    setCurrentTime(time: number) {
        const { vc } = this
        this.stop()
        if (vc) {
            vc.currentTime = time
            this.currentTime = time
        }
    }

    play() {
        const { vc } = this

        this.stop()
        if (!vc) {
            return
        }

        const { segment } = this.props
        const start = segment.position
        const end = segment.endPosition
        if (hasReachedEnd(vc.currentTime, end)) {
            this.currentTime = start
            vc.currentTime = start
        }

        const playbackRateString = localStorage.getItem(LocalStorageKeys.VIDEO_PLAYBACK_RATE)
        const playbackRate = playbackRateString ? parseFloat(playbackRateString) : 1.0
        vc.playbackRate = playbackRate

        vc.play()
    }

    stop() {
        const { vc } = this

        if (!vc) {
            return
        }
        if (!vc.paused) {
            vc.pause()
        }
    }

    timeUpdate() {
        const { segment } = this.props
        const { vc } = this
        if (!vc) {
            this.stop()
            return
        }

        const end = segment.endPosition
        this.currentTime = vc.currentTime
        if (hasReachedEnd(vc.currentTime, end)) {
            this.stop()
        }
    }

    private startUpdater() {
        this.stopUpdater()
        this.timerId = setInterval(this.timeUpdate, 33)
    }

    private stopUpdater() {
        if (this.timerId) {
            clearInterval(this.timerId)
            this.timerId = null
        }
    }

    render() {
        const { video, segment, passage } = this.props

        const { url, creator } = video
        const { src, currentTime, setCurrentTime, isPlaying, play, stop } = this

        const start = segment.position
        const end = segment.endPosition
        const actualSrc = `${src}#t=${start},${end}`

        if (video.isAudioOnly()) {
            return (
                <div className="old-audio-segment-viewer-content">
                    <div className="old-segment-viewer-bottom">
                        {src ? (
                            <>
                                {isPlaying ? (
                                    <PauseButton
                                        enabled
                                        onClick={stop}
                                        tooltip={t('Pause.')}
                                        className="sl-segment-toolbar-button old-segment-viewer-pause-button"
                                    />
                                ) : (
                                    <PlayButton
                                        enabled
                                        selectionPresent={false}
                                        onClick={play}
                                        className="sl-segment-toolbar-button old-segment-viewer-play-button"
                                        tooltip={t('Play')}
                                    />
                                )}
                                <OldSegmentWaveformVisualizer
                                    segment={segment}
                                    currentTime={currentTime}
                                    startPosition={start}
                                    endPosition={end}
                                    passage={passage}
                                    setCurrentTime={setCurrentTime}
                                />
                                <audio
                                    src={actualSrc}
                                    ref={(vc) => (this.vc = vc)}
                                    onPlay={() => {
                                        this.isPlaying = true
                                        this.startUpdater()
                                    }}
                                    onPause={() => {
                                        this.isPlaying = false
                                        this.stopUpdater()
                                    }}
                                />
                            </>
                        ) : (
                            <BlobDownloading
                                url={url}
                                onEnded={(blob) => {
                                    if (!src) {
                                        this.src = window.URL.createObjectURL(blob)
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            )
        }

        return (
            <div className="old-segment-viewer-content">
                <div className="old-segment-viewer">
                    {src ? (
                        <>
                            <video
                                className="old-segment-viewer-video"
                                src={actualSrc}
                                ref={(vc) => (this.vc = vc)}
                                onPlay={() => {
                                    this.isPlaying = true
                                    this.startUpdater()
                                }}
                                onPause={() => {
                                    this.isPlaying = false
                                    this.stopUpdater()
                                }}
                            />
                            <div className="old-segment-viewer-timeline-area">
                                {isPlaying ? (
                                    <PauseButton
                                        enabled
                                        onClick={stop}
                                        tooltip={t('Pause.')}
                                        className="sl-segment-toolbar-button old-segment-viewer-pause-button"
                                    />
                                ) : (
                                    <PlayButton
                                        enabled
                                        selectionPresent={false}
                                        onClick={play}
                                        className="sl-segment-toolbar-button old-segment-viewer-play-button"
                                        tooltip={t('Play')}
                                    />
                                )}
                                <SegmentViewerTimeline
                                    domainStart={start}
                                    domainEnd={end}
                                    position={currentTime}
                                    updatePosition={setCurrentTime}
                                />
                            </div>
                        </>
                    ) : (
                        <VideoDownloading
                            className="media-placeholder"
                            videoUrl={url}
                            creator={creator}
                            fontSizePt={18}
                            onEnded={(blob) => {
                                if (!src) {
                                    this.src = window.URL.createObjectURL(blob)
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default observer(OldSegmentPlayer)
