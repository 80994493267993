import { FC } from 'react'

import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { Project } from '../../models3/Project'
import NoteMarker from '../notes/NoteMarker'

interface Props {
    note: PassageNote
    passage: Passage
    project: Project
}

const PassageNoteMarker: FC<Props> = ({ note, passage, project }) => {
    return (
        <div className="passage-notes-marker">
            <NoteMarker
                className="passage-note-marker__note-marker"
                enabled={!passage.videoBeingCompressed}
                note={note}
                noteColors={project.allNoteColors}
                noteLabels={project.noteLabels}
            />
        </div>
    )
}

export default PassageNoteMarker
