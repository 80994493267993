import { useTranslation } from 'react-i18next'

import { PUBLIC_IMAGES_BASE_PATH } from '../slttAvtt'

export const Header = () => {
    const { t } = useTranslation()

    return (
        <div className="amplify-left-container">
            <div className="amplify-left-container-header">
                <img className="amplify-avtt-logo" src={`${PUBLIC_IMAGES_BASE_PATH}/avtt-logo.png`} />
                <div className="amplify-left-container-sub-header">
                    <h2 className="amplify-login-header">{t('loginPageHeaderText')}</h2>
                    <img className="amplify-avtt-headset" src={`${PUBLIC_IMAGES_BASE_PATH}/avtt-headset.png`} />
                </div>
            </div>
            <img className="amplify-login-image" src={`${PUBLIC_IMAGES_BASE_PATH}/avtt-devices.png`} />
        </div>
    )
}
