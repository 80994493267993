// Not all headers can be programmatically set/changed. See
// https://developer.mozilla.org/en-US/docs/Glossary/Forbidden_response_header_name.
// This shouldn't be a problem, because all the ones we care about can be set.
const serializeHeaders = (response: Response, body?: Blob) => {
    const headers = Object.fromEntries(response.headers)

    if (body) {
        // Recalculate, since we aren't using compression
        headers['content-length'] = body.size.toString()
    }

    delete headers['content-encoding'] // No compression
    delete headers.authorization // secret, probably not used in caching because it's unique

    return headers
}

export const serializeResponse = async (response: Response) => {
    const { type, url, redirected, status, statusText, ok, body } = response

    const serializedBody = body ? await response.blob() : undefined

    return {
        type,
        url,
        redirected,
        status,
        ok,
        statusText,
        bodyUsed: false, // will be false when this response is deserialized
        body: serializedBody,
        headers: serializeHeaders(response, serializedBody)
    }
}
