import { FC } from 'react'

import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import PassageNoteMarker from './PassageNoteMarker'
import PassageNoteTime from './PassageNoteTime'
import { PassageNote } from '../../models3/PassageNote'
import { Root } from '../../models3/Root'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'
import { HelpLinkWithMessage } from '../utils/Buttons'
import './PassageNotesViewer.css'

interface Props {
    rt: Root
}

const PassageNotesViewer: FC<Props> = ({ rt }) => {
    const { t } = useTranslation()
    const { dateFormatter, passage, passageVideo, project, setNote } = rt

    if (!passage || !passageVideo || !passageVideo.getVisibleNotes(passage, false)?.length) {
        return <HelpLinkWithMessage message={t('noPassagesNotesMessage')} id="notes" />
    }

    const visibleNotes = passageVideo.getVisibleNotes(passage, false)
    const getLastModificationDetails = (note: PassageNote) => {
        if (!note.items.length) {
            return { lastModBy: note.modBy, lastModDate: note.modDate }
        }
        const lastItem = note.items[note.items.length - 1]
        const isLastItem = new Date(lastItem.modDate).getTime() > new Date(note.modDate).getTime()

        return {
            lastModBy: isLastItem ? lastItem.modBy : note.modBy,
            lastModDate: isLastItem ? lastItem.modDate : note.modDate
        }
    }

    const handleSetNote = (note: PassageNote) => setNote?.(note)

    return (
        <div className="passage-notes-viewer">
            <Table>
                <thead className="passage-notes-viewer__table-head">
                    <tr>
                        <th>{t('passageNotesTime')}</th>
                        <th>{t('passageNotesMarker')}</th>
                        <th>{t('passageNotesDescription')}</th>
                        <th>{t('passageNotesLastModified')}</th>
                    </tr>
                </thead>
                <tbody className="passage-notes-viewer__table-body">
                    {visibleNotes
                        .sort((a, b) => a.time - b.time)
                        .map((note) => {
                            const { lastModBy, lastModDate } = getLastModificationDetails(note)

                            return (
                                <tr key={note._id}>
                                    <td onClick={() => handleSetNote(note)}>
                                        <PassageNoteTime note={note} />
                                    </td>
                                    <td onClick={() => handleSetNote(note)}>
                                        <PassageNoteMarker note={note} passage={passage} project={project} />
                                    </td>
                                    <td>{note.description}</td>
                                    <td>
                                        <VisibleTimestamp
                                            name={lastModBy}
                                            dateBy={lastModDate}
                                            className="passage-notes-viewer__visible-timestamp"
                                            dateFormatter={dateFormatter}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        </div>
    )
}

export default PassageNotesViewer
