import { FC } from 'react'

import { getTimecodeFormat } from '../../models3/DateUtilities'
import { PassageNote } from '../../models3/PassageNote'

interface Props {
    note: PassageNote
}

const PassageNoteTime: FC<Props> = ({ note }) => {
    return <div className="passage-notes-viewer__note-time">{getTimecodeFormat(note.time)}</div>
}

export default PassageNoteTime
