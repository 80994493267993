import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { AudioClip } from '../../models3/AudioClip'
import { IDateFormatter } from '../../models3/DateUtilities'
import { PassageSegment } from '../../models3/PassageSegment'
import { SegmentDocumentType, TextHistoryEntry } from '../../types'
import { useAppRoot } from '../app/RootContext'
import { AudioPlayerWithPlaceholder } from '../audio/AudioPlayerWithPlaceholder'
import { OKButton, CancelButton, DeleteButton, PencilButton, RecordButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { getTimeStamp } from '../utils/Helpers'
import { OralTranscriberMultiSelect } from '../utils/oralTranscriber/OralTranscriberMultiSelect'
import { OralTranscriberRecorder } from '../utils/oralTranscriber/OralTranscriberRecorder'
import { TextHistoryDropdown } from '../utils/TextHistoryDropdown'
import { WarningList } from '../utils/WarningList'
import { saveRecording } from '../video/VideoUploader'

import './Segments.css'

const EditorHeader = ({
    documentType,
    enableEditButton,
    isEditing,
    setIsEditing,
    setIsEditingSegment,
    versionTimeStamp,
    textHistory,
    dateFormatter,
    isOlderVersion,
    handleViewOlderVersion
}: {
    documentType: SegmentDocumentType
    enableEditButton: boolean
    isEditing?: SegmentDocumentType
    setIsEditing: (value: SegmentDocumentType) => void
    setIsEditingSegment: (value: boolean) => void
    versionTimeStamp: string
    textHistory: TextHistoryEntry[]
    dateFormatter: IDateFormatter
    isOlderVersion: boolean
    handleViewOlderVersion: (index: number, selectedText: string, selectedName: string, selectedDate: string) => void
}) => {
    const { t } = useTranslation()
    return (
        <div className="segment-dialog-heading-group">
            <div className="segment-dialog-heading">
                {documentType === 'transcription' ? t('transcriptionPanel') : t('backTranslationPanel')}
            </div>
            <div className="button-row-space-between">
                <div className="button-row">
                    <PencilButton
                        enabled={enableEditButton && !isOlderVersion}
                        onClick={() => {
                            setIsEditing(documentType)
                            setIsEditingSegment(true)
                        }}
                        className="right-pane-button default-blue-icon"
                        tooltip={t('Edit')}
                    />
                </div>
            </div>
            {!isEditing && (
                <TextHistoryDropdown
                    versionTimeStamp={versionTimeStamp}
                    textHistory={textHistory}
                    dateFormatter={dateFormatter}
                    disabled={false}
                    handleViewOlderVersion={handleViewOlderVersion}
                />
            )}
        </div>
    )
}

const RecordingControls = ({
    enableDeleteButton,
    setAudioUrl,
    setIsRecording,
    setRecordedBlob
}: {
    enableDeleteButton: boolean
    setAudioUrl: (value: string) => void
    setIsRecording: (value: boolean) => void
    setRecordedBlob: (value: Blob | undefined) => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="audio-button-row">
            <RecordButton
                enabled
                className="small-button audio-recorder-record-button"
                onClick={() => setIsRecording(true)}
                tooltip={t('Start recording')}
            />
            <DeleteButton
                enabled={enableDeleteButton}
                className="small-button"
                onClick={() => {
                    setRecordedBlob(undefined)
                    setAudioUrl('')
                }}
                tooltip={t('Cancel')}
            />
            <OralTranscriberMultiSelect />
        </div>
    )
}

// initialText !== text || initialAudioUrl !== audioUrl || hasRecorded
const FormControls = ({
    enableSaveButton,
    handleDocumentSave,
    resetEditing
}: {
    enableSaveButton: boolean
    handleDocumentSave: () => Promise<void>
    resetEditing: () => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="segment-document-bottom button-row">
            <OKButton
                enabled={enableSaveButton}
                onClick={handleDocumentSave}
                buttonClassName=""
                className="large-right-pane-button segment-document-ok-button"
                tooltip={t('Save edits and close')}
            />
            <CancelButton
                enabled
                className="large-right-pane-button"
                tooltip={t('Cancel edits')}
                onClick={resetEditing}
            />
        </div>
    )
}

interface SegmentDocumentEditorProps {
    segment: PassageSegment
    documentType: SegmentDocumentType
    className: string
    isEditing?: SegmentDocumentType
    isEditingSegment: boolean
    setIsEditing: (isEditing?: SegmentDocumentType) => void
    setIsEditingSegment: (value: boolean) => void
    readOnly?: boolean
    showMessage?: boolean
    recordingDate?: string
    dateFormatter: IDateFormatter
}

export const SegmentDocumentEditor = ({
    segment,
    documentType,
    className,
    isEditing,
    isEditingSegment,
    setIsEditing,
    setIsEditingSegment,
    recordingDate,
    readOnly,
    showMessage,
    dateFormatter
}: SegmentDocumentEditorProps) => {
    const { rt } = useAppRoot()
    const { t } = useTranslation()

    const [isRecording, setIsRecording] = useState(false)
    const [recordedBlob, setRecordedBlob] = useState<Blob>()

    const audioClip = documentType === 'backTranslationText' ? segment.getCurrentAudioClip() : undefined
    const initialAudioUrl = audioClip ? audioClip.url : ''

    const document = segment.getCurrentDocument(documentType)
    const { text: initialText, modDate: initialModDate } = document ?? { text: '', modDate: '' }

    const textHistory = document?.textHistory.slice().reverse() || []

    const mostRecentVersion = textHistory.length > 0 ? textHistory[0] : undefined

    const [audioUrl, setAudioUrl] = useState(initialAudioUrl)
    const [text, setText] = useState(initialText)
    const [initText, setInitText] = useState(initialText)
    const [date, setDate] = useState(initialModDate)
    const [isOlderVersion, setIsOlderVersion] = useState<boolean>(false)
    const [versionTimeStamp, setVersionTimeStamp] = useState<string>('')

    useEffect(() => {
        setIsEditing(undefined)
    }, [segment, setIsEditing])

    useEffect(() => {
        setVersionTimeStamp(
            mostRecentVersion ? getTimeStamp(mostRecentVersion.modBy, mostRecentVersion.modDate, dateFormatter) : ''
        )
        setIsEditingSegment(false)
        setRecordedBlob(undefined)
        setText(initialText)
        setInitText(initialText)
        setDate(initialModDate)
        setAudioUrl(initialAudioUrl)
    }, [initialAudioUrl, initialModDate, initialText, dateFormatter, setIsEditingSegment, mostRecentVersion])

    const handleAudioRecordingSave = async (audioClipToSave: AudioClip) => {
        if (!recordedBlob) {
            return
        }
        const result = await saveRecording(recordedBlob, audioClipToSave.url)
        if (result.type === 'error') {
            displayError(result.error)
            return
        }

        const { url, duration } = result
        audioClipToSave.url = url
        audioClipToSave.duration = duration
        await segment.addAudioClip(audioClipToSave)
        setRecordedBlob(undefined)
        setAudioUrl(audioClipToSave.url)
    }

    const saveAudioRecording = async () => {
        if (recordedBlob) {
            const audioClipToSave = segment.createAudioClip(rt?.name ?? '')
            await handleAudioRecordingSave(audioClipToSave)
        } else if (audioClip && !audioUrl) {
            await segment.removeAudioClip(audioClip._id)
        }
    }

    const saveDocumentText = async (value?: SegmentDocumentType) => {
        if (initText !== text) {
            if (document) {
                await document.setText(text)
            } else {
                const newDocument = segment.createDocument(documentType)
                newDocument.text = text
                await segment.addDocument(documentType, newDocument)
            }
            setIsEditing(value)
            setInitText(text)
            setText(text)
        }
    }

    const handleShortcutSave = async (value?: SegmentDocumentType) => {
        await saveDocumentText(value)
        await saveAudioRecording()
    }

    const handleDocumentSave = async () => {
        try {
            await saveDocumentText(undefined)
            await saveAudioRecording()

            setIsEditing(undefined)
            setIsEditingSegment(false)
        } catch (error) {
            displayError(error)
        }
    }

    const handleKeyDown = async (e: KeyboardEvent) => {
        if (e.code === 'KeyS' && e.ctrlKey && e.altKey) {
            e.preventDefault()

            if (isEditing) {
                await handleShortcutSave(isEditing)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })

    const handleViewOlderVersion = (
        index: number,
        selectedText: string,
        selectedName: string,
        selectedDate: string
    ) => {
        setVersionTimeStamp(getTimeStamp(selectedName, selectedDate, dateFormatter))
        setIsOlderVersion(index !== 0)
        setText(selectedText)
    }

    const allowRecording = documentType === 'backTranslationText'
    const hasRecorded = Boolean(recordedBlob)

    const editorClassName = 'segment-text-editor__editor'
    const isEditingDocType = isEditing === documentType

    return (
        <div className={`segment-transcription ${className} ${isEditingDocType ? 'editing' : ''}`}>
            <EditorHeader
                documentType={documentType}
                enableEditButton={!readOnly && !isEditing && !isEditingSegment}
                setIsEditing={setIsEditing}
                setIsEditingSegment={setIsEditingSegment}
                versionTimeStamp={versionTimeStamp}
                textHistory={textHistory}
                dateFormatter={dateFormatter}
                handleViewOlderVersion={handleViewOlderVersion}
                isOlderVersion={isOlderVersion}
                isEditing={isEditing}
            />

            {showMessage && recordingDate && date && date < recordingDate && (
                <WarningList warnings={[{ text: t('olderThanSegment'), key: 'older-than-segment' }]} />
            )}

            {isEditingDocType && allowRecording && !isRecording && (
                <RecordingControls
                    enableDeleteButton={Boolean(hasRecorded || audioUrl)}
                    setAudioUrl={setAudioUrl}
                    setRecordedBlob={setRecordedBlob}
                    setIsRecording={setIsRecording}
                />
            )}

            {isRecording ? (
                <OralTranscriberRecorder
                    closeRecorder={() => {
                        setRecordedBlob(undefined)
                        setIsRecording(false)
                    }}
                    onDoneRecording={(blob) => {
                        setRecordedBlob(blob)
                        setIsRecording(false)
                    }}
                    setTranscription={setText}
                />
            ) : (
                (audioUrl || hasRecorded) && (
                    <div className="segment-document-player">
                        <AudioPlayerWithPlaceholder url={audioUrl} recordedBlob={recordedBlob} />
                    </div>
                )
            )}

            <TextareaAutosize
                className={editorClassName}
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isEditingDocType}
                spellCheck={documentType !== 'transcription'}
                dir="auto"
            />

            {isEditingDocType && !isRecording && (
                <FormControls
                    enableSaveButton={initText !== text || initialAudioUrl !== audioUrl || hasRecorded}
                    handleDocumentSave={handleDocumentSave}
                    resetEditing={() => {
                        setIsEditing(undefined)
                        setIsEditingSegment(false)
                        setRecordedBlob(undefined)
                        setText(initText)
                        setAudioUrl(initialAudioUrl)
                    }}
                />
            )}
        </div>
    )
}
