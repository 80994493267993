/* eslint-disable no-underscore-dangle */
import i18next from 'i18next'
import { observable, computed } from 'mobx'

import { DateFormatterFactory, daysAgo, IDateFormatter } from './DateUtilities'
import { Passage } from './Passage'
import { PassageGloss } from './PassageGloss'
import { PassageNote } from './PassageNote'
import { PassageSegment } from './PassageSegment'
import { PassageSegmentLabel } from './PassageSegmentLabel'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { Project } from './Project'
import { ProjectReferences } from './ProjectReferences'
import { RootBase } from './RootBase'
import { getDefault, normalizeUsername, setDefault } from './Utils'
import { redirectToHome } from '..'
import { LocalStorageKeys } from '../components/app/slttAvtt'
import { defaultLabels } from '../components/segments/SegmentLabelsPosition'
import { displayError } from '../components/utils/Errors'
import { fmt } from '../components/utils/Fmt'
import { RefRange, refRangesToDisplay } from '../resources/RefRange'
import { RecordingType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Root')

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

export interface RootProps {
    project: Project
    username: string
    id_token: string
    iAmRoot: boolean
    group?: string
    groupProjects?: string[]
    selectPage: (selection: string) => void
}
export class Root extends RootBase {
    @computed get name() {
        return this.project.name
    }

    @computed get displayName() {
        return this.project.displayName
    }

    project: Project

    initializing = false

    @observable initialized = false

    @computed get dateFormatter(): IDateFormatter {
        return new DateFormatterFactory().getDateFormatter(this.project.dateFormat, i18next.language)
    }

    @observable portion: Portion | null = null

    @observable passageGloss: PassageGloss | null = null

    @observable passageSegment: PassageSegment | null = null

    @observable playbackRate = 1.0

    @observable statusShowAll = true

    @observable statusShowAssignedPassages = false

    @observable statusShowCurrentPortion = false

    @observable statusShowCurrentPassage = false

    username = ''

    id_token = ''

    @observable iAmRoot = false

    @observable iAmAdmin = false

    @observable iAmTranslator = false

    @observable iAmConsultant = false // anyone who can make notes

    @observable iAmInterpreter = false

    @observable iAmBackTranslator = false // can only do back translation and transcription

    @observable iAmApprover = false // must have consultant role

    @observable recording = false

    @observable passageVideoUrl = ''

    @observable passageVideoBlob: Blob | null = null

    @observable segmentLabelsDraft: PassageSegmentLabel[] = [] // Only use if editingSegmentLabels is true

    @observable editingSegmentLabels = false

    @observable termModalOpen = false

    group?: string

    groupProjects?: string[]

    @computed get videoPlaybackKeydownEnabled() {
        return !this.editingSegmentLabels && !this.note && !this.termModalOpen
    }

    @observable softNotificationCutoff = '2000/01/01' // Do not generate notifications for items created before this date

    @observable notificationMaxDays = 30

    hardNotificationCutoff = () => {
        const { softNotificationCutoff, notificationMaxDays } = this
        const notificationCutoffDate = new Date(softNotificationCutoff)
        return new Date(Math.max(daysAgo(notificationMaxDays), notificationCutoffDate.getTime()))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectPage: (selection: string) => void

    @observable glossScale = 0.0 // 0..100, 0 is narrow width glosses is GlossBar

    projectReferences = new ProjectReferences()

    @observable loadingMessage = ''

    @observable useMobileLayout = false

    @observable useNarrowWidthLayout = false

    previousGlossVideoId = ''

    previousGlossPosition = 0

    constructor({ project, username, id_token, iAmRoot, group, groupProjects, selectPage }: RootProps) {
        super()
        this.project = project
        this.username = normalizeUsername(username)
        this.id_token = id_token
        this.iAmRoot = iAmRoot
        this.group = group
        this.groupProjects = groupProjects
        this.selectPage = selectPage

        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.setTimelineZoom = this.setTimelineZoom.bind(this)
        this.play = this.play.bind(this)
        this.setGlossScale = this.setGlossScale.bind(this)
        this.hardNotificationCutoff = this.hardNotificationCutoff.bind(this)
        this.setSegmentLabelsDraft = this.setSegmentLabelsDraft.bind(this)
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
        this.displayableReferences = this.displayableReferences.bind(this)
        this.navigateToLinkLocation = this.navigateToLinkLocation.bind(this)
        this.pause = this.pause.bind(this)
        this.iAmTranslatorForPassage = this.iAmTranslatorForPassage.bind(this)
        this.setPassage = this.setPassage.bind(this)
        this.saveNewPortion = this.saveNewPortion.bind(this)

        if (this.iAmRoot) {
            log(`role = root`)
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmApprover = true
        }
    }

    dbg(details?: string) {
        const portion = this.portion && `${this.portion.name} | ${this.portion._id}`
        const passage = this.passage && `${this.passage.name} | ${this.passage._id}`
        const currentVideos = this.currentVideos.dbg()
        const passageGloss = this.passageGloss && this.passageGloss.dbg()
        const passageSegment = this.passageSegment && this.passageSegment.dbg(this.passage, details)

        const {
            statusShowAll,
            statusShowAssignedPassages,
            statusShowCurrentPassage,
            statusShowCurrentPortion,
            username,
            recording,
            playing,
            currentTime,
            duration,
            canPlayThrough
        } = this

        const note = (this.note && this.note.dbg(details?.includes('n'))) || 'none selected'
        const verseReference = (this.verseReference && this.verseReference.dbg()) || 'none selected'

        return {
            portion,
            passage,
            currentVideos,
            statusShowAll,
            statusShowAssignedPassages,
            statusShowCurrentPassage,
            statusShowCurrentPortion,
            username,
            recording,
            playing,
            currentTime,
            duration,
            canPlayThrough,
            passageGloss,
            passageSegment,
            note,
            verseReference
        }
    }

    async initialize() {
        const { initialized, initializing, name, project } = this
        log(`[${name}] --- start 7 model.initialize`)

        if (initialized || initializing) {
            log(`[${name}] model.initialize duplicate initialize ignored`)
            return
        }

        log(`[${this.name}] model.initialize A`)

        this.initializing = true

        await project.initialize((message: string) => {
            this.loadingMessage = message
        })

        log(`[${this.name}] model.initialize B`)

        this.setRole()
        await this.restoreDefaults()

        log(`[${this.name}] model.initialize C`)
        await this.navigateToLinkLocation()

        this.initialized = true
    }

    async navigateToLinkLocation() {
        const { origin, pathname, hash } = window.location
        const { project } = this
        const nonHashURL = origin.concat(pathname).concat(hash.slice(2))
        const url = new URL(nonHashURL)
        const searchParams = new URLSearchParams(url.search)
        const _id = searchParams.get('id')
        const time = searchParams.get('time')
        const linkProject = searchParams.get('project')

        if (!_id || !time || (linkProject && project.name !== linkProject)) {
            return
        }

        const portion = project.findPortion(_id)
        if (!portion) {
            displayError('Portion does not exist.')
            redirectToHome()
            return
        }

        const passage = project.findPassage(_id)
        if (!passage) {
            displayError('Passage does not exist.')
            redirectToHome()
            return
        }

        const pv = passage.findVideo(_id)
        if (!pv) {
            displayError('Passage video does not exist.')
            redirectToHome()
            return
        }

        await this.setPortion(portion)
        await this.setPassage(passage)
        // Root.setPassageVideo function assumes the video you pass is a base video
        await this.setPassageVideo(pv.baseVideo(passage) || pv)

        this.setNote(passage.findNote(_id))
        this.setCurrentTime(Number(time) || 0)
    }

    setRole() {
        if (!this.project) throw Error('Project not set')
        if (!this.project.members) throw Error('Members not set')

        if (this.iAmRoot) {
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmApprover = true
            return
        }

        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmBackTranslator = false
        this.iAmApprover = false

        const member = this.project.members.find((projectMember) => projectMember.email === this.username)
        if (!member) {
            log(`###setRole member [${this.username}] not found`)
            return
        }

        const { role } = member
        log(`setRole ${this.username} = ${role}, root=${this.iAmRoot}`)

        if (role === 'admin') this.iAmAdmin = true

        if (['admin', 'translator'].includes(role)) this.iAmTranslator = true

        if (['admin', 'translator', 'consultant'].includes(role)) this.iAmConsultant = true

        if (['admin', 'consultant'].includes(role)) this.iAmApprover = true

        if (['admin', 'translator', 'consultant', 'interpreter'].includes(role)) this.iAmInterpreter = true

        if (role === 'backTranslator') this.iAmBackTranslator = true
    }

    getDefault(tag: string) {
        return getDefault(tag, this.name)
    }

    setDefault(tag: string, value: string | null) {
        setDefault(tag, value, this.name)
    }

    // Retrieve the previous user settings from local storage.
    // Attempt to set project to previously selected portion and passage.
    async restoreDefaults() {
        log(`[${this.name}] restoreDefaults`)

        const playbackRateString = localStorage.getItem(LocalStorageKeys.VIDEO_PLAYBACK_RATE)
        log(`initial videoPlaybackRate ${playbackRateString}`)
        const playbackRate = playbackRateString ? parseFloat(playbackRateString) : 1.0
        this.playbackRate = playbackRate

        this.glossScale = parseFloat(this.getDefault('gloss-scale') || '100.0')

        this.timeline.zoomLevel = parseFloat(this.getDefault('timelineZoom') || '1')

        const handleStatus = (
            statusKey:
                | 'statusShowAll'
                | 'statusShowAssignedPassages'
                | 'statusShowCurrentPortion'
                | 'statusShowCurrentPassage',
            defaultValue: string
        ) => {
            const status = this.getDefault(statusKey)
            if (!status) {
                this.setDefault(statusKey, defaultValue)
                this[statusKey] = defaultValue === 'true'
            } else {
                this[statusKey] = status === 'true'
            }
        }

        handleStatus('statusShowAll', 'true')
        handleStatus('statusShowAssignedPassages', 'false')
        handleStatus('statusShowCurrentPortion', 'false')
        handleStatus('statusShowCurrentPassage', 'false')

        const { project } = this
        const portion = project.getDefaultPortion(this.getDefault('portion'))
        if (!portion) return

        this.portion = portion
        this.setDefault('portion', portion._id)

        const passage = portion.getDefaultPassage(this.getDefault('passage'))
        if (!passage) return

        this.passage = passage
        this.setDefault('passage', passage._id)

        const passageVideo = passage.getDefaultVideo(this.getDefault('passagevideo') ?? '')
        if (passageVideo) {
            await this.setPassageVideo(passageVideo)
        } else {
            await this.setPassage(passage)
        }
    }

    getDefaultPaneSize(tag: string, defaultSize: number) {
        const persistedSize = this.getDefault(tag)
        return Number(persistedSize) || defaultSize
    }

    resetStatusFilter() {
        this.setDefault('statusShowAll', 'false')
        this.setDefault('statusShowAssignedPassages', 'false')
        this.setDefault('statusShowCurrentPortion', 'false')
        this.setDefault('statusShowCurrentPassage', 'false')
        this.statusShowAll = false
        this.statusShowAssignedPassages = false
        this.statusShowCurrentPortion = false
        this.statusShowCurrentPassage = false
    }

    getLastProjectNotificationSeen() {
        const rank = this.getDefault('projectnotification')
        return rank ?? '0'
    }

    setLastProjectNotificationSeen(rank: string) {
        this.setDefault('projectnotification', rank)
    }

    setNotificationCutoff(value: string) {
        const cutoffMilliseconds = Date.parse(value)
        if (!isNaN(cutoffMilliseconds)) {
            this.setDefault('notificationCutoff', value)
            this.softNotificationCutoff = value
        }
    }

    setStatus(
        statusKey:
            | 'statusShowAll'
            | 'statusShowAssignedPassages'
            | 'statusShowCurrentPortion'
            | 'statusShowCurrentPassage',
        value: boolean
    ) {
        this.resetStatusFilter()
        this[statusKey] = value
        this.setDefault(statusKey, value ? 'true' : 'false')
    }

    setGlossScale(scale: number) {
        super.setGlossScale(scale)
        this.setDefault('gloss-scale', scale.toString())
    }

    async setPortion(portion: Portion | null) {
        log(`[${this.name}] setPortion: ${portion && portion.name}`)

        this.setDefault('portion', portion && portion._id)
        this.portion = null
        await this.setPassage(null)
        if (!portion) return

        this.portion = portion

        await this.setDefaultPassage()
    }

    async setDefaultPassage() {
        const passage = this.portion?.getDefaultPassage('') ?? null
        await this.setPassage(passage)

        this.setDefaultPassageVideo()
    }

    async setPassage(passage: Passage | null) {
        log(`[${this.name}] setPassage: ${passage && passage.name}`)
        this.setDefault('passage', passage && passage._id)

        if (!passage) {
            this.passage = null
            await this.setPassageVideo(null)
            return
        }

        // Set passageVideo to null because otherwise we can have a situation
        // where passage and passageVideo temporarily refer to different passages.
        await this.setPassageVideo(null)

        this.passage = passage
        await this.setDefaultPassageVideo()
    }

    async setDefaultPassageVideo() {
        if (!this.passage) {
            this.passageVideo = null
            this.passageSegment = null
            this.passageVideoUrl = ''
            return
        }

        const passageVideo = this.passage.getDefaultVideo('')
        await this.setPassageVideo(passageVideo)
    }

    async setPassageVideo(passageVideo: PassageVideo | null) {
        const { portion, passage, name } = this
        await super._setPassageVideo(name, passage, passageVideo)

        // Set references, sa they can be derived from portion, passage, and segment video
        this.setDbsRefs(portion)
    }

    setPassageSegment(passageSegment: PassageSegment) {
        const { passage, passageVideo } = this
        if (!passage || !passageVideo) return
        if (this.passageSegment === passageSegment) return
        log('setPassageSegment', fmt({ passageSegment, time: passageSegment.time }))
        this.passageSegment = passageSegment
    }

    resetCurrentTime(newTime: number, duration?: number) {
        super.resetCurrentTime(newTime, duration)
        const { portion } = this
        this.setDbsRefs(portion)
    }

    setCurrentTime(currentTime: number) {
        super.setCurrentTime(currentTime)
        const { passage, passageVideo, portion } = this

        if (!portion) {
            return
        }
        this.setDbsRefs(portion)

        // If the video was just created, it may not have any segments yet.
        // If so, we cannot select a segment yet.
        if (!passage || !passageVideo || passageVideo.getAllBaseSegments().length === 0) {
            return
        }

        try {
            const segment = passageVideo.timeToSegment(currentTime)
            this.setPassageSegment(segment)
        } catch (error) {
            log('###setCurrentTime', error)
        }
    }

    setPlaybackRate(rate: number) {
        rate = Math.max(0.1, Math.min(rate, 2.0))
        this.playbackRate = rate
        localStorage.setItem(LocalStorageKeys.VIDEO_PLAYBACK_RATE, rate.toString())
        log(`change videoPlaybackRate ${rate}`)
    }

    createNote() {
        const { passageVideo } = this
        if (!passageVideo) {
            throw new Error('note not created because of missing passageVideo')
        }

        const creationDate = passageVideo.db.getNewId(passageVideo.notes, new Date(Date.now()))
        const _id = `${passageVideo._id}/${creationDate}`

        this.setNote(new PassageNote(_id, passageVideo.db))
        if (!this.note) {
            throw new Error('note not created')
        }

        let ct = this.currentTime
        if (intest) ct = Math.round(ct * 1000) / 1000 // stop jitter from failing test
        this.note.position = ct
        this.note.startPosition = ct - 10
        this.note.endPosition = ct + 10
    }

    /**
     * Play video in main window (RootVideoPlayer)
     * @param startTime - when present start playing video at this time, otherwise at time = 0
     * @param endPosition - when present stop playing video at this time, otherwise play to end
     * @param resetTime - when present, reset video time to this playing
     */
    play(startTime?: number, endPosition?: number, resetTime?: number) {
        super.play(startTime, endPosition, resetTime)
    }

    playAll() {
        this.emit('playAll')
    }

    /**
     * This is called by VideoMain to trigger the start of recording.
     * It is also called by SegmentsEditor to start recording a patch.
     */
    record(recordingType: RecordingType) {
        if (this.recording) {
            log(`record request ignored, already recording`)
            return // ignore if already recording
        }
        const { portion, passage, iAmTranslator } = this
        if (!portion || !passage || !iAmTranslator) return
        log(`record`)

        const recordAudioOnly = this.shouldRecordAudioOnly(recordingType, passage)

        this.emit('record', recordingType, recordAudioOnly)
    }

    shouldRecordAudioOnly(recordingType: RecordingType, passage: Passage) {
        return [RecordingType.APPENDED_SEGMENT, RecordingType.PATCH].some((rec) => rec === recordingType)
            ? !this.passageVideo?.mimeType.startsWith('video')
            : passage.recordAudioOnly()
    }

    async createBiblicalTermMarker(time: number) {
        const { passage, passageVideo } = this
        if (!passage || !passageVideo) return

        // If this segment has a patch add the marker to the patch, otherwise add to base video
        const segment = passageVideo.timeToSegment(time)
        const video = segment.patchVideo(passage) || passageVideo
        const _segment = segment.actualSegment(passage)
        const position = _segment.timeToPosition(time)

        const marker = video.createBiblicalTermMarker()
        marker.position = position
        if (
            marker.canChangePositionToTime({
                time,
                computedDuration: passageVideo.computedDuration,
                markers: passageVideo.getVisibleBiblicalTermMarkers(passage)
            })
        ) {
            return marker
        }
    }

    getProjectReferences() {
        return this.projectReferences
    }

    startEditingSegmentLabels(initialLabels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = initialLabels
        this.editingSegmentLabels = true
    }

    async saveSegmentLabelsDraftChanges() {
        const { passageSegment, passage } = this
        if (passageSegment && passage) {
            const segment = passageSegment.actualSegment(passage)
            if (segment) {
                const { segmentLabelsDraft } = this

                log('Save segment labels to database')
                const filteredLabels = segmentLabelsDraft.map((label, i) => (label.text ? label : defaultLabels[i]))
                await segment.setLabels(filteredLabels)

                this.passageSegment = passageSegment
                this.editingSegmentLabels = false
            }
        }

        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
    }

    setSegmentLabelsDraft(labels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = labels
    }

    async resetSegmentLabelsDraftChanges() {
        log(`discard segment labels changes`)
        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
    }

    @computed get canViewConsultantOnlyFeatures() {
        return this.iAmRoot || this.iAmApprover
    }

    // Convert references to a display able string based on book names defined for the project
    displayableReferences(references: RefRange[] | undefined | null) {
        if (!references) return ''
        return refRangesToDisplay(references, this.project)
    }

    // Parse a Reference string, Luke 7.11-12, into RefRanges.
    // Uses book names from project, ui language, or English.
    // THROWS if cannot parse.
    parseReferences(references: string, allowBookNameOnly: boolean): RefRange[] {
        const { bookNames } = this.project
        const bookNamesList = Object.values(bookNames)
        const parsedReferences = RefRange.parseReferences(references.trim(), i18next.language, bookNamesList)

        // If some references only include the book name, ignore the reference unless allowBookNameOnly
        // is true
        if (parsedReferences.some((ref) => ref.isBBBOnly())) {
            return allowBookNameOnly ? parsedReferences.map((ref) => ref.fullBook()) : []
        }
        return parsedReferences
    }

    // Get RefRange suggestions from a Reference string, e.g., "Luke 7:"
    // Uses book names from project, ui language, or English.
    getSuggestions(references: string): RefRange[] {
        const { bookNames } = this.project
        const bookNamesList = Object.values(bookNames)
        return RefRange.getSuggestions(references, i18next.language, bookNamesList)
    }

    iAmTranslatorForPassage(passage: Passage | null) {
        if (!passage || !passage.assignee || passage.assignee === this.username) {
            return this.iAmTranslator
        }

        return this.iAmAdmin
    }

    setTimelineZoom(zoom: number) {
        super.setTimelineZoom(zoom)
        this.setDefault('timelineZoom', zoom.toFixed(0))
    }

    async saveNewPortion(name: string, references: RefRange[]) {
        const { project } = this
        try {
            const portion = project.createPortion(name)
            portion.references = references
            const newPortion = await project.addPortionFromExisting(portion)
            await this.setPortion(newPortion)
            this.setDbsRefs(this.portion)
        } catch (error) {
            displayError(error)
        }
    }

    static screenCaptureInProgress() {
        return localStorage.getItem(LocalStorageKeys.SCREEN_CAPTURE) === 'true'
    }
}
