import { IDateFormatter } from '../../models3/DateUtilities'
import { MimeType, ResponseWithBody } from '../../types'
import { LocalStorageKeys, routePrefix } from '../app/slttAvtt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Helpers')

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem)
        if (!previous[group]) previous[group] = []
        previous[group].push(currentItem)
        return previous
    }, {} as Record<K, T[]>)

export const deduplicateArrayByField = <T,>(array: T[], fieldName: keyof T): T[] => [
    ...new Map(array.map((item) => [item[fieldName], item])).values()
]

export const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize)
        chunks.push(chunk)
    }
    return chunks
}

export const processChunksAsync = async <T, U>(chunks: T[][], processFn: (chunk: T[]) => Promise<U>): Promise<U[]> => {
    const results: U[] = []
    for (const chunk of chunks) {
        const result = await processFn(chunk)
        results.push(result)
    }
    return results
}

export const getRangeString = (items: string[]): string => {
    if (items.length === 0) return ''

    const [start, , ...rest] = items
    const end = items.length > 1 ? items[items.length - 1] : ''
    const rangeCount = rest.length > 0 ? `+${rest.length}` : ''

    return end ? [start, rangeCount, end].filter(Boolean).join('-') : start
}

export const isValidIdCharacters = (id: string) => /^[a-zA-Z0-9-]+$/gi.test(id)

export const isValidIdLength = (id: string) => id.length >= 3 && id.length <= 20

export const isValidDisplayNameCharacters = (id: string) => /^[\p{L}0-9:()\- ]+$/giu.test(id)

export const isValidDisplayNameLength = (id: string) => id.length >= 3 && id.length <= 40

export const downloadWithFilename = (href: string, fileName: string) => {
    const link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', fileName)
    link.click()
}

export const currentTimestampSafeString = () => new Date().toISOString().replace(/[/:-]/g, '_')

export const safeFileName = (fileName: string) => fileName.replace(/[\\/:"*?<>|]/g, '_')

export const exportToFile = (content: string | Blob, fileName: string, fileExtension: string) => {
    const blob = typeof content === 'string' ? new Blob([content], { type: 'text/plain' }) : content
    const url = window.URL.createObjectURL(blob)
    downloadWithFilename(url, `${safeFileName(fileName)}-${currentTimestampSafeString()}${fileExtension}`)
    window.URL.revokeObjectURL(url)
}

export const createLink = ({
    projectName,
    itemId,
    time = 0
}: {
    projectName: string
    itemId: string
    time?: number
}) => {
    const linkUrl = `/index.html?project=${projectName}&id=${itemId}&time=${time}`
    return `${window.location.origin}${routePrefix}/#${linkUrl}`
}

export const newlinesToHtmlBreaks = (text: string) => text.replace(/\n/g, '<br />')

export const isProjectRestoreInProgress = () =>
    localStorage.getItem(LocalStorageKeys.PROJECT_RESTORE_IN_PROGRESS) === 'true'

export const setProjectRestoreInProgress = () =>
    localStorage.setItem(LocalStorageKeys.PROJECT_RESTORE_IN_PROGRESS, 'true')

export const clearProjectRestoreInProgress = () =>
    localStorage.setItem(LocalStorageKeys.PROJECT_RESTORE_IN_PROGRESS, '')

// Make sure that we clear the project restore setting if it is still set (browser crashes,
// power outage, etc.)
if (isProjectRestoreInProgress()) {
    log(
        `localStorage.${LocalStorageKeys.PROJECT_RESTORE_IN_PROGRESS} should be cleared before page closes. Clearing...`
    )
    clearProjectRestoreInProgress()
}

export const inRangeInclusive = (value: number, min: number, max: number) => {
    return value >= min && value <= max
}

export const millisecondsToHHMMSSMS = (milliseconds: number) => {
    const date = new Date(Date.UTC(0, 0, 0, 0, 0, 0, milliseconds))
    const parts = [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]
    const HHMMSS = parts.map((part) => String(part).padStart(2, '0')).join(':')
    const MS = String(date.getUTCMilliseconds()).padStart(3, '0')
    return `${HHMMSS}.${MS}`
}

export type AudioSection = {
    start: number
    end: number
}

// https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-if-two-ranges-overlap
export const doRangesOverlap = (rangeA: AudioSection, rangeB: AudioSection) => {
    return rangeA.start <= rangeB.end && rangeB.start <= rangeA.end
}

export const incrementTime = (aNumber: number) => aNumber + 0.01

export const decrementTime = (aNumber: number) => aNumber - 0.01

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

export const nnn = (value: number) => value.toString().padStart(3, '0')

export const getEmailPrefix = (email: string) => email.split('@')[0]

export const getEmailDomain = (email: string) => {
    const atIndex = email.indexOf('@')
    if (atIndex === -1) {
        return undefined // Invalid email format
    }
    return email.substring(atIndex + 1)
}

export const isAllowedDomainOrSubdomain = (email: string, allowedDomains: string[]): boolean => {
    const emailDomain = getEmailDomain(email)
    if (!emailDomain) {
        return false
    }
    return allowedDomains.some((domain) => emailDomain === domain || emailDomain.endsWith(`.${domain}`))
}

export const hasReachedEnd = (current: number, end: number, tolerance = 0.01) => current + tolerance >= end

export const isWithinTolerance = (a: number, b: number, tolerance = 0.01) => Math.abs(a - b) < tolerance

export const getNumberFromLocalStorage = ({ key, defaultValue }: { key: string; defaultValue: number }) => {
    const index = Number(localStorage.getItem(key))
    return !isNaN(index) && isFinite(index) ? index : defaultValue
}

export const getTimeStamp = (modBy: string, modDate: string, dateFormatter: IDateFormatter) =>
    `${getEmailPrefix(modBy)} ${dateFormatter.format(new Date(modDate))}`

/*
 * Stores JSON data along with Blob objects in a binary file.
 * Schema:
 *   First 4 bytes = # of blobs stored in the file
 *   next 4 * # of blobs = size of each Blob
 *   remaining = JSON string
 */
// See https://stackoverflow.com/a/72882043
const HOPEFULLY_UNIQUE_ID = '_blob_'
export const generateBinary = (jsObject: any) => {
    let blobIndex = 0
    const blobsMap = new Map()
    const stringifiedObject = JSON.stringify(jsObject, (key, value) => {
        if (value instanceof Blob) {
            if (blobsMap.has(value)) {
                return blobsMap.get(value)
            }
            blobsMap.set(value, HOPEFULLY_UNIQUE_ID + blobIndex)
            blobIndex += 1
            return HOPEFULLY_UNIQUE_ID + blobIndex
        }
        return value
    })
    const blobsArr = [...blobsMap.keys()]
    const data = [
        new Uint32Array([blobsArr.length]),
        ...blobsArr.map((blob) => new Uint32Array([blob.size])),
        ...blobsArr,
        stringifiedObject
    ]
    return new Blob(data)
}

export const readBinary = async (binary: Blob) => {
    const numberOfBlobs = new Uint32Array(await binary.slice(0, 4).arrayBuffer())[0]
    let cursor = 4 * (numberOfBlobs + 1)
    const blobSizes = new Uint32Array(await binary.slice(4, cursor).arrayBuffer())
    const blobs: Blob[] = []
    for (let i = 0; i < numberOfBlobs; i++) {
        const blobSize = blobSizes[i]
        const end = cursor + blobSize
        blobs.push(binary.slice(cursor, end))
        cursor = end
    }
    const pattern = new RegExp(`^${HOPEFULLY_UNIQUE_ID}\\d+$`)
    const jsObject = JSON.parse(await binary.slice(cursor).text(), (key, value) => {
        if (typeof value !== 'string' || !pattern.test(value)) {
            return value
        }
        const index = Number(value.replace(HOPEFULLY_UNIQUE_ID, '')) - 1
        return blobs[index]
    })
    return jsObject
}

export const fetchAndWaitForBody = async (url: string, init?: RequestInit): Promise<ResponseWithBody> => {
    const response = await fetch(url, init)
    if (!response.ok) {
        return { response, body: undefined }
    }

    // Make sure the body is fully downloaded, and use a clone to allow response to use blob() or json() later
    const clonedResponse = response.clone()

    const body = response.headers.get('content-type')?.includes(MimeType.JSON)
        ? await clonedResponse.json()
        : await clonedResponse.blob()

    return { response, body }
}
