import { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { SimpleAudioPlayer } from './SimpleAudioPlayer'
import { Project } from '../../models3/Project'
import { ChapterTimeCodes, PlaybackRange, getAllVersesInRange, getBibleAudio } from '../../resources/AudioResource'
import { RefRange } from '../../resources/RefRange'
import { PublishedBible } from '../../types'
import { incrementTime, nnn } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'

import './SimpleAudioPlayer.css'

const getCurrentTimeCodeIndex = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    return chapterTimeCodes.codes.findIndex(
        (code) => currentTime >= Number(code.start) && currentTime <= Number(code.end)
    )
}

const getNextVerse = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    const { codes } = chapterTimeCodes
    if (!codes.length) {
        return
    }
    const currentTimeCodeIndex = getCurrentTimeCodeIndex(currentTime, chapterTimeCodes)
    if (currentTimeCodeIndex === codes.length - 1) {
        return
    }
    return codes[currentTimeCodeIndex + 1]
}

const getPreviousVerse = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    const { codes } = chapterTimeCodes
    if (!codes.length) {
        return
    }
    const currentTimeCodeIndex = getCurrentTimeCodeIndex(currentTime, chapterTimeCodes)
    if (currentTimeCodeIndex < 1) {
        return codes[0]
    }
    return codes[currentTimeCodeIndex - 1]
}

export const BibleAudioPlayer = observer(
    ({
        bible,
        reference,
        project,
        onVerseUpdate,
        audioPlayerRef,
        chapterTimeCodes,
        setChapterTimeCodes,
        isPlaying,
        setIsPlaying,
        idSuffix
    }: {
        bible: PublishedBible
        reference: RefRange
        project: Project
        onVerseUpdate: (refs: RefRange[]) => void
        audioPlayerRef: React.RefObject<HTMLAudioElement>
        chapterTimeCodes?: ChapterTimeCodes
        setChapterTimeCodes?: (chapterTimeCodes: ChapterTimeCodes) => void
        isPlaying: string
        setIsPlaying: (isPlaying: string) => void
        idSuffix: string
    }) => {
        const { t } = useTranslation()
        const [url, setUrl] = useState('')
        const [timeRange, setTimeRange] = useState<PlaybackRange>({
            start: undefined,
            end: undefined
        })
        const [isError, setIsError] = useState(false)
        const latestRequestRef = useRef<string>('')

        useEffect(() => {
            const resourceRef = bible.id + reference.startRef + reference.endRef + project.versification
            latestRequestRef.current = resourceRef

            let audioUrl = ''
            const getData = async () => {
                setIsError(false)
                try {
                    const audioBible = await getBibleAudio(bible, reference)

                    if (!audioBible) {
                        throw new Error('No audio Bible')
                    }

                    // Make sure the last call to update state is the one that takes precedence
                    // so we avoid a race condition.
                    if (resourceRef === latestRequestRef.current && setChapterTimeCodes) {
                        setChapterTimeCodes(audioBible.timeCodes)
                        setTimeRange(audioBible.timeRange)
                        audioUrl = URL.createObjectURL(audioBible.blob)
                        setUrl(audioUrl)
                    }
                } catch (error) {
                    if (resourceRef === latestRequestRef.current) {
                        setIsError(true)
                    }
                }
            }

            getData()

            return () => {
                if (resourceRef === latestRequestRef.current) {
                    setUrl('')
                }

                if (audioUrl) {
                    URL.revokeObjectURL(audioUrl)
                }
            }
        }, [bible, reference, project, setChapterTimeCodes])

        const updateVerse = (time: number) => {
            if (!isPlaying) {
                return
            }

            if (!chapterTimeCodes) {
                return
            }

            const index = getCurrentTimeCodeIndex(time, chapterTimeCodes)
            if (index < 0) {
                return
            }

            const currentTimeCode = chapterTimeCodes.codes[index]
            const versesInRange = getAllVersesInRange(currentTimeCode.verseId)
            if (versesInRange.length === 0) {
                return
            }

            const refString = (verse: string) => `${currentTimeCode.chapter}${nnn(Number(verse))}`

            const startRef = refString(versesInRange[0])
            const endRef = refString(versesInRange[versesInRange.length - 1])
            onVerseUpdate([new RefRange(startRef, endRef)])
        }

        const getNextSkipTime = (currentTime: number, limit: number) => {
            if (chapterTimeCodes) {
                const nextVerse = getNextVerse(currentTime, chapterTimeCodes)
                if (nextVerse && Number(nextVerse.start) <= limit) {
                    // add a little bit to make sure we start playing
                    // in the next verse and not the current one
                    return incrementTime(Number(nextVerse.start))
                }
                return limit
            }
            return currentTime
        }

        const getPreviousSkipTime = (currentTime: number, limit: number) => {
            if (chapterTimeCodes) {
                const previousVerse = getPreviousVerse(currentTime, chapterTimeCodes)
                if (previousVerse && Number(previousVerse.start) >= limit) {
                    // add a little bit to make sure we start playing
                    // in the previous verse and not the one before it
                    return incrementTime(Number(previousVerse.start))
                }
                return limit
            }
            return currentTime
        }

        return (
            <>
                {!isError && url.length === 0 && (
                    <div className="centered-controls">
                        <LoadingIcon className="published-audio-playback-button" />
                    </div>
                )}
                {!isError && url.length > 0 && (
                    <SimpleAudioPlayer
                        url={url}
                        startTime={timeRange.start}
                        endTime={timeRange.end}
                        onPause={() => {
                            setIsPlaying('')
                            onVerseUpdate([])
                        }}
                        onPlay={() => setIsPlaying(idSuffix)}
                        onTimeUpdate={updateVerse}
                        getNextSkipTime={getNextSkipTime}
                        getPreviousSkipTime={getPreviousSkipTime}
                        nextSkipTooltip={t('goToNextVerse')}
                        previousSkipTooltip={t('goToPreviousVerse')}
                        audioPlayerRef={audioPlayerRef}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        idSuffix={idSuffix}
                        isEnabled={idSuffix === isPlaying}
                    />
                )}
            </>
        )
    }
)
